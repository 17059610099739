import React from 'react';
import FormControl from '@material-ui/core/FormControl/FormControl';
import { withStyles } from '@material-ui/core/styles';
import createToolbarPlugin, { Separator } from 'draft-js-static-toolbar-plugin';
import Editor from 'draft-js-plugins-editor';
import '../../../node_modules/draft-js-static-toolbar-plugin/lib/plugin.css';
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  CodeButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
} from 'draft-js-buttons';

import { Grid } from '@material-ui/core';

const styles = theme => ({
  editor: {
    boxSizing: 'border-box',
    border: '1px solid #ddd',
    cursor: 'text',
    padding: theme.spacing(2),
    borderRadius: '2px',
    boxShadow: 'inset 0px 1px 8px -3px #ABABAB',
    background: '#fefefe',
    minHeight: theme.spacing(20),
  },
});

const staticToolbarPlugin = createToolbarPlugin();
const { Toolbar } = staticToolbarPlugin;

class DraftEditor extends React.Component {
  componentDidMount() {
    this.domEditor.focus();
  }

  focus = () => this.domEditor.focus();

  setDomEditorRef = (ref) => {
    this.domEditor = ref;
  };

  render() {
    const { editorState, onChange, classes } = this.props;
    return (
      <Grid container direction="column" >
        <Grid item xs>
          <FormControl fullWidth className={classes.editor} onClick={this.focus}>
            <Editor
              editorState={editorState}
              onChange={onChange}
              ref={this.setDomEditorRef}
              plugins={[staticToolbarPlugin]}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <Toolbar>
            {
            externalProps => (
              <div>
                <HeadlineOneButton {...externalProps} />
                <HeadlineTwoButton {...externalProps} />
                <HeadlineThreeButton {...externalProps} />
                <Separator {...externalProps} />
                <BoldButton {...externalProps} />
                <ItalicButton {...externalProps} />
                <UnderlineButton {...externalProps} />
                <CodeButton {...externalProps} />
                <Separator {...externalProps} />
                <UnorderedListButton {...externalProps} />
                <OrderedListButton {...externalProps} />
                <BlockquoteButton {...externalProps} />
              </div>
            )}
          </Toolbar>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(DraftEditor);
