import { FETCH_TAGS_FAILURE, FETCH_TAGS_SUCCESS, FETCH_TAGS_REQUEST } from '../actions/types';

const initialState = { data: [] };
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TAGS_REQUEST:
      return {
        ...state,
        data: [],
        error: undefined,
        loading: true,
      };
    case FETCH_TAGS_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case FETCH_TAGS_FAILURE: {
      // return error and make loading = false
      const { error } = action.payload;
      return {
        ...state,
        data: [],
        error,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export const getTags = state => state.Tags.data;
