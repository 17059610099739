import React from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Editor, EditorState, convertFromRaw } from 'draft-js';
import Moment from 'moment';
import {
  Grid, Typography,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { fetchContent } from '../../actions/Content';
import { fetchComments } from '../../actions/Comments';
import { getContent, getContentLoading } from '../../reducers/Content';
import { getComments, getCommentsLoading } from '../../reducers/Comments';
import CommentList from '../ContentDetails/CommentsList/CommentList';
import CommentEditor from '../ContentDetails/CommentEditor/CommentEditor';
import { getCurrentOwnerTypeTitle } from '../../reducers/CurrentOwner';
import ContentEditButton from '../ContentCardActions/ContentEditButton';
import ContentArchiveButton from '../ContentCardActions/ContentArchiveButton';
import ContentDeleteButton from '../ContentCardActions/ContentDeleteButton';

const ContentCardDialog = (props) => {
  const {
    content, open, handleClose, currentOwnerTypeTitle, CommentsLoading, Comments, getComments, getContent, Content, ContentLoading,
  } = props;

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  React.useEffect(() => {
    if (open) getContent(content.id);
  }, [open]);

  React.useEffect(() => {
    if (open) getComments(content.id);
  }, [open]);

  if (!Content) return null;

  let descriptionEditorState = EditorState.createEmpty();
  if (Content.memo !== null) {
    try {
      const descriptionState = convertFromRaw(JSON.parse(Content.description));
      descriptionEditorState = EditorState.createWithContent(descriptionState);
    } catch (e) {
      descriptionEditorState = EditorState.createEmpty();
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        fullWidth
        maxWidth="lg"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent dividers>
          <Grid
            container
            spacing={0}
          >
            <Grid item xs={8}>
              <Typography variant="h4">{content.title}</Typography>
              <Typography variant="subtitle2" color="textSecondary">
                <i className="mr-1 far fa-clock" />
                <small>{`Added ${Moment(content.created_at * 1000).fromNow()} by ${(content.user && content.user.name) || (content.creator && content.creator.name)}`}</small>
              </Typography>
              {content.tags.length > 0 && (
                <div className="d-flex align-items-flex-end justify-content-flex-end flex-wrap">
                  {content.tags.map(tag => <div key={tag} className="m-1 text-second badge badge-neutral-second">{tag}</div>)}
                  {'  '}
                </div>
              )}
              <hr />
              {!ContentLoading && descriptionEditorState.getCurrentContent().hasText() && (
                <Editor editorState={descriptionEditorState} readOnly />
              )}
            </Grid>
            <Grid item xs={4}>
              <div className="m-1 badge badge-second">{currentOwnerTypeTitle}</div>
              {content.list_name && (
                <p>
                  <div className="m-1 badge badge-gray">
                    List: &nbsp;
                    {content.list_name}
                  </div>
                </p>
              )}

              <PerfectScrollbar>
                {!CommentsLoading && <CommentList comments={Comments} /> }
                <CommentEditor contentId={content.id} />
              </PerfectScrollbar>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ContentEditButton content={Content} />
          <ContentDeleteButton content={content} />
          <div style={{ flex: '1 0 0' }} />
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = state => ({
  Content: getContent(state),
  Comments: getComments(state),
  CommentsLoading: getCommentsLoading(state),
  ContentLoading: getContentLoading(state),
  currentOwnerTypeTitle: getCurrentOwnerTypeTitle(state),
});

const mapDispatchToProps = dispatch => ({
  getContent: contentId => dispatch(fetchContent(contentId)),
  getComments: contentId => dispatch(fetchComments(contentId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContentCardDialog);
