import { CircularProgress, Grid } from "@material-ui/core";
import React from 'react';
import { connectStateResults } from 'react-instantsearch-dom';
import MaterialUIClearRefinements from "../../UI/Algolia/MaterialUIClearRefinements";
import NoContentsScreen from "./NoContentsScreen";

const StateResults = (props) => {
  const {
    searchResults, error, searching, searchingForFacetValues, isSearchStalled, children,
  } = props;
  if (searchResults) {
    if (searchResults.nbHits > 0) return children;
    if (!searchResults.query) return <NoContentsScreen />; // also, searchResults.nbHits === 0
    return (
      <Grid container direction="column" alignItems="center">
        <Grid item>No results have been found.</Grid>
        <Grid item><MaterialUIClearRefinements clearsQuery /></Grid>
      </Grid>
    );
  }
  return (
    <div>
      {/* {isSearchStalled ? 'The search is stalled. ' : ''} */}
      {(searching || isSearchStalled)
        && (
          <Grid container justify="center">
            <CircularProgress size={50} />
          </Grid>
        )
      }

      {searchingForFacetValues && 'Search for facet values is in progress.'}
      {error && `Error while searching: ${error.message}`}
    </div>
  );
};

export default connectStateResults(StateResults);
