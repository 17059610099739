import axios from 'axios';
import { enqueueMessage } from './Alerts';
import {
  FETCH_ORGANIZATION,
  FETCH_ORGANIZATION_SUCCESS,
  FETCH_ORGANIZATION_FAILURE,
} from './types';

import parseErrorMessage from '../utils/errorMessage';
import { isOrganization } from '../utils/listOwnerTypes';
import { setAlgoliaSearchKey } from './Alogolia';

export const fetchOrganizationSuccess = organization => ({
  type: FETCH_ORGANIZATION_SUCCESS,
  payload: organization,
});

export const fetchOrganizationFailure = data => ({
  type: FETCH_ORGANIZATION_FAILURE,
  payload: {
    error: data.message,
  },
});

export const fetchOrganization = () => (dispatch, getState) => {
  const request = axios
    .get('/organization')
    .then((response) => {
      dispatch(fetchOrganizationSuccess(response.data));
      const { CurrentOwner } = getState();
      if (isOrganization(CurrentOwner.currentOwnerType)) {
        dispatch(setAlgoliaSearchKey(response.data.algolia_secured_api_key_organization_contents));
      }
    })
    .catch((error) => {
      dispatch(fetchOrganizationFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
    });

  return dispatch({
    type: FETCH_ORGANIZATION,
    payload: request,
  });
};
