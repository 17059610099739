import React from 'react';
import { connect } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { Card, Link, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import Gravatar from '../../UI/Gravatar';
import { getOrganization } from '../../../reducers/Organization';

const NonAdminUser = ({ organization }) => (
  <Alert severity="error">
    <AlertTitle>Only Admin Users can manage this organization!</AlertTitle>
    <span>
      <p>
        Please contact your admin if you need to manage users in the organization or change your subscription. Please feel free to contact us at
        {' '}
        <Link href="mailto:support@iseloapp.com">support@iseloapp.com</Link>
      </p>
      <p>
        Admin(s) you can contact in your organization:
      </p>
      {/* {organization && organization.users} */}
      <Card>
        <List>
          {organization && organization.users
          && organization.users.filter(u => u.organization_role === 'admin').map(user => (
            <ListItem key={user.id}>
              <ListItemAvatar>
                <Gravatar email={user.email} size={60} />
              </ListItemAvatar>
              <ListItemText
                primary={`${
                  user.name
                } (${user.organization_role.toUpperCase()})`}
                secondary={user.email}
              />
            </ListItem>
          ))}
        </List>
      </Card>
    </span>
  </Alert>
);

const mapStateToProps = state => ({
  organization: getOrganization(state),
});

export default connect(mapStateToProps, null)(NonAdminUser);
