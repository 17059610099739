import React from 'react';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { Link } from '@material-ui/core';
import { connect } from 'react-redux';
import StripeCheckoutSessionButton from '../StripeCheckoutSessionButton';
import { getCurrentUser } from '../../../reducers/CurrentUser';

const NoActiveSubscription = ({ currentUser }) => (
  <Alert severity="error">
    <AlertTitle>No Active Subscription!</AlertTitle>
    <span>
      <p>You do not have an active subscription. </p>
      {currentUser && currentUser.organization_role === 'admin' && (
        <>
          <p>
          Please click the following button to activate your subscription! Please
            feel free to contact us at
            {' '}
            <Link href="mailto:support@iseloapp.com">support@iseloapp.com</Link>
          </p>
          <StripeCheckoutSessionButton interval="monthly" text="Start Monthly Subscription" />
          <StripeCheckoutSessionButton interval="yearly" text="Start Yearly Subscription" />
        </>
      )}
    </span>
  </Alert>
);

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
});

export default connect(mapStateToProps)(NoActiveSubscription);
