import React from 'react';
import PropTypes from 'prop-types';
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Gravatar from '../UI/Gravatar';

const styles = () => ({
  root: {
    displace: 'flex',
    minWidth: '450px',
  },
});

const UserList = ({ users, handleDeleteUser }) => (
  <List dense>
    {users.map(user => (
      <ListItem key={user.email}>
        <ListItemAvatar>
          <Gravatar email={user.email} />
        </ListItemAvatar>
        <ListItemText
          primary={user.name || user.email}
          secondary={user.name ? user.email : null}
        />
        <ListItemSecondaryAction>
          <Button size="small" color="secondary" onClick={() => handleDeleteUser(user)}>
            <i className="fa fa-times" />
            &nbsp;
            Remove
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
    ))}
  </List>
);

UserList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleDeleteUser: PropTypes.func.isRequired,
};

export default withStyles(styles)(UserList);
