import axios from 'axios';
import { enqueueMessage } from './Alerts';
import {
  FETCH_MEMBERSHIPS_REQUEST,
  FETCH_MEMBERSHIPS_SUCCESS,
  FETCH_MEMBERSHIPS_FAILURE,
  CREATE_MEMBERSHIP_REQUEST,
  CREATE_MEMBERSHIP_SUCCESS,
  CREATE_MEMBERSHIP_FAILURE,
  DELETE_MEMBERSHIP_REQUEST,
  DELETE_MEMBERSHIP_SUCCESS,
  DELETE_MEMBERSHIP_FAILURE,
} from './types';

import { trackAddedGroupMember, trackRemovedGroupMember } from '../utils/mixpanel/memberships';
import parseErrorMessage from '../utils/errorMessage';

export const fetchMembershipsRequest = () => ({
  type: FETCH_MEMBERSHIPS_REQUEST,
});

export const fetchMembershipsSuccess = memberships => ({
  type: FETCH_MEMBERSHIPS_SUCCESS,
  payload: memberships,
});

export const fetchMembershipsFailure = data => ({
  type: FETCH_MEMBERSHIPS_FAILURE,
  payload: {
    error: data.message,
  },
});

export const fetchMemberships = groupId => (dispatch) => {
  dispatch(fetchMembershipsRequest());

  return axios.get(`/groups/${groupId}/memberships`)
    .then((response) => {
      dispatch(fetchMembershipsSuccess(response.data));
      return response.data;
    })
    .catch((error) => {
      dispatch(fetchMembershipsFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
    });
};

export const createMembershipRequest = () => ({
  type: CREATE_MEMBERSHIP_REQUEST,
});

export const createMembershipSuccess = group => ({
  type: CREATE_MEMBERSHIP_SUCCESS,
  payload: group,
});

export const createMembershipFailure = data => ({
  type: CREATE_MEMBERSHIP_FAILURE,
  payload: {
    error: data.message,
  },
});

export const createMembership = (groupId, email) => (dispatch) => {
  dispatch(createMembershipRequest());

  return axios.post(`/groups/${groupId}/memberships`, { email })
    .then((response) => {
      dispatch(createMembershipSuccess(response.data));
      dispatch(enqueueMessage('Group Member Added Successfully', 'success'));
      trackAddedGroupMember(response.data);
      return response.data;
    })
    .catch((error) => {
      dispatch(createMembershipFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
      trackAddedGroupMember({ group: { id: groupId }, user: { email } }, 'failure', parseErrorMessage(error));
    });
};

export const deleteMembershipRequest = () => ({
  type: DELETE_MEMBERSHIP_REQUEST,
});

export const deleteMembershipSuccess = membership => ({
  type: DELETE_MEMBERSHIP_SUCCESS,
  payload: membership,
});

export const deleteMembershipFailure = data => ({
  type: DELETE_MEMBERSHIP_FAILURE,
  payload: {
    error: data.message,
  },
});

export const deleteMembership = membership => (dispatch) => {
  dispatch(deleteMembershipRequest());

  return axios.delete(`/groups/${membership.group.id}/memberships/${membership.id}`)
    .then(() => {
      dispatch(deleteMembershipSuccess(membership.id));
      dispatch(enqueueMessage('Group Member Removed Successfully', 'success'));
      trackRemovedGroupMember(membership);
      return membership.id;
    })
    .catch((error) => {
      dispatch(deleteMembershipFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
      trackRemovedGroupMember(membership, 'failure', parseErrorMessage(error));
    });
};
