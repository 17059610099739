import React from 'react';
import {
  TextField, IconButton, Tooltip, InputAdornment,
} from '@material-ui/core';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import ClearIcon from '@material-ui/icons/Clear';
import { connectSearchBox } from 'react-instantsearch-dom';

class MaterialUiSearchBox extends React.Component {
  timerId = null;

  style = {
    display: 'flex',
    alignItems: 'center',
    marginRight: '8px',
    flexGrow: 1,
  };

  state = {
    value: this.props.currentRefinement,
  };

  clearQuery = () => {
    const { refine } = this.props;
    refine('');
    this.setState(() => ({
      value: '',
    }));
  }

  onChangeDebounced = (event) => {
    const { refine, delay, onChange } = this.props;
    const { value } = event.currentTarget;

    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      refine(value);
      onChange();
    }, delay);

    this.setState(() => ({
      value,
    }));
  };

  render() {
    const { value } = this.state;

    return (
      <div style={this.style}>
        <Tooltip
          title="Search by Title, Tags, Link titles, File names etc."
          placement="bottom"
          enterDelay={0}
        >
          <TextField
            variant="outlined"
            size="small"
            id="input-with-icon-textfield1"
            className="w-100"
            value={value}
            onChange={this.onChangeDebounced}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchTwoToneIcon />
                </InputAdornment>
              ),
              endAdornment: value && (
                <InputAdornment position="end">
                  <IconButton onClick={this.clearQuery}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Tooltip>
      </div>
    );
  }
}

export default connectSearchBox(MaterialUiSearchBox);
