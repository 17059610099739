import axios from 'axios';
// import { normalize } from 'normalizr';
import { setAlgoliaSearchKey } from './Alogolia';
// import * as schema from '../api/schema';

import { enqueueMessage } from './Alerts';
import {
  FETCH_GROUPS_REQUEST,
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUPS_FAILURE,
  CREATE_GROUP_REQUEST,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAILURE,
  UPDATE_GROUP_REQUEST,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAILURE,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAILURE,
  SELECT_GROUP,
} from './types';

import { trackAddedGroup, trackUpdatedGroup, trackRemovedGroup } from '../utils/mixpanel/groups';
import parseErrorMessage from '../utils/errorMessage';
import { isGroup } from "../utils/listOwnerTypes";

export const fetchGroupsRequest = () => ({ type: FETCH_GROUPS_REQUEST });

export const fetchGroupsSuccess = groups => ({
  type: FETCH_GROUPS_SUCCESS,
  payload: groups,
});

export const fetchGroupsFailure = data => ({
  type: FETCH_GROUPS_FAILURE,
  payload: {
    error: data.message,
  },
});

export const fetchGroups = () => (dispatch, getState) => {
  dispatch(fetchGroupsRequest());

  axios.get('/groups')
    .then((response) => {
      // const normalizedResponse = normalize(response.data, [schema.group]);
      // console.log(normalizedResponse);
      dispatch(fetchGroupsSuccess(response.data));
      const { Groups, CurrentOwner } = getState();
      if (Groups && isGroup(CurrentOwner.currentOwnerType)) {
        const group = Groups.data.find(t => Number(t.id) === Number(CurrentOwner.currentOwnerId));
        dispatch(setAlgoliaSearchKey(group.algolia_secured_api_key_group_contents));
      }
      return response.data;
    })
    .catch((error) => {
      dispatch(fetchGroupsFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
    });
};

export const createGroupRequest = () => ({ type: CREATE_GROUP_REQUEST });

export const createGroupSuccess = group => ({
  type: CREATE_GROUP_SUCCESS,
  payload: group,
});

export const createGroupFailure = data => ({
  type: CREATE_GROUP_FAILURE,
  payload: {
    error: data.message,
  },
});

export const createGroup = group => (dispatch) => {
  dispatch(createGroupRequest());

  return axios.post('/groups', { group })
    .then((response) => {
      trackAddedGroup({ name: group.name });
      dispatch(createGroupSuccess(response.data));
      dispatch(enqueueMessage('Group Created Successfully', 'success'));
      return response.data;
    })
    .catch((error) => {
      trackAddedGroup({ name: group.name }, 'failure', parseErrorMessage(error));
      dispatch(createGroupFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
    });
};

export const updateGroupRequest = () => ({ type: UPDATE_GROUP_REQUEST });

export const updateGroupSuccess = group => ({
  type: UPDATE_GROUP_SUCCESS,
  payload: group,
});

export const updateGroupFailure = data => ({
  type: UPDATE_GROUP_FAILURE,
  payload: {
    error: data.message,
  },
});

export const updateGroup = group => (dispatch) => {
  dispatch(updateGroupRequest());

  return axios.put(`/groups/${group.id}`, { group })
    .then((response) => {
      trackUpdatedGroup(group);
      dispatch(updateGroupSuccess(response.data));
      dispatch(enqueueMessage('Group Updated Successfully', 'success'));
      return response.data;
    })
    .catch((error) => {
      trackUpdatedGroup(group, 'failure', parseErrorMessage(error));
      dispatch(updateGroupFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
    });
};

export const deleteGroupRequest = () => ({ type: DELETE_GROUP_REQUEST });

export const deleteGroupSuccess = group => ({
  type: DELETE_GROUP_SUCCESS,
  payload: group,
});

export const deleteGroupFailure = data => ({
  type: DELETE_GROUP_FAILURE,
  payload: {
    error: data.message,
  },
});

export const deleteGroup = group => (dispatch) => {
  dispatch(deleteGroupRequest());

  return axios.delete(`/groups/${group.id}`)
    .then(() => {
      trackRemovedGroup(group);
      dispatch(deleteGroupSuccess(group.id));
      dispatch(enqueueMessage('Group Deleted Successfully', 'success'));
      return group.id;
    })
    .catch((error) => {
      trackRemovedGroup(group, 'failure', parseErrorMessage(error));
      dispatch(deleteGroupFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
    });
};

export const selectGroup = groupId => (dispatch, getState) => {
  dispatch({
    type: SELECT_GROUP,
    payload: groupId,
  });
  // If selectedGroup is fetched set Algolia search key
  const { CurrentUser, Groups } = getState();
  if (parseInt(groupId, 10) === 0 && CurrentUser.data) {
    const searchKey = CurrentUser.data.algolia_secured_api_key_private_contents;
    dispatch(setAlgoliaSearchKey(searchKey));
  } else if (parseInt(groupId, 10) > 0 && Groups.data.length > 0) {
    const searchKey = Groups.data.find(t => Number(t.id) === Number(groupId)).algolia_secured_api_key_group_contents;
    dispatch(setAlgoliaSearchKey(searchKey));
  }
};
