import React from 'react';
import { connect } from 'react-redux';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import MixpanelClient from '../../utils/mixpanel/client';
import { MIXPANEL_CLICKED_ON_DELETE } from '../../utils/mixpanel/events';
import { getCurrentUser } from '../../reducers/CurrentUser';
import { deleteContent } from '../../actions/Contents';

const ContentDeleteButton = ({
  currentUser, content, deleteContentAction, iconOnly,
}) => {
  if (!currentUser) return null;
  const trackDeleteClick = () => MixpanelClient.track(MIXPANEL_CLICKED_ON_DELETE, { content_title: content.title });
  const isCurrentUserContent = () => ((content.creator && Number(content.creator.id) === Number(currentUser.id)));
  const handleDelete = () => deleteContentAction(content);

  return isCurrentUserContent() && (
    <Tooltip title="Delete" enterDelay={1000}>
      {iconOnly
        ? (
          <IconButton
            className="m-2"
            color="secondary"
            aria-label="delete"
            size="small"
            onClick={() => window.confirm(
              'Are you sure you wish to delete the item?',
            ) && handleDelete() && trackDeleteClick()
          }
          >
            <i className="fas fa-trash" />
          </IconButton>
        ) : (
          <Button
            className="m-2"
            color="secondary"
            aria-label="delete"
            onClick={() => window.confirm(
              'Are you sure you wish to delete the item?',
            ) && handleDelete() && trackDeleteClick()
          }
          >
            <span>
              <i className="fas fa-trash" />
              {' '}
              Delete
            </span>
          </Button>
        )}
    </Tooltip>
  );
};

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
});

const mapDispatchToProps = dispatch => ({
  deleteContentAction: payload => dispatch(deleteContent(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentDeleteButton);
