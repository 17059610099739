import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Button, TextField, Select, MenuItem, InputLabel, FormControl, FormHelperText,
} from '@material-ui/core';
import { createInvitation } from '../../actions/Invitations';
import AddModal from '../AddModal';

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
});

class AddOrganizationUserButton extends React.Component {
  state = {
    open: false,
    email: '',
    name: '',
    role: 'user',
  };

  handleOpen = (e) => {
    e.stopPropagation();
    this.setState({ open: true });
  };

  handleClose = (e) => {
    e.stopPropagation();
    this.setState({ open: false });
  };

  handleSave = (e) => {
    e.stopPropagation();
    const { createInvitationAction } = this.props;
    const { name, email, role } = this.state;
    createInvitationAction(name, email, role).then(() => {
      this.setState({ open: false });
    });
  };

  onNameChange = e => this.setState({ name: e.target.value });

  onEmailChange = e => this.setState({ email: e.target.value });

  onRoleChange = e => this.setState({ role: e.target.value });

  render() {
    const { classes, children } = this.props;
    const {
      open, email, name, role,
    } = this.state;

    return (
      <Button
        variant="contained"
        color="primary"
        role="button"
        tabIndex="-1"
        onClick={this.handleOpen}
        onKeyDown={() => {}}
      >
        {children}
        <AddModal
          open={open}
          onClose={this.handleClose}
          onSave={this.handleSave}
          heading="Add a new user to the organization"
          showDelete={false}
        >
          <FormControl className={classes.formControl}>
            <TextField
              required
              autoFocus
              id="name"
              label="Full Name"
              value={name}
              onChange={this.onNameChange}
            />
          </FormControl>
          <br />
          <FormControl className={classes.formControl}>
            <TextField
              type="email"
              required
              id="email"
              label="Email address"
              value={email}
              onChange={this.onEmailChange}
            />
          </FormControl>
          <br />
          <FormControl className={classes.formControl}>
            <InputLabel id="role-label" shrink htmlFor="role">Organization Role</InputLabel>
            <Select
              labelId="role-label"
              // label="Organization Role"
              value={role}
              onChange={this.onRoleChange}
              inputProps={{
                name: 'role',
                id: 'role',
              }}
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="user">User</MenuItem>
            </Select>

            <FormHelperText>Admin(s) will be able to manage users of the organization</FormHelperText>
          </FormControl>
        </AddModal>
      </Button>
    );
  }
}

AddOrganizationUserButton.defaultProps = {
  classes: {},
};

AddOrganizationUserButton.propTypes = {
  classes: PropTypes.shape({}),
  createInvitationAction: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
};

const mapDispatchToProps = dispatch => ({
  createInvitationAction: (name, email, role) => dispatch(createInvitation(name, email, role)),
});

export default connect(
  null,
  mapDispatchToProps,
)(withStyles(styles)(AddOrganizationUserButton));
