import { Hidden, IconButton } from '@material-ui/core';
import React from 'react';

import IntegrationsMenu from '../../../Header/IntegrationsMenu';
import NotificationsMenu from '../../../Header/NotificationsMenu';

export default () => (
  <div className="d-flex align-items-center popover-header-wrapper">
    <Hidden implementation="css" xsDown>
      <span className="pr-2">
        <IconButton aria-label="Guidebook" href="http://guide.iseloapp.com/" target="_blank">
          <i className="far fa-question-circle" />
        </IconButton>
      </span>
    </Hidden>
    <span className="pr-2">
      <Hidden implementation="css" xsDown>
        <IntegrationsMenu />
      </Hidden>
    </span>
    <span className="pr-2">
      <NotificationsMenu />
    </span>
  </div>
);
