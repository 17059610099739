import {
  FETCH_MEMBERSHIPS_REQUEST,
  FETCH_MEMBERSHIPS_FAILURE,
  FETCH_MEMBERSHIPS_SUCCESS,
  CREATE_MEMBERSHIP_REQUEST,
  CREATE_MEMBERSHIP_SUCCESS,
  CREATE_MEMBERSHIP_FAILURE,
  DELETE_MEMBERSHIP_REQUEST,
  DELETE_MEMBERSHIP_SUCCESS,
  DELETE_MEMBERSHIP_FAILURE,
} from '../actions/types';

const initialState = { data: [] };
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MEMBERSHIPS_REQUEST:
      return {
        ...state,
        data: [],
        error: undefined,
        loading: true,
      };
    case FETCH_MEMBERSHIPS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_MEMBERSHIPS_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        data: [],
        error,
        loading: false,
      };
    }
    case CREATE_MEMBERSHIP_REQUEST:
      return { ...state, error: undefined, loading: true };
    case CREATE_MEMBERSHIP_SUCCESS:
      return { ...state, data: [...state.data, action.payload], loading: false };
    case CREATE_MEMBERSHIP_FAILURE: {
      const { error } = action.payload;
      return { ...state, error, loading: false };
    }
    case DELETE_MEMBERSHIP_REQUEST:
      return { ...state, error: undefined, loading: true };
    case DELETE_MEMBERSHIP_SUCCESS: {
      const membershipId = action.payload;
      const membershipIndex = state.data.findIndex(x => x.id === membershipId);
      const data = [...state.data];
      data.splice(membershipIndex, 1);
      return { ...state, data, loading: false };
    }
    case DELETE_MEMBERSHIP_FAILURE: {
      const { error } = action.payload;
      return { ...state, error, loading: false };
    }
    default:
      return state;
  }
};
