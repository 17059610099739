import axios from 'axios';
import { enqueueMessage } from './Alerts';
import parseErrorMessage from '../utils/errorMessage';
import {
  FETCH_CONTENT_REQUEST,
  FETCH_CONTENT_SUCCESS,
  FETCH_CONTENT_FAILURE,
} from './types';

export const fetchContentRequest = () => ({
  type: FETCH_CONTENT_REQUEST,
});

export const fetchContentSuccess = content => ({
  type: FETCH_CONTENT_SUCCESS,
  payload: content,
});

export const fetchContentFailure = data => ({
  type: FETCH_CONTENT_FAILURE,
  payload: {
    error: data.message,
  },
});

export const fetchContent = contentId => (dispatch) => {
  dispatch(fetchContentRequest());

  return axios.get(`/contents/${contentId}`)
    .then((response) => {
      dispatch(fetchContentSuccess(response.data));
      return response.data;
    })
    .catch((error) => {
      dispatch(fetchContentFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
      throw error;
    });
};
