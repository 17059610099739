import { combineReducers } from 'redux';
import Alerts from './Alerts';
import Algolia from './Algolia';
import Comments from './Comments';
import Contents from './Contents';
import Content from "./Content";
import CurrentUser from './CurrentUser';
import CurrentOwner from './CurrentOwner';
import CurrentList from './CurrentList';
import Filters from './Filters';
import Groups from './Groups';
import Invitations from './Invitations';
import Links from './Links';
import Memberships from './Memberships';
import Notifications from './Notifications';
import PaymentMethods from './PaymentMethods';
import Organization from './Organization';
import Tags from './Tags';
import ThemeOptions from './ThemeOptions';
import Users from './Users';

const rootReducer = combineReducers({
  Alerts,
  Algolia,
  Comments,
  Contents,
  Content,
  CurrentUser,
  CurrentOwner,
  CurrentList,
  Filters,
  Groups,
  Invitations,
  Links,
  Memberships,
  Notifications,
  Organization,
  PaymentMethods,
  Tags,
  ThemeOptions,
  Users,
});

export default rootReducer;
