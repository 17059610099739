import { createSelector } from 'reselect';
import {
  SET_CURRENT_OWNER_TYPE,
  SET_CURRENT_OWNER_ID,
} from '../actions/types';
import { isGroup, isOrganization, isUser } from '../utils/listOwnerTypes';
import { getCurrentUser } from './CurrentUser';
import { getGroups } from './Groups';
import { getOrganization } from './Organization';

const initialState = {};
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_OWNER_TYPE:
      return {
        ...state,
        currentOwnerType: action.payload,
      };
    case SET_CURRENT_OWNER_ID:
      return {
        ...state,
        currentOwnerId: action.payload,
      };
    default:
      return state;
  }
};

export const getCurrentOwnerType = state => state.CurrentOwner.currentOwnerType;

export const isCurrentOwnerGroup = createSelector(
  getCurrentOwnerType,
  currentOwnerType => isGroup(currentOwnerType),
);

export const isCurrentOwnerUser = createSelector(
  getCurrentOwnerType,
  currentOwnerType => isUser(currentOwnerType),
);

export const isCurrentOwnerOrganization = createSelector(
  getCurrentOwnerType,
  currentOwnerType => isOrganization(currentOwnerType),
);

export const getCurrentOwnerId = state => state.CurrentOwner.currentOwnerId;

export const getCurrentOwner = createSelector(
  getCurrentOwnerType,
  getCurrentOwnerId,
  getCurrentUser,
  getGroups,
  getOrganization,
  (currentOwnerType, currentOwnerId, currentUser, groups, organization) => {
    if (isUser(currentOwnerType)) return currentUser;
    if (isOrganization(currentOwnerType)) return organization;
    if (isGroup(currentOwnerType)) return groups.find(group => parseInt(group.id, 10) === parseInt(currentOwnerId, 10));
    return null;
  },
);

// Only return Group name or else null
export const getCurrentOwnerName = createSelector(
  getCurrentOwnerType,
  getCurrentOwner,
  (currentOwnerType, currentOwner) => (isGroup(currentOwnerType) ? currentOwner && currentOwner.name : null),
);

export const ownerTypeToLabel = (ownerType, ownerName) => {
  if (isUser(ownerType)) return '🔒 Private Space';
  if (isOrganization(ownerType)) return '🏢 Organization Space';
  if (isGroup(ownerType)) return `👥 Group: ${ownerName}`;
  return null;
};

export const getCurrentOwnerTypeTitle = createSelector(
  getCurrentOwnerType,
  getCurrentOwnerName,
  (currentOwnerType, currentOwnerName) => ownerTypeToLabel(currentOwnerType, currentOwnerName),
);
