import axios from 'axios';
import { FETCH_TAGS_REQUEST, FETCH_TAGS_SUCCESS, FETCH_TAGS_FAILURE } from './types';

export const fetchTagRequest = () => ({
  type: FETCH_TAGS_REQUEST,
});


export const fetchTagsSuccess = tags => ({
  type: FETCH_TAGS_SUCCESS,
  payload: tags,
});

export const fetchTagsFailure = data => ({
  type: FETCH_TAGS_FAILURE,
  payload: {
    error: data.message,
  },
});

export const fetchTags = () => (dispatch) => {
  dispatch(fetchTagRequest());

  return axios
    .get('/tags')
    .then((response) => {
      dispatch(fetchTagsSuccess(response.data));
    })
    .catch((response) => {
      dispatch(fetchTagsFailure(response));
    });
};
