import { SET_ALGOLIA_SEARCH_KEY } from '../actions/types';

const initialState = { SearchKey: null };
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ALGOLIA_SEARCH_KEY:
      return { ...state, SearchKey: action.payload };
    default:
      return state;
  }
};

export const getAlgoliaSearchKey = state => state.Algolia.SearchKey;
