import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { Paper, Box, Table, TableBody, TableRow, TableCell, Typography, Grid } from '@material-ui/core';
import PaymentMethods from './PaymentMethods';
import AddPaymentMethodButton from './AddPaymentMethodButton';
import { getOrganization } from '../../reducers/Organization';

// subscription: {
//   id: "sub_GBMPjurDbbqzAw",
//   created: 1573804667,
//   status: 1573804667,
//   start_date: 1573804667,
//   cancel_at: null,
//   canceled_at: null,
//   trial_end: 1576396667,
//   plan: {
//     id: "individual_yearly",
//     amount: 5000,
//     currency: "usd",
//     interval: "year",
//     nickname: "Individual Yearly"
//   }
// }

const toDateFormat = date => moment.unix(date).format('MMMM Do YYYY');

const SubscriptionDetailRow = ({name, value}) => (
  <TableRow>
    <TableCell align="right" ><Typography variant="button">{name}</Typography></TableCell>
    <TableCell>{value}</TableCell>
  </TableRow>
);

const OrganizationSubscription = (props) => {
  const { organization } = props;
  if (!organization) return (<div />);
  const { subscription } = organization;
  return (subscription
    ? (
      <Grid container>
        <Typography variant="h6">Organization: {organization.name}</Typography>
        <Grid container justify="center">
          <Box width="50%" minWidth={300} margin={2}>
            <Typography variant="subtitle1">Subscription Details</Typography>
            <Paper>
              <Table size="small" aria-label="a dense table">
                <TableBody>
                  <SubscriptionDetailRow name="Start Date" value={toDateFormat(subscription.start_date)} />
                  {subscription.canceled_at && <SubscriptionDetailRow name="Cancelled At" value={toDateFormat(subscription.canceled_at)} />}
                  {subscription.cancel && <SubscriptionDetailRow name="Cancel At" value={toDateFormat(subscription.cancel)} />}
                  {subscription.trial_end && <SubscriptionDetailRow name="Trial End Date" value={toDateFormat(subscription.trial_end)} />}
                  <SubscriptionDetailRow name="Plan" value={subscription.plan.nickname} />
                  <SubscriptionDetailRow name="Price" value={`${subscription.plan.amount / 100} ${subscription.plan.currency.toLocaleUpperCase()} / ${subscription.plan.interval}`} />
                </TableBody>
              </Table>
            </Paper>
          </Box>
        </Grid>
        <PaymentMethods />
        <AddPaymentMethodButton />
      </Grid>
    )
    : (
      <Grid container spacing={0} direction="column" alignItems="center" justify="center">
        <Grid item>
          <Typography variant="h6">No Subscription!</Typography>
          <PaymentMethods />
          <AddPaymentMethodButton />
        </Grid>
      </Grid>
    )
  );
};

OrganizationSubscription.defaultProps = {
  organization: null,
};

OrganizationSubscription.propTypes = {
  organization: PropTypes.shape({}),
};

const mapStateToProps = state => ({
  organization: getOrganization(state),
});

export default connect(
  mapStateToProps,
  null,
)(OrganizationSubscription);
