import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EmailIcon from '@material-ui/icons/EmailTwoTone';

import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Grid,
  Button,
  Card,
  Hidden,
  Tooltip,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { resendInvitation } from '../../actions/Invitations';
import { deleteUser } from '../../actions/Users';
import Gravatar from '../UI/Gravatar';
import { getOrganization } from '../../reducers/Organization';
import NoActiveSubscription from './Errors/NoActiveSubscription';
import InsufficientUserSubscription from './Errors/InsufficientUserSubscription';
import Header from "../NewDashboard/dashboard-components/Header";

const styles = theme => ({
  card: {
    // height: '100%',
    // marginTop: theme.spacing(4),
    // marginBottom: theme.spacing(4),
    maxWidth: theme.spacing(120),
    width: '100%',
    // minWidth: theme.spacing(90),
  },
});

class OrganizationUserList extends Component {
  resendInvitation = (user) => {
    const { resendInvitationAction } = this.props;
    resendInvitationAction(user.id);
  };

  deleteUser = (user) => {
    const { deleteUserAction } = this.props;
    deleteUserAction(user.id);
  };

  render() {
    const { organization, classes } = this.props;
    if (!organization) {
      return <Grid>Loading...</Grid>;
    }
    return (
      <Card className={classNames('shadow-xxl', classes.card)}>
        <List>
          {organization.users
          && organization.users.map(user => (
            <ListItem key={user.id}>
              <ListItemAvatar>
                <Gravatar email={user.email} size={60} />
              </ListItemAvatar>
              <ListItemText
                primary={`${
                  user.name
                } (${user.organization_role.toUpperCase()})`}
                secondary={user.email}
              />
              <ListItemSecondaryAction>
                {user.invited_to_sign_up && !user.invitation_accepted && (
                  <Tooltip title="Click here to resend invitation to the user">
                    <Button
                      color="primary"
                      onClick={() => this.resendInvitation(user)}
                    >
                      <EmailIcon />
                      <Hidden xsDown>Resend Invitation</Hidden>
                    </Button>
                  </Tooltip>
                )}
                <Tooltip title="Click here to delete user">
                  <Button
                    color="secondary"
                    onClick={() => window.confirm('Are you sure you wish to DELETE the user?') && this.deleteUser(user)}
                  >
                    <DeleteIcon />
                    <Hidden xsDown>Delete User</Hidden>
                  </Button>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Card>
    );
  }
}

OrganizationUserList.defaultProps = {
  organization: null,
};

OrganizationUserList.propTypes = {
  organization: PropTypes.shape({}),
  resendInvitationAction: PropTypes.func.isRequired,
  deleteUserAction: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  organization: getOrganization(state),
});

const mapDispatchToProps = dispatch => ({
  resendInvitationAction: (userId) => {
    const action = resendInvitation(userId);
    return dispatch(action).payload;
  },

  deleteUserAction: (userId) => {
    const action = deleteUser(userId);
    return dispatch(action).payload;
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(OrganizationUserList));
