import React from 'react';
import { List, Link } from '@material-ui/core';
import StarListItem from './StarListItem';
import Tour from './Tour';

const Tour3 = () => (
  <Tour
    title="How to add content faster?"
    image="/assets/images/tour/product-tour3.jpeg"
    previousUrl="/tour/2"
    nextUrl="/tour/4"
  >
    <List dense>
      <StarListItem>
        <Link href="https://chrome.google.com/webstore/detail/iselo-chrome-extension/jaefgmphcmcmkggipibkmjemeflhokdm" target="_blank">Click here to install our Chrome Extension </Link>
      </StarListItem>
      <StarListItem>
        Login into Chrome Extension (One time activity)
      </StarListItem>
      <StarListItem>
        Just click on the Chrome Extension Icon to save to ISELO
      </StarListItem>
    </List>
  </Tour>
);

export default Tour3;
