import MixpanelClient from './client';
import { MIXPANEL_ADDED_GROUP_MEMBER, MIXPANEL_UPDATED_GROUP_MEMBER, MIXPANEL_REMOVED_GROUP_MEMBER } from './events';

// responseStatus can be 'success' OR 'failure'
const trackGroupMembershipEvent = (eventNameConstant, membership, responseStatus, errorMessage) => {
  MixpanelClient.track(eventNameConstant, {
    'Group Name': membership?.group?.name,
    'User Email': membership?.user?.email,
    'User Name': membership?.user?.name,
    'Response Status': responseStatus,
    'Response Error Message': errorMessage,
  });
};

export const trackAddedGroupMember = (membership, responseStatus = 'success', errorMessage = null) => {
  trackGroupMembershipEvent(MIXPANEL_ADDED_GROUP_MEMBER, membership, responseStatus, errorMessage);
};

export const trackUpdatedGroupMember = (membership, responseStatus = 'success', errorMessage = null) => {
  trackGroupMembershipEvent(MIXPANEL_UPDATED_GROUP_MEMBER, membership, responseStatus, errorMessage);
};

export const trackRemovedGroupMember = (membership, responseStatus = 'success', errorMessage = null) => {
  trackGroupMembershipEvent(MIXPANEL_REMOVED_GROUP_MEMBER, membership, responseStatus, errorMessage);
};
