import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

export default () => (
  <div className="app-sidebar--footer">
    <Button fullWidth color="primary" component={Link} to="/groups/new">
      <i className="fas fa-plus-circle" />
      &nbsp;
      Add New Group
    </Button>
  </div>
);
