import MixpanelClient from './client';
import { MIXPANEL_ADDED_COMMENT, MIXPANEL_UPDATED_COMMENT, MIXPANEL_REMOVED_COMMENT } from './events';

// responseStatus can be 'success' OR 'failure'
const trackCommentEvent = (eventNameConstant, comment, responseStatus, errorMessage) => {
  MixpanelClient.track(eventNameConstant, {
    // 'Comment User Id': comment
    // 'Content Id': comment.contentId,
    'Response Status': responseStatus,
    'Response Error Message': errorMessage,
  });
}

export const trackAddedComment = (comment, responseStatus = 'success', errorMessage = null) => {
  trackCommentEvent(MIXPANEL_ADDED_COMMENT, comment, responseStatus, errorMessage);
};

export const trackUpdatedComment = (comment = null, responseStatus = 'success', errorMessage = null) => {
  trackCommentEvent(MIXPANEL_UPDATED_COMMENT, comment, responseStatus, errorMessage);
};

export const trackRemovedComment = (comment = null, responseStatus = 'success', errorMessage = null) => {
  trackCommentEvent(MIXPANEL_REMOVED_COMMENT, comment, responseStatus, errorMessage);
};
