import { createSelector } from 'reselect';
import {
  SET_CURRENT_LIST_ID,
} from '../actions/types';
import { getCurrentOwner } from './CurrentOwner';

const initialState = { data: [] };
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_LIST_ID:
      return {
        ...state,
        currentListId: action.payload,
      };
    default:
      return state;
  }
};

export const getCurrentListId = state => state.CurrentList.currentListId;
export const getCurrentList = createSelector(
  getCurrentOwner,
  getCurrentListId,
  (currentOwner, currentListId) => currentOwner && currentOwner.lists.find(list => parseInt(list.id, 10) === parseInt(currentListId, 10)), // Comapare string and inte
);

export const getCurrentListName = createSelector(
  getCurrentList,
  currentList => (currentList && currentList.name),
);
