import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import ContentArea from '../Dashboard/ContentArea/ContentArea';
import Sidebar from './dashboard-components/Sidebar';
import Header from './dashboard-components/Header';
import { getCurrentUser } from '../../reducers/CurrentUser';
import { getOrganization } from '../../reducers/Organization';
import { GROUP, ORGANIZATION, USER } from '../../Constants';

// import { Sidebar, Header, Footer } from './dashboard-components';

const NewDashboard = (props) => {
  const {
    children,
    sidebarToggle,
    sidebarToggleMobile,
    sidebarFixed,
    headerFixed,
    headerSearchHover,
    headerDrawerToggle,
    footerFixed,
    contentBackground,
    match,
    currentUser,
    organization,
  } = props;

  return (
    <>
      <div
        className={classNames('app-wrapper', contentBackground, {
          'header-drawer-open': headerDrawerToggle,
          'app-sidebar-collapsed': sidebarToggle,
          'app-sidebar-mobile-open': sidebarToggleMobile,
          'app-sidebar-fixed': sidebarFixed,
          'app-header-fixed': headerFixed,
          'app-footer-fixed': footerFixed,
          'search-wrapper-open': headerSearchHover,
        })}
      >
        <div>
          <Sidebar />
        </div>
        <div className="app-main">
          <Header />
          <div className="app-content">
            <div className="app-content--inner">
              <div className="app-content--inner__wrapper">
                <Route
                  exact
                  path={`${match.path}/all`}
                  render={props => (
                    <ContentArea ownerId={Number(currentUser?.id)}/> // All Contents # ownerId is sent so that it rerenders after user fetch
                  )}
                />
                <Route
                  exact
                  path={`${match.path}/private`}
                  render={props => (
                    <ContentArea
                      ownerType={USER}
                      ownerId={Number(currentUser?.id)}
                    />
                  )}
                />
                <Route
                  path={`${match.path}/private/lists/:listId`}
                  render={props => (
                    <ContentArea
                      ownerType={USER}
                      ownerId={Number(currentUser?.id)}
                      listId={Number(props.match.params.listId)}
                    />
                  )}
                />
                <Route
                  exact
                  path={`${match.path}/groups/:groupId/lists/:listId`}
                  render={props => (
                    <ContentArea
                      ownerType={GROUP}
                      ownerId={Number(props.match.params.groupId)}
                      listId={Number(props.match.params.listId)}
                    />
                  )}
                />
                <Route
                  exact
                  path={`${match.path}/groups/:groupId`}
                  render={props => (
                    <ContentArea
                      ownerType={GROUP}
                      ownerId={Number(props.match.params.groupId)}
                      listId={null}
                    />
                  )}
                />

                <Route
                  exact
                  path={`${match.path}/organization`}
                  render={props => (
                    <ContentArea
                      ownerType={ORGANIZATION}
                      ownerId={Number(organization?.id)}
                      listId={null}
                    />
                  )}
                />

                <Route
                  exact
                  path={`${match.path}/organization/lists/:listId`}
                  render={props => (
                    <ContentArea
                      ownerType={ORGANIZATION}
                      ownerId={Number(organization?.id)}
                      listId={Number(props.match.params.listId)}
                    />
                  )}
                />
                <Route exact path={match.path}>
                  <Redirect to="/dashboard/private" />
                </Route>
              </div>
            </div>
            {/* <Footer /> */}
          </div>
        </div>
      </div>
    </>
  );
};

NewDashboard.propTypes = {
  children: PropTypes.node,
};

const mapStateToProps = state => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarFixed: state.ThemeOptions.sidebarFixed,
  headerFixed: state.ThemeOptions.headerFixed,
  headerSearchHover: state.ThemeOptions.headerSearchHover,
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,
  currentUser: getCurrentUser(state),
  organization: getOrganization(state),
  footerFixed: state.ThemeOptions.footerFixed,
  contentBackground: state.ThemeOptions.contentBackground,
});

export default connect(mapStateToProps)(NewDashboard);
