import React from 'react';
import { FormControl, InputLabel, Select } from '@material-ui/core';

export default ({ label, handleChange, value, children }) => (
  <FormControl fullWidth>
    <InputLabel id="list-select-label">{label}</InputLabel>
    <Select
      labelId="list-select-label"
      id="list-select"
      value={value}
      name="list_id"
      onChange={handleChange}
    >
      {children}
    </Select>
  </FormControl>
);
