import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import AddContentsActionBar from '../../NewDashboard/dashboard-components/AddContentsActionBar';

const styles = theme => ({
  root: {
    minHeight: '50vh',
  },
  icon: {
    width: theme.spacing(22),
    height: theme.spacing(22),
  },
  addCircleIcon: {
    marginRight: theme.spacing(1),
  },
  superPrimaryButton: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FFC107 90%)',
    color: '#000000',
  },
});

const noContentsPrivateScreen = () => (
  <Grid>
    {/* <Typography variant="h6" align="center">There are no contents here</Typography> */}
    <Typography variant="body1" align="center">Please use following buttons to add new content</Typography>
  </Grid>
);

const NoContentsScreen = (props) => {
  const { classes } = props;

  return (
    <Grid container direction="column" className={classes.root} alignItems="center" justify="center">
      <Grid>
        <Typography variant="h6">No Contents!</Typography>
      </Grid>
      <Grid>
        <i className="fas fa-inbox" style={{ fontSize: 100 }} />
      </Grid>
      { noContentsPrivateScreen() }
      <br />
      <Grid>
        <AddContentsActionBar />
      </Grid>
    </Grid>
  );
};

NoContentsScreen.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default connect(
  null,
  null,
)(withStyles(styles)(NoContentsScreen));
