import React from 'react';
import { Grid, FormControlLabel, Checkbox, Tooltip } from '@material-ui/core';

export default ({ checked, onChange }) => (
  <Grid container alignItems="center">
    <FormControlLabel
      control={(
        <Checkbox
          checked={checked}
          onChange={onChange}
          name="index_files_contents"
          color="primary"
        />
      )}
      label="Index File Contents"
    />
    <Grid item>
      <Tooltip title="Index file contents only when you would want to search using contents in the files attached. Indexing lot of files can also create noise in the search results.">
        <i className="fas fa-exclamation-triangle" style={{ fontSize: '24px', color: 'red' }} />
      </Tooltip>
    </Grid>
  </Grid>
);
