import {
  FETCH_CONTENT_REQUEST,
  FETCH_CONTENT_FAILURE,
  FETCH_CONTENT_SUCCESS,
} from '../actions/types';

const initialState = { data: null };
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONTENT_REQUEST:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case FETCH_CONTENT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_CONTENT_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        loading: false,
      };
    }
    default:
      return state;
  }
};

// Selectors
export const getContent = state => state.Content.data;
export const getContentLoading = state => state.Content.loading;
