import {
  FETCH_COMMENTS_REQUEST,
  FETCH_COMMENTS_FAILURE,
  FETCH_COMMENTS_SUCCESS,
  CREATE_COMMENT_REQUEST,
  CREATE_COMMENT_SUCCESS,
  CREATE_COMMENT_FAILURE,
  UPDATE_COMMENT_REQUEST,
  UPDATE_COMMENT_SUCCESS,
  UPDATE_COMMENT_FAILURE,
  DELETE_COMMENT_REQUEST,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_FAILURE,
} from '../actions/types';

const initialState = { data: [] };
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMMENTS_REQUEST:
      return {
        ...state,
        data: [],
        error: undefined,
        loading: true,
      };
    case FETCH_COMMENTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_COMMENTS_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        data: [],
        error,
        loading: false,
      };
    }
    case CREATE_COMMENT_REQUEST:
      return { ...state, error: undefined, loading: true };
    case CREATE_COMMENT_SUCCESS:
      return { ...state, data: [...state.data, action.payload], loading: false };
    case CREATE_COMMENT_FAILURE: {
      const { error } = action.payload;
      return { ...state, error, loading: false };
    }
    case UPDATE_COMMENT_REQUEST:
      return { ...state, error: undefined, loading: true };
    case UPDATE_COMMENT_SUCCESS: {
      const comment = action.payload;
      const commentIndex = state.data.findIndex(x => x.id === comment.id);
      const data = [...state.data];
      data.splice(commentIndex, 1, comment);
      return { ...state, data, loading: false };
    }
    case UPDATE_COMMENT_FAILURE: {
      // return error and make loading = false
      const { error } = action.payload;
      return { ...state, error, loading: false };
    }
    case DELETE_COMMENT_REQUEST:
      return { ...state, error: undefined, loading: true };
    case DELETE_COMMENT_SUCCESS: {
      const commentId = action.payload;
      const commentIndex = state.data.findIndex(x => x.id === commentId);
      const data = [...state.data];
      data.splice(commentIndex, 1);
      return { ...state, data, loading: false };
    }
    case DELETE_COMMENT_FAILURE: {
      const { error } = action.payload;
      return { ...state, error, loading: false };
    }
    default:
      return state;
  }
};

export const getComments = state => state.Comments.data;
export const getCommentsLoading = state => state.Comments.loading;
