/* global pendo */

import axios from 'axios';
import { enqueueMessage } from './Alerts';

import {
  LOG_OUT_REQUEST, LOG_OUT_SUCCESS, LOG_OUT_FAILURE,
  FETCH_CURRENT_USER_REQUEST, FETCH_CURRENT_USER_SUCCESS, FETCH_CURRENT_USER_FAILURE,
  DELETE_USER_REQUEST, DELETE_USER_SUCCESS, DELETE_USER_FAILURE,
} from './types';

import MixpanelClient from '../utils/mixpanel/client';
import Zendesk from '../utils/zendesk/zendesk';
import parseErrorMessage from '../utils/errorMessage';
import { fetchOrganization } from './Organization';
import { setAlgoliaSearchKey } from './Alogolia';
import { isUser } from '../utils/listOwnerTypes';

export const fetchCurrentUserRequest = () => ({
  type: FETCH_CURRENT_USER_REQUEST,
});

export const fetchCurrentUserSuccess = user => ({
  type: FETCH_CURRENT_USER_SUCCESS,
  payload: user,
});

export const fetchCurrentUserFailure = data => ({
  type: FETCH_CURRENT_USER_FAILURE,
  payload: {
    error: data.message,
  },
});

export const fetchCurrentUser = () => (dispatch, getState) => {
  dispatch(fetchCurrentUserRequest());

  return axios
    .get('/users/me')
    .then((response) => {
      const user = response.data;
      MixpanelClient.identify(user.id);
      Zendesk.identify(user);
      pendo.initialize({
        visitor: {
          id: user.id, // Required if user is logged in
          email: user.email,
          full_name: user.name,
        },
        account: {
          id: user.organization_id, // Highly recommended
        },
      });
      dispatch(fetchCurrentUserSuccess(user));
      const { CurrentOwner } = getState();
      if (isUser(CurrentOwner.currentOwnerType)) {
        dispatch(setAlgoliaSearchKey(user.algolia_secured_api_key_private_contents));
      }
      return user;
    })
    .catch((error) => {
      dispatch(fetchCurrentUserFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
    });
};

export const deleteUserRequest = () => ({
  type: DELETE_USER_REQUEST,
});

export const deleteUserSuccess = () => ({
  type: DELETE_USER_SUCCESS,
});

export const deleteUserFailure = data => ({
  type: DELETE_USER_FAILURE,
  payload: {
    error: data.message,
  },
});

export const deleteUser = userId => (dispatch) => {
  dispatch(deleteUserRequest());

  return axios
    .delete(`/users/${userId}`)
    .then((response) => {
      dispatch(deleteUserSuccess(response.data));
      dispatch(fetchOrganization());
      dispatch(enqueueMessage('User Deleted Successfully', 'success'));
      return response.data;
    })
    .catch((error) => {
      dispatch(deleteUserFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
    });
};

export const logOutRequest = () => ({
  type: LOG_OUT_REQUEST,
});

export const logOutSuccess = () => ({
  type: LOG_OUT_SUCCESS,
});

export const logOutFailure = data => ({
  type: LOG_OUT_FAILURE,
  payload: {
    error: data.message,
  },
});

export const logOut = () => dispatch => axios
  .delete('/sessions/me')
  .then(() => {
    dispatch(fetchCurrentUser());
  })
  .catch((error) => {
    dispatch(logOutFailure(error));
    dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
  });
