import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Moment from 'moment';
import {
  IconButton,
  Typography,
  Badge,
  ListItemText,
  ListItemIcon,
  Tooltip,
  ListItemSecondaryAction,
  Popover,
  List,
  ListItem,
} from '@material-ui/core';
import UnreadIcon from '@material-ui/icons/Brightness1';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withRouter } from 'react-router-dom';
import { getNotifications } from '../../reducers/Notifications';
import {
  fetchNotifications,
  updateNotification,
  deleteNotification,
} from '../../actions/Notifications';

const styles = theme => ({
  middle: {
    marginTop: 'auto',
    marginBottom: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    float: 'right',
    cursor: 'pointer',
  },
  avatar: {
    width: 40,
    height: 40,
  },
  iconSmall: {
    fontSize: 20,
    marginRight: 10,
  },
  logo: {
    width: '150px',
    [theme.breakpoints.down('xs')]: {
      width: '100px',
    },
  },
  unReadListItem: {
    backgroundColor: 'rgba(34,150,243,0.15)',
  },
});
class NotificationsMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  componentDidMount() {
    const { fetchNotificationsAction } = this.props;
    fetchNotificationsAction();
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleDeleteButtonClick = (notification) => {
    const { deleteNotificationAction } = this.props;
    deleteNotificationAction(notification.id);
  }

  handleMenuItemClick = (notification) => {
    const { history, updateNotificationAction } = this.props;
    updateNotificationAction({
      ...notification,
      read: true,
    });
    if (notification.path) history.replace(notification.path);
    this.handleClose();
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, notifications } = this.props;
    const { anchorEl } = this.state;

    return (
      <div>
        <Tooltip
          title={
            notifications.length > 0
              ? 'Notifications'
              : 'You have NO notifications!'
          }
          placement="bottom"
        >
          <div>
            {/* https://github.com/mui-org/material-ui/pull/12265/files */}
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={this.handleClick}
              className={classes.iconButton}
              disabled={!notifications || notifications.length === 0}
            >
              <Badge
                badgeContent={notifications.filter(n => !n.read).length}
                invisible={!notifications || notifications.filter(n => !n.read).length === 0}
                color="secondary"
              >
                <i className="fas fa-bell" />
              </Badge>
            </IconButton>
          </div>
        </Tooltip>
        <Popover
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className="popover-custom-lg overflow-hidden">
            <div className="bg-composed-wrapper bg-midnight-bloom border-0 text-center rounded-sm m-2">
              <div className="bg-composed-img-3 bg-composed-wrapper--image" />
              <div className="bg-composed-wrapper--content text-white px-2 py-4">
                <h4 className="font-size-xl font-weight-bold mb-2">
                  Notifications
                </h4>
                <p className="opacity-8 mb-0">
                  You have
                  {' '}
                  <b className="text-success">{notifications.filter(n => !n.read).length}</b>
                  {' '}
                  unread notifications.
                </p>
              </div>
            </div>
            <div className="height-280">
              <PerfectScrollbar>
                <List>
                  {notifications && notifications.map(notification => (
                    <ListItem onClick={() => this.handleMenuItemClick(notification)} key={notification.id} className={!notification.read ? classes.unReadListItem : ''}>
                      <ListItemIcon color="primary">
                        {!notification.read
                          && (
                            <Tooltip title="Unread Notification">
                              <UnreadIcon color="primary" />
                            </Tooltip>
                          )
                        }
                      </ListItemIcon>

                      <ListItemText
                        primary={(
                          <Typography variant="body2">
                            {notification.description}
                          </Typography>
                        )}
                        secondary={Moment(notification.created_at).fromNow()}
                      />
                      <ListItemSecondaryAction>
                        <IconButton size="small" color="secondary" edge="end" aria-label="delete" onClick={() => this.handleDeleteButtonClick(notification)}>
                          <i className="fa fa-trash-alt" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </PerfectScrollbar>
            </div>
          </div>
        </Popover>
      </div>
    );
  }
}

NotificationsMenu.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  notifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchNotificationsAction: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  notifications: getNotifications(state),
});

const mapDispatchToProps = dispatch => ({
  fetchNotificationsAction: () => dispatch(fetchNotifications()),
  updateNotificationAction: notification => dispatch(updateNotification(notification)),
  deleteNotificationAction: id => dispatch(deleteNotification(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(NotificationsMenu)));
