import {
  SEARCH_TEXT_CHANGED,
  TAGS_FILTER_CHANGED,
  RESET_FILTERS,
  SORT_BY_CHANGED,
  SHOW_ONLY_STARRED_CHANGED,
  SHOW_WITH_ARCHIVED_CHANGED,
} from './types';

export const searchTextChanged = searchText => dispatch => dispatch({
  type: SEARCH_TEXT_CHANGED,
  payload: searchText,
});

export const tagsFilterChanged = selectedTags => dispatch => dispatch({
  type: TAGS_FILTER_CHANGED,
  payload: selectedTags,
});

export const sortByChanged = sortBy => dispatch => dispatch({
  type: SORT_BY_CHANGED,
  payload: sortBy,
});

export const resetFilters = () => dispatch => dispatch({
  type: RESET_FILTERS,
});

export const showOnlyStarredChanged = val => dispatch => dispatch({
  type: SHOW_ONLY_STARRED_CHANGED,
  payload: val,
});

export const showWithArchivedChanged = val => dispatch => dispatch({
  type: SHOW_WITH_ARCHIVED_CHANGED,
  payload: val,
});
