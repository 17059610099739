import React from 'react';
import axios from 'axios';

import { loadStripe } from '@stripe/stripe-js';
import { Button, Link } from '@material-ui/core';

// mode can be :
// 'subscription' for starting subscription
// 'setup' for saving payment methods for future payments
const StripeCheckoutSessionButton = ({ mode, text, interval = 'monthly' }) => {
  const creatCheckoutSession = async () => {
    const stripeConfig = await axios.get('/stripe/config');
    const stripe = await loadStripe(stripeConfig.data.publishableKey);
    axios.post('/stripe/checkout_session', { mode: mode || 'subscription', interval })
      .then((response) => {
        const { data } = response;
        stripe.redirectToCheckout({
          sessionId: data.sessionId,
        }).then((result) => {
          console.log(result);
        }).catch((err) => {
          console.log(err);
        });
      })
      .catch((error) => {
        console.log('Error Creating Checkout Session', error);
      });
  };
  return (
    <Button
      variant="contained"
      aria-label="customer-portal"
      size="large"
      component={Link}
      onClick={creatCheckoutSession}
      className="btn-primary btn-gradient shadow-none bg-night-sky ml-2"
    >
      {text || 'Start Subscription'}
    </Button>
  );
};

export default StripeCheckoutSessionButton;
