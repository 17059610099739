import React from 'react';
import PropTypes from 'prop-types';

import {
  ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';

const StarListItem = ({ children }) => (
  <ListItem>
    <ListItemIcon>
      <i className="fa fa-star" />
    </ListItemIcon>
    <ListItemText
      primary={children}
    />
  </ListItem>
);

StarListItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StarListItem;
