import React from 'react';
import { List } from '@material-ui/core';
import StarListItem from './StarListItem';
import Tour from './Tour';

const Tour4 = () => (
  <Tour
    title="Organization, Users and Groups"
    image="/assets/images/tour/product-tour4.jpeg"
    previousUrl="/tour/3"
    nextUrl="/tour/5"
  >
    <List dense>
      <StarListItem>
        Step1: Invite users to register with your organization account
      </StarListItem>
      <StarListItem>
        Step2: Create group(s) and add any registered users to the new group(s)
      </StarListItem>
    </List>
  </Tour>
);

export default Tour4;
