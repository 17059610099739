import React from 'react';
import { List } from '@material-ui/core';
import StarListItem from './StarListItem';
import Tour from './Tour';

const Tour2 = () => (
  <Tour
    title="How to add content to ISELO?"
    image="/assets/images/tour/product-tour2.jpeg"
    previousUrl="/tour/1"
    nextUrl="/tour/3"
  >
    <List dense>
      <StarListItem>
        Just click on &quot;Add Link&quot; to add new content.
      </StarListItem>
    </List>
  </Tour>
);

export default Tour2;
