import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import Moment from 'moment';
import classNames from 'classnames';
import {
  CardActions, Link,
} from '@material-ui/core';
import ContentEditButton from '../ContentCardActions/ContentEditButton';
import ContentArchiveButton from '../ContentCardActions/ContentArchiveButton';
import ContentShareButton from '../ContentCardActions/ContentShareButton';
import CommentsButton from '../ContentCardActions/CommentsButton';
// import AddToGoogleCalenderButton from '../ContentCardActions/AddToGoogleCalenderButton';
import ContentDeleteButton from '../ContentCardActions/ContentDeleteButton';
import ContentCardDialog from '../ContentCardDialog';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  clickable: {
    cursor: 'pointer',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  content: {
    flex: '1 0 auto',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cover: {
    width: 300,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

const ContentLongCard = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { hit } = props;

  const dialogOpen = () => setOpen(true);
  const dialogClose = () => setOpen(false);
  const handleCardClick = () => dialogOpen();

  const contentUrl = () => {
    if (hit.links && hit.links.length === 1) return hit.links[0].url;
    if (hit.files && hit.files.length === 1) return hit.files[0].path;
    if (hit.one_drive_files && hit.one_drive_files.length === 1) return hit.one_drive_files[0].web_url;
    return null;
  };

  return (
    <>
      <Card className={classNames(classes.root, 'mr-5 ml-5 mt-4', classes.clickable)} onClick={handleCardClick}>
        {hit.image && (
        <CardMedia
          className={classes.cover}
          image={hit.image}
          title={hit.title}
        />
        )}
        <div className={classes.details}>
          {/* <div className="badge text-primary badge-neutral-primary">
          {hit.owner_type === 'User' ? 'PRIVATE' : (hit.owner_type === 'Group' ? `GROUP: ${hit.owner_name}` : 'ORGANIZATION')}
          {' > '}
          { hit.list_name && (hit.list_name) }
        </div> */}
          <div className={classNames(classes.content, 'mt-3 mr-3 ml-3')}>
            <Typography variant="h6">
              <Link
                href={contentUrl(hit)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {hit.title}
              &nbsp;
                {contentUrl(hit) && (
                <i className="fas fa-external-link-square-alt" />
                )}
              </Link>
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              <i className="mr-1 far fa-clock" />
              <small>{`Added ${Moment(hit.created_at * 1000).fromNow()} by ${(hit.user && hit.user.name) || (hit.creator && hit.creator.name)}`}</small>
            </Typography>
            {hit.tags.length > 0 && (
            <div className="d-flex align-items-flex-end justify-content-flex-end flex-wrap">
              {hit.tags.map(tag => <div key={tag} className="m-1 text-second badge badge-neutral-second">{tag}</div>)}
              {' '}
                    &nbsp;
              {/* {hit.tags.map(tag => <div className="badge badge-neutral-primary badge-pill m-1 text-primary">{tag}</div>)} &nbsp; */}
              {/* {hit.tags.map(tag => <div className="m-1 text-second badge badge-neutral-second">{tag}</div>)} &nbsp; */}
            </div>
            )}

          </div>
          <CardActions disableSpacing>
            <ContentEditButton content={hit} iconOnly />
            {/* <ContentShareButton content={hit} iconOnly /> */}
            {/* <CommentsButton content={hit} iconOnly /> */}
            <ContentArchiveButton content={hit} iconOnly />
            <ContentDeleteButton content={hit} iconOnly />
            {/* <AddToGoogleCalenderButton content={hit} /> */}
          </CardActions>
        </div>
      </Card>
      <ContentCardDialog content={hit} open={open} handleClose={dialogClose} />
    </>
  );
};

export default ContentLongCard;
