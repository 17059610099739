import React from 'react';
import { Button } from '@material-ui/core';
import { connectCurrentRefinements } from 'react-instantsearch-dom';

const MaterialUiClearRefinements = ({ items, refine }) => (
  <Button variant="contained" color="secondary" size="small" onClick={() => refine(items)} disabled={!items.length}>
    Clear all filters
  </Button>
);

export default connectCurrentRefinements(MaterialUiClearRefinements);
