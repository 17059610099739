import React from 'react';
import { Button, Link } from '@material-ui/core';
import { createCustomerPortalSession } from '../../utils/stripe/client';

export default ({ caption }) => {
  const customerPortalButtonHandler = () => {
    createCustomerPortalSession().then((response) => {
      const { data } = response;
      window.location = data.url;
    });
  };

  return (
    <Button
      variant="contained"
      aria-label="customer-portal"
      size="large"
      component={Link}
      onClick={customerPortalButtonHandler}
      className="btn-primary btn-gradient shadow-none bg-night-sky ml-2"
    >
      {caption || 'Update Subscription'}
    </Button>
  );
};
