import React from 'react';
import PropTypes from 'prop-types';
import md5 from 'md5';
import { Avatar, Tooltip } from '@material-ui/core';

const Gravatar = ({ email, size }) => {
  const styles = {
    width: size,
    height: size,
  };
  if (email) {
    const emailHash = md5(email.trim().toLowerCase());
    return (
      <Tooltip title={email} aria-label={email}>
        <Avatar
          style={styles}
          src={`https://www.gravatar.com/avatar/${emailHash}.jpg?s=${size}&d=robohash`}
        />
      </Tooltip>
    );
  }
  return <Avatar />;
};

const defaultSize = 40;
Gravatar.defaultProps = {
  size: defaultSize,
};

Gravatar.propTypes = {
  email: PropTypes.string.isRequired,
  size: PropTypes.number,
};

export default Gravatar;
