import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Grid } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';

import { fetchContent } from '../../actions/Content';
import { fetchComments } from '../../actions/Comments';
import CommentList from './CommentsList/CommentList';
import CommentEditor from './CommentEditor/CommentEditor';
import ContentCard from '../ContentCard/ContentCard';
import { getContentParentDashboardPath } from '../../utils/listOwnerTypes';
import Header from '../NewDashboard/dashboard-components/Header';

class ContentDetails extends Component {
  componentDidMount() {
    const { match } = this.props;
    this.loadData(match.params.contentId);
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { match } = this.props;
    const oldContentId = prevProps.match.params.contentId;
    const newContentId = match.params.contentId;
    if (newContentId && oldContentId && newContentId !== oldContentId) {
      this.loadData(newContentId);
    }
  }

  loadData = (contentId) => {
    const { getContent, getComments } = this.props;
    getContent(contentId);
    getComments(contentId);
  }

  redirectToContentList = () => {
    const { history, content } = this.props;
    history.replace(getContentParentDashboardPath(content));
  };

  render() {
    const { content, comments } = this.props;

    const contentDetails = content === null ? null : (
      <>
        <Header />

        <Grid container justify="center" direction="column" alignContent="center">
          <Grid xs />
          <Grid item xs={8}>
            <Button size="small" color="primary" onClick={this.redirectToContentList}>
              <ArrowBack />
              All Contents
            </Button>
            <ContentCard content={content} showActions={false} />
            <CommentList comments={comments} />
            <CommentEditor contentId={content.id} />
          </Grid>
          <Grid xs />
        </Grid>
      </>
    );

    return contentDetails;
  }
}

ContentDetails.defaultProps = {
  content: null,
};

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  return {
    // content: state.Contents.data.find(b => b.id === match.params.contentId),
    content: state.Content.data,
    comments: state.Comments.data,
  };
};

const mapDispatchToProps = dispatch => ({
  getContent: contentId => dispatch(fetchContent(contentId)),
  getComments: contentId => dispatch(fetchComments(contentId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContentDetails);
