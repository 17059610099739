import React from 'react';
import { List } from '@material-ui/core';
import StarListItem from './StarListItem';
import Tour from './Tour';

const Tour1 = () => (
  <Tour
    title="Welcome to ISELO!"
    image="/assets/images/tour/product-tour1.jpeg"
    nextUrl="/tour/2"
  >
    <List dense>
      <StarListItem>
        You can save content in Private or Group Spaces
      </StarListItem>
      <StarListItem>
        Keep your content organized with Tags
      </StarListItem>
      <StarListItem>
        Everything saved is searchable
      </StarListItem>
    </List>
  </Tour>
);

export default Tour1;
