import uniq from 'lodash/uniq';
import pick from 'lodash/pick';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import Fuse from 'fuse.js';
import { convertFromRaw } from 'draft-js';

import { createSelector } from 'reselect';
import {
  FETCH_CONTENTS_REQUEST,
  FETCH_CONTENTS_FAILURE,
  FETCH_CONTENTS_SUCCESS,
  FETCH_CONTENT_REQUEST,
  FETCH_CONTENT_FAILURE,
  FETCH_CONTENT_SUCCESS,
  CREATE_CONTENT_REQUEST,
  CREATE_CONTENT_SUCCESS,
  CREATE_CONTENT_FAILURE,
  UPDATE_CONTENT_REQUEST,
  UPDATE_CONTENT_SUCCESS,
  UPDATE_CONTENT_FAILURE,
  UPDATE_CONTENT_USER_PROPERTIES_REQUEST,
  UPDATE_CONTENT_USER_PROPERTIES_SUCCESS,
  UPDATE_CONTENT_USER_PROPERTIES_FAILURE,
  DELETE_CONTENT_REQUEST,
  DELETE_CONTENT_SUCCESS,
  DELETE_CONTENT_FAILURE,
  UPDATE_TASK_REQUEST,
  UPDATE_TASK_SUCCESS,
  UPDATE_TASK_FAILURE,
  DELETE_TASK_REQUEST,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_FAILURE,
  UPDATE_LINK_REQUEST,
  UPDATE_LINK_SUCCESS,
  UPDATE_LINK_FAILURE,
  DELETE_LINK_REQUEST,
  DELETE_LINK_SUCCESS,
  DELETE_LINK_FAILURE,
  DELETE_FILE_REQUEST,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_FAILURE,
  TOGGLE_STARRED_REQUEST,
  TOGGLE_STARRED_SUCCESS,
  TOGGLE_STARRED_FAILURE,
} from '../actions/types';
import TaskReducer from './Tasks';
import LinkReducer from './Links';
import FileReducer from './Files';

const initialState = { data: [] };
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONTENTS_REQUEST:
      return {
        ...state,
        data: [],
        error: undefined,
        loading: true,
      };
    case FETCH_CONTENTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_CONTENTS_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        data: [],
        error,
        loading: false,
      };
    }
    case CREATE_CONTENT_REQUEST:
      return { ...state, error: undefined, loading: true };
    case CREATE_CONTENT_SUCCESS:
      return { ...state, data: [...state.data, action.payload], loading: false };
    case CREATE_CONTENT_FAILURE: {
      const { error } = action.payload;
      return { ...state, error, loading: false };
    }
    case UPDATE_CONTENT_REQUEST:
    case UPDATE_CONTENT_USER_PROPERTIES_REQUEST:
      return { ...state, error: undefined, loading: true };
    case UPDATE_CONTENT_SUCCESS:
    case UPDATE_CONTENT_USER_PROPERTIES_SUCCESS: {
      const content = action.payload;
      const contentIndex = state.data.findIndex(x => x.id === content.id);
      const data = [...state.data];
      data.splice(contentIndex, 1, content);
      return { ...state, data, loading: false };
    }
    case UPDATE_CONTENT_FAILURE:
    case UPDATE_CONTENT_USER_PROPERTIES_FAILURE: {
      const { error } = action.payload;
      return { ...state, error, loading: false };
    }
    case DELETE_CONTENT_REQUEST:
      return { ...state, error: undefined, loading: true };
    case DELETE_CONTENT_SUCCESS: {
      const contentId = action.payload;
      const contentIndex = state.data.findIndex(x => x.id === contentId);
      const data = [...state.data];
      data.splice(contentIndex, 1);
      return { ...state, data, loading: false };
    }
    case DELETE_CONTENT_FAILURE: {
      const { error } = action.payload;
      return { ...state, error, loading: false };
    }
    case TOGGLE_STARRED_REQUEST:
      return { ...state, error: undefined };
    case TOGGLE_STARRED_SUCCESS: {
      const content = action.payload;
      const contentIndex = state.data.findIndex(x => x.id === content.id);
      const data = [...state.data];
      data.splice(contentIndex, 1, content);
      return { ...state, data };
    }
    case TOGGLE_STARRED_FAILURE: {
      const { error } = action.payload;
      return { ...state, error };
    }

    case UPDATE_TASK_REQUEST:
    case UPDATE_TASK_SUCCESS:
    case UPDATE_TASK_FAILURE:
    case DELETE_TASK_REQUEST:
    case DELETE_TASK_SUCCESS:
    case DELETE_TASK_FAILURE:
      return TaskReducer(state, action);
    case UPDATE_LINK_REQUEST:
    case UPDATE_LINK_SUCCESS:
    case UPDATE_LINK_FAILURE:
    case DELETE_LINK_REQUEST:
    case DELETE_LINK_SUCCESS:
    case DELETE_LINK_FAILURE:
      return LinkReducer(state, action);
    case DELETE_FILE_REQUEST:
    case DELETE_FILE_SUCCESS:
    case DELETE_FILE_FAILURE:
      return FileReducer(state, action);
    default:
      return state;
  }
};

// Selectors
export const getContents = state => state.Contents.data;
export const getContentsLoading = state => state.Contents.loading;
export const getSearchText = state => state.Filters.searchText;
export const getStarredFilter = state => state.Filters.showOnlyStarred;
export const getShowWithArchivedFilter = state => state.Filters.showWithArchived;
export const getSelectedTags = state => state.Filters.selectedTags;
export const getSortByFilter = state => state.Filters.sortBy;

export const getContentsCount = createSelector(
  getContents,
  contents => contents.length,
);

export const getTags = createSelector(
  getContents,
  contents => uniq(contents.map(b => b.tags).flat()),
);

export const getFilteredContents = createSelector(
  getContents,
  getSearchText,
  getStarredFilter,
  getShowWithArchivedFilter,
  getSelectedTags,
  (contents, searchText, showOnlyStarred, showWithArchived, selectedTags) => {
    const filteredContents = contents.filter((content) => {
      const { starred, archived_at } = content;

      if (showWithArchived || !archived_at) {
        if (!showOnlyStarred || starred) {
          if (selectedTags && selectedTags.length > 0) {
            return selectedTags.every(selectedTag => content.tags.includes(selectedTag));
          }
          return true;
        }
      }
      return false;
    });

    const filteredContentsWithDescription = filteredContents.map((content) => {
      let descriptionText;
      if (content.description) {
        try {
          descriptionText = convertFromRaw(JSON.parse(content.description)).getPlainText();
        } catch (e) {
          descriptionText = content.description;
        }
      }
      const linksTitleUrl = map(content.links, link => Object.values(pick(link, ['title', 'url']))).toString();
      const tagsString = content.tags.toString();
      const fullSearchableText = `${tagsString} ${content.title} ${descriptionText} ${linksTitleUrl} ${content.user.name} ${content.user.email}`;
      return { ...content, descriptionText, fullSearchableText };
    });

    // https://fusejs.io/
    const fuseOptions = {
      threshold: 0.4,
      distance: 400,
      keys: ['fullSearchableText'],
    };

    if (searchText && searchText.length > 0 ) {
      const fuse = new Fuse(filteredContentsWithDescription, fuseOptions);
      return fuse.search(searchText).map(e => e.item);
    }
    return filteredContents;
  },
);


export const getFilteredTags = createSelector(
  getFilteredContents,
  contents => uniq(contents.map(b => b.tags).flat()),
);

export const getSortedFilteredContents = createSelector(
  getFilteredContents,
  getSortByFilter,
  getSearchText,
  (contents, sortByParams, searchText) => {
    // Ignore sorting by sortParams; as it'll be sorted by search relavance
    if (searchText && searchText.length > 0) {
      return contents;
    }
    let sortByKey = null;
    let sortByOrder = null;
    [sortByKey, sortByOrder] = sortByParams.split(':');
    const sortedContents = sortBy(contents, (content) => {
      if (sortByKey === 'title') {
        return content[sortByKey].toString().toLowerCase();
      }

      return +new Date(content[sortByKey]);
    });

    if (sortByOrder === 'desc') {
      sortedContents.reverse();
    }

    return sortedContents;
  },
);
