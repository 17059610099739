import produce from 'immer';

import {
  UPDATE_LINK_REQUEST, UPDATE_LINK_SUCCESS, UPDATE_LINK_FAILURE,
  DELETE_LINK_REQUEST, DELETE_LINK_SUCCESS, DELETE_LINK_FAILURE,
} from '../actions/types';

const initialState = { loading: false, data: [] };

// Update Link
const updateLinkRequest = state => produce(state, (draft) => {
  draft.loading = true;
  draft.error = null;
});

const updateLinkSuccess = (state, action) => produce(state, (draft) => {
  const link = action.payload;
  const contentIndex = draft.data.findIndex(x => x.id.toString() === link.content_id.toString());
  const content = draft.data.find(x => x.id.toString() === link.content_id.toString());
  if (content && contentIndex && link) {
    const linkIndex = content.links.findIndex(x => x.id.toString() === link.id.toString());
    draft.data[contentIndex].links[linkIndex] = link;
  }

  draft.loading = false;
});

const updateLinkFailure = (state, action) => produce(state, (draft) => {
  const { error } = action.payload;
  draft.loading = false;
  draft.error = error;
});

// DELETE Link
const deleteLinkRequest = state => produce(state, (draft) => {
  draft.loading = true;
  draft.error = null;
});

const deleteLinkSuccess = (state, action) => produce(state, (draft) => {
  const link = action.payload;
  const contentIndex = draft.data.findIndex(x => x.id.toString() === link.content_id.toString());
  const content = draft.data.find(x => x.id.toString() === link.content_id.toString());
  if (content && contentIndex && link) {
    const linkIndex = content.links.findIndex(x => x.id.toString() === link.id.toString());
    draft.data[contentIndex].links.splice(linkIndex, 1);
  }

  draft.loading = false;
});

const deleteLinkFailure = (state, action) => produce(state, (draft) => {
  const { error } = action.payload;
  draft.loading = false;
  draft.error = error;
});

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_LINK_REQUEST:
      return updateLinkRequest(state);
    case UPDATE_LINK_SUCCESS:
      return updateLinkSuccess(state, action);
    case UPDATE_LINK_FAILURE:
      return updateLinkFailure(state, action);
    case DELETE_LINK_REQUEST:
      return deleteLinkRequest(state);
    case DELETE_LINK_SUCCESS:
      return deleteLinkSuccess(state, action);
    case DELETE_LINK_FAILURE:
      return deleteLinkFailure(state, action);
    default:
      return state;
  }
};
