import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Typography,
  Badge,
  Menu,
  Button,
  List,
  ListItem,
  Tooltip,
  Divider,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import PaymentIcon from '@material-ui/icons/Payment';
import BusinessIcon from '@material-ui/icons/Business';
import ExitIcon from '@material-ui/icons/ExitToApp';
import { withStyles } from '@material-ui/core/styles';
import { createCustomerPortalSession } from '../../../../utils/stripe/client';
import { getCurrentUser } from '../../../../reducers/CurrentUser';
import { logOut } from '../../../../actions/Users';
import Gravatar from "../../../UI/Gravatar";

const StyledBadge = withStyles({
  badge: {
    backgroundColor: 'var(--success)',
    color: 'var(--success)',
    boxShadow: '0 0 0 2px #fff',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
})(Badge);

const HeaderUserbox = (props) => {
  const { user } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const billingMenuItemHandler = () => {
    createCustomerPortalSession().then((response) => {
      const { data } = response;
      window.location = data.url;
    });
  };

  const handleLogout = (e) => {
    e.stopPropagation();
    const { logOut } = props;
    logOut();
  };

  return (
    <>
      <Button
        variant="text"
        onClick={handleClick}
        className="ml-2 btn-transition-none text-left ml-2 p-0 bg-transparent d-flex align-items-center"
        disableRipple
      >
        <div className="d-block p-0 avatar-icon-wrapper">
          <StyledBadge
            overlap="circle"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            badgeContent=" "
            classes={{ badge: 'bg-success badge-circle border-0' }}
            variant="dot"
          >
            <div className="avatar-icon rounded">
              <Gravatar email={user && user.email} />
              {/* <img src={`https://www.gravatar.com/avatar/${emailHash}.jpg?s=${size}&d=robohash`} alt="..." /> */}
            </div>
          </StyledBadge>
        </div>

        <div className="d-none d-xl-block pl-2">
          <div className="font-weight-bold line-height-1">{user && user.name}</div>
          {/* <span className="text-black-50">{user && user.email}</span> */}
        </div>
        <span className="pl-1 pl-xl-3">
          <i className="fas fa-angle-down opacity-5" />
        </span>
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        classes={{ list: 'p-0' }}
        onClose={handleClose}
      >
        <div className="dropdown-menu-lg overflow-hidden p-0">
          <div className="d-flex px-3 pt-3 align-items-center justify-content-between">
            <Typography variant="caption" className="pl-1 font-weight-bold text-dark">
              {user && user.email}
            </Typography>
          </div>
          {user && user.organization_role === 'admin' && (
            <>
              <List
                component="div"
                className="nav-neutral-primary text-left d-flex align-items-center flex-column px-3 pb-3"
              >
                <ListItem className="d-block text-left" component={Link} to="#" onClick={billingMenuItemHandler}>
                  <PaymentIcon />
                  &nbsp;
                  Billing

                </ListItem>
                <ListItem button className="d-block text-left" component={Link} to="/organization/users">
                  <BusinessIcon />
                  &nbsp;
                  Organization Users
                </ListItem>
              </List>
              <Divider className="w-100" />
            </>
          )}

          <List
            component="div"
            className="nav-neutral-primary text-left d-flex align-items-center flex-column px-3 pb-3"
          >
            <ListItem button className="d-block text-left" onClick={handleLogout}>
              <ExitIcon />
              &nbsp;
              Logout
            </ListItem>
          </List>
        </div>
      </Menu>
    </>
  );
};

const mapStateToProps = state => ({
  user: getCurrentUser(state),
});

const mapDispatchToProps = {
  logOut,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderUserbox);
