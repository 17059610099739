import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  Typography,
  Grid,
  CardActions,
  Card,
  CardContent,
  Box,
  InputAdornment,
} from '@material-ui/core';
import cloneDeep from 'lodash/cloneDeep';
import setValue from 'lodash/set';
import { objectToFormData } from 'object-to-formdata';
import {
  EditorState, ContentState, convertFromRaw, convertToRaw,
} from 'draft-js';
import TagsEditor from '../TagsEditor';
import FileNameInfoBox from './FileNameInfoBox';
import IndexFileContentsCheckBox from './IndexFileContentsCheckBox';
import { enqueueMessage } from '../../actions/Alerts';
import { createContent, updateContent, deleteContent } from '../../actions/Contents';
import { getGroups, getSelectedGroup } from '../../reducers/Groups';
import { getTags } from '../../reducers/Tags';
import { selectGroup } from '../../actions/Groups';
import { fetchTags } from '../../actions/Tags';
import {
  MICROSOFT_GRAPH_CLIENT_ID,
  MIRCOSOFT_ONE_DRIVE_FILE_PICKER_REDIRECT_URI_DEV,
  MIRCOSOFT_ONE_DRIVE_FILE_PICKER_REDIRECT_URI_PROD,
} from '../../Constants';

import DraftEditor from '../UI/DraftEditor';

const styles = theme => ({
  button: {
    marginRight: theme.spacing(1),
  },
  groupSelect: {
    padding: theme.spacing(2, 0),
  },
  card: {
    width: theme.spacing(80),
    margin: theme.spacing(1),
  },
  icon: {
    padding: theme.spacing(0, 1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  favicon: {
    maxWidth: theme.spacing(4),
    maxHeight: theme.spacing(4),
  },
  dropzone: {
    minHeight: theme.spacing(10),
    maxHeight: theme.spacing(30),
    fontFamily: theme.typography.fontFamily,
    fontWeight: 200,
    marginBottom: theme.spacing(1),
  },
  dropzoneParagraph: {
    fontSize: 14,
  },
});

const initialContentState = {
  url: null,
  title: null,
  description: null,
  group: null,
  tags: [],
  links: [],
  memos: [],
  tasks: [],
  files: [],
  index_files_contents: false,
  microsoft_graph_access_token: null,
  one_drive_files: [],
  favicon: null,
};

class OneDriveFileForm extends React.Component {
  state = {
    content: initialContentState,
  };

  constructor(props) {
    super(props);
    const { content, selectedGroup } = props;
    const descriptionEditorState = EditorState.createEmpty();
    content.group = selectedGroup;
    this.state = { ...this.state, content: cloneDeep(content), descriptionEditorState };
  }

  componentDidMount() {
    const { match, selectGroupAction, fetchTagsAction } = this.props;
    fetchTagsAction(); // For refereshing tags
    if (match.params.contentId) {
      axios.get(`/contents/${match.params.contentId}`).then((response) => {
        const content = response.data;
        let descriptionEditorState = EditorState.createEmpty();
        if (content.description) {
          try {
            descriptionEditorState = EditorState.createWithContent(
              convertFromRaw(JSON.parse(content.description)),
            );
          } catch (e) {
            descriptionEditorState = EditorState.createEmpty();
          }
        }
        this.setState({ content, descriptionEditorState });
        if (content.group) selectGroupAction(content.group.id);
      });
    }
  }

  updateDescriptionEditorState = (descriptionTxt) => {
    let descriptionEditorState = EditorState.createEmpty();
    if (descriptionTxt) {
      try {
        descriptionEditorState = EditorState.createWithContent(ContentState.createFromText(descriptionTxt));
      } catch (e) {
        descriptionEditorState = EditorState.createEmpty();
      }
    }
    this.setState({ descriptionEditorState });
  }

  handleDescriptionChange = (descriptionEditorState) => {
    this.setState({ descriptionEditorState });
  };

  handleChange = (e) => {
    const { target } = e;
    const { content } = this.state;
    setValue(content, target.name, target.value);
    this.setState({ content });
  };

  handleCheckedChange = (e) => {
    const { target } = e;
    const { content } = this.state;
    setValue(content, target.name, target.checked);
    this.setState({ content });
  };

  handleTagsChange = (value) => {
    const { content } = this.state;
    content.tags = value;
    this.setState({ content });
  };

  handleGroupChange = (e) => {
    const { groups, selectGroupAction } = this.props;
    const { content } = this.state;
    const group = groups.find(x => x.id === e.target.value);
    content.group = group || null;
    this.setState({ content });
    selectGroupAction(group ? group.id : null);
  };

  handleDelete = () => {
    const { deleteContentAction } = this.props;
    const { content } = this.state;
    deleteContentAction(content).then(() => this.redirectBack());
  };

  redirectBack = () => {
    const { selectedGroup, history } = this.props;

    const destination = selectedGroup ? `/dashboard/groups/${selectedGroup.id}` : '/dashboard/private';
    history.replace(destination);
  };

  handleSave = (e) => {
    e.stopPropagation();
    const { createContentAction, updateContentAction } = this.props;
    const { descriptionEditorState } = this.state;
    const descriptionJsonString = JSON.stringify(
      convertToRaw(descriptionEditorState.getCurrentContent()),
    );

    let { content } = this.state;

    content = {
      ...content,
      description: descriptionJsonString,
      group: undefined,
      group_id: content.group && content.group.id,
    };
    const formData = objectToFormData({ content });

    const action = content.id ? updateContentAction : createContentAction;
    action(formData).then(() => this.redirectBack());
  };

  onOneDriveFileSelection = async (res) => {
    const { content } = this.state;
    const file = res.value[0];
    this.setState({
      content: {
        ...content,
        title: file.name,
        microsoft_graph_access_token: res.accessToken,
        one_drive_files: [
          {
            microsoft_graph_file_id: file.id,
            name: file.name,
            web_url: file.webUrl,
            size: file.size,
          },
        ],
      },
    });
  }

  launchOneDrivePicker = () => {
    const { enqueueMessageAction } = this.props;
    const odOptions = {
      clientId: MICROSOFT_GRAPH_CLIENT_ID,
      action: 'query',
      multiSelect: false,
      advanced: {
        queryParameters: 'select=id,name,size,webUrl,photo',
        filter: 'folder,.png,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf', /* display folder and files with extension '.png' only */
        redirectUri: process.env.NODE_ENV === 'development' ? MIRCOSOFT_ONE_DRIVE_FILE_PICKER_REDIRECT_URI_DEV : MIRCOSOFT_ONE_DRIVE_FILE_PICKER_REDIRECT_URI_PROD,
        navigation: {
          sources: ['OneDrive', 'Recent', 'Sites'],
        },
      },
      success: this.onOneDriveFileSelection,
      cancel: () => { },
      error: (error) => { enqueueMessageAction(error); },
    };
    window.OneDrive.open(odOptions);
  }

  render() {
    const {
      groups, tagSuggestions, selectedGroup, classes,
    } = this.props;
    const {
      content,
      descriptionEditorState,
    } = this.state;

    return (
      <Grid container justify="center" direction="column" alignContent="center">
        <Typography variant="h6">New Content</Typography>
        <Card className={classes.card}>
          <CardContent>
            <Grid item container direction="column" spacing={0} justify="center">
              <FormControl variant="outlined" className={classes.groupSelect}>
                <InputLabel id="group-select-label">Private / Group</InputLabel>
                <Select
                  labelId="group-select-label"
                  id="group-select"
                  value={
                    (content.group && content.group.id) || (selectedGroup && selectedGroup.id) || '0'
                  }
                  name="group"
                  onChange={this.handleGroupChange}
                >
                  <MenuItem value="0" key="0">
                    <i className="fa fa-lock" color="error" />
                    &nbsp;
                    <Typography variant="inherit">PRIVATE</Typography>
                  </MenuItem>
                  {groups.map(group => (
                    <MenuItem value={group.id} key={group.id}>
                      <i className="fa fa-users" fontSize="small" />
                      &nbsp;
                      <Typography variant="inherit">{group.name}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              { (content.one_drive_files.length > 0)
                ? (
                  <FileNameInfoBox
                    filename={content.one_drive_files[0].name}
                    onChangeFileClick={this.launchOneDrivePicker}
                  />
                ) : (
                  <Button variant="contained" color="primary" onClick={this.launchOneDrivePicker}>
                    Select file from OneDrive
                  </Button>
                )
              }

              { content.one_drive_files.length > 0 && (
                <Grid spacing={8}>
                  <TextField
                    required
                    margin="dense"
                    fullWidth
                    variant="outlined"
                    label="Title"
                    name="title"
                    value={content.title || ''}
                    onChange={this.handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={`data:image/png;base64, ${content.favicon} `} alt="" className={classes.favicon} />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TagsEditor
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    label="Tags/Keywords (Press Enter to create tags)"
                    suggestions={tagSuggestions.map(x => ({ label: x, value: x }))}
                    name="tags"
                    value={content.tags}
                    onChange={this.handleTagsChange}
                  />

                  <Grid item>
                    <InputLabel shrink>Notes</InputLabel>
                    <DraftEditor
                      margin="dense"
                      editorState={descriptionEditorState}
                      onChange={this.handleDescriptionChange}
                      autoFocus={false}
                    />
                  </Grid>
                  <IndexFileContentsCheckBox checked={content.index_files_contents} onChange={this.handleCheckedChange} />
                </Grid>
              )}
            </Grid>
          </CardContent>
          <hr />
          <CardActions>
            <Grid container>
              {content.id && (
                <Button color="secondary" onClick={() => window.confirm('Are you sure you wish to delete the item?') && this.handleDelete()}>
                  <i className="fa fa-trash-alt" />
                  &nbsp; Delete
                </Button>
              )}

              <Box flexGrow={1} />

              <Button variant="contained" color="primary" onClick={this.handleSave} className={classes.button} disabled={!content.title}>
                <i className="fa fa-plus-circle" />
                &nbsp; Save
              </Button>

              <Button color="primary" onClick={this.redirectBack} className={classes.button}>Cancel</Button>
            </Grid>
          </CardActions>
          {/* <div><pre>{JSON.stringify(this.state.content, null, 2) }</pre></div>) */}
        </Card>
      </Grid>
    );
  }
}

OneDriveFileForm.defaultProps = {
  content: initialContentState,
  selectedGroup: null,
};

OneDriveFileForm.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tagSuggestions: PropTypes.arrayOf(PropTypes.string).isRequired,
  content: PropTypes.shape({}),
  selectGroupAction: PropTypes.func.isRequired,
  createContentAction: PropTypes.func.isRequired,
  updateContentAction: PropTypes.func.isRequired,
  selectedGroup: PropTypes.shape({}),
  deleteContentAction: PropTypes.func.isRequired,
};

const matchStateToProps = state => ({
  selectedGroup: getSelectedGroup(state),
  groups: getGroups(state),
  tagSuggestions: getTags(state),
});

const mapDispatchToProps = dispatch => ({
  selectGroupAction: groupId => dispatch(selectGroup(groupId)),
  fetchTagsAction: () => dispatch(fetchTags()),
  createContentAction: content => dispatch(createContent(content)),
  updateContentAction: content => dispatch(updateContent(content)),
  deleteContentAction: content => dispatch(deleteContent(content)),
  enqueueMessageAction: message => dispatch(enqueueMessage(message)),
});

export default connect(
  matchStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(OneDriveFileForm)));
