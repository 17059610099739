import axios from 'axios';
import { enqueueMessage } from './Alerts';
import {
  FETCH_COMMENTS_REQUEST,
  FETCH_COMMENTS_SUCCESS,
  FETCH_COMMENTS_FAILURE,
  CREATE_COMMENT_REQUEST,
  CREATE_COMMENT_SUCCESS,
  CREATE_COMMENT_FAILURE,
  UPDATE_COMMENT_REQUEST,
  UPDATE_COMMENT_SUCCESS,
  UPDATE_COMMENT_FAILURE,
  DELETE_COMMENT_REQUEST,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_FAILURE,
} from './types';

import { trackAddedComment, trackUpdatedComment, trackRemovedComment } from '../utils/mixpanel/comments';
import parseErrorMessage from '../utils/errorMessage';

export const fetchCommentRequest = () => ({
  type: FETCH_COMMENTS_REQUEST,
});

export const fetchCommentsSuccess = comments => ({
  type: FETCH_COMMENTS_SUCCESS,
  payload: comments,
});

export const fetchCommentsFailure = data => ({
  type: FETCH_COMMENTS_FAILURE,
  payload: {
    error: data.message,
  },
});

export const fetchComments = contentId => (dispatch) => {
  dispatch(fetchCommentRequest());

  return axios.get(`contents/${contentId}/comments`)
    .then((response) => {
      dispatch(fetchCommentsSuccess(response.data));
      return response.data;
    })
    .catch((error) => {
      dispatch(fetchCommentsFailure(error));
      try {
        dispatch(enqueueMessage(error.response.data.message, 'error'));
      } catch (err) {
        dispatch(enqueueMessage(error.message, 'error'));
      }
    });
};

export const createCommentRequest = () => ({
  type: CREATE_COMMENT_REQUEST,
});

export const createCommentSuccess = comment => ({
  type: CREATE_COMMENT_SUCCESS,
  payload: comment,
});

export const createCommentFailure = data => ({
  type: CREATE_COMMENT_FAILURE,
  payload: {
    error: data.message,
  },
});

export const createComment = (contentId, body) => (dispatch) => {
  dispatch(createCommentRequest());

  return axios.post(`contents/${contentId}/comments`, { body })
    .then((response) => {
      trackAddedComment({ contentId });
      dispatch(createCommentSuccess(response.data));
      dispatch(enqueueMessage('Comment Added Successfully', 'success'));
      return response.data;
    })
    .catch((error) => {
      trackAddedComment({ contentId }, 'failure', parseErrorMessage(error));
      dispatch(createCommentFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
    });
};

export const updateCommentRequest = () => ({
  type: UPDATE_COMMENT_REQUEST,
});

export const updateCommentSuccess = comment => ({
  type: UPDATE_COMMENT_SUCCESS,
  payload: comment,
});

export const updateCommentFailure = data => ({
  type: UPDATE_COMMENT_FAILURE,
  payload: {
    error: data.message,
  },
});

export const updateComment = comment => (dispatch) => {
  dispatch(updateCommentRequest());

  return axios.put(`/comments/${comment.id}`, comment)
    .then((response) => {
      trackUpdatedComment();
      dispatch(updateCommentSuccess(response.data));
      dispatch(enqueueMessage('Comment Updated Successfully', 'success'));
      return response.data;
    })
    .catch((error) => {
      trackUpdatedComment(null, 'failure', parseErrorMessage(error));
      dispatch(updateCommentFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
    });
};

export const deleteCommentRequest = () => ({
  type: DELETE_COMMENT_REQUEST,
});

export const deleteCommentSuccess = comment => ({
  type: DELETE_COMMENT_SUCCESS,
  payload: comment,
});

export const deleteCommentFailure = data => ({
  type: DELETE_COMMENT_FAILURE,
  payload: {
    error: data.message,
  },
});

export const deleteComment = comment => (dispatch) => {
  dispatch(deleteCommentRequest());

  return axios.delete(`/comments/${comment.id}`)
    .then(() => {
      trackRemovedComment();
      dispatch(deleteCommentSuccess(comment.id));
      dispatch(enqueueMessage('Comment Deleted Successfully', 'success'));
      return comment.id;
    })
    .catch((error) => {
      trackRemovedComment(null, 'failure', parseErrorMessage(error));
      dispatch(deleteCommentFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
    });
};
