import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CreatableSelect from 'react-select/creatable';
import { withStyles } from '@material-ui/core/styles';
import { TextField, Typography, Paper, Chip, Avatar, MenuItem } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1400,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
});

function NoOptionsMessage(props) {
  const { innerProps, selectProps, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.noOptionsMessage}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  const {
    innerProps, selectProps, children, innerRef,
  } = props;
  return (
    <TextField
      fullWidth
      margin="dense"
      InputProps={{
        inputComponent,
        inputProps: {
          className: selectProps.classes.input,
          inputRef: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  const {
    innerProps, children, innerRef, isFocused, isSelected,
  } = props;

  return (
    <MenuItem
      buttonRef={innerRef}
      selected={isFocused}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
      {...innerProps}
    >
      {children}
    </MenuItem>
  );
}

function Placeholder(props) {
  const { innerProps, selectProps, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

function SingleValue(props) {
  const { innerProps, selectProps, children } = props;
  return (
    <Typography className={selectProps.classes.singleValue} {...innerProps}>
      {children}
    </Typography>
  );
}

function ValueContainer(props) {
  /* eslint-disable */
  const {
    selectProps, children,
  } = props;
  /* eslint-enable */
  return <div className={selectProps.classes.valueContainer}>{children}</div>;
}

function MultiValue(props) {
  const {
    selectProps, children, isFocused, removeProps,
  } = props;

  return (
    <Chip
      tabIndex={-1}
      avatar={(
        <Avatar>
          <i className="fa fa-hashtag" />
        </Avatar>
      )}
      label={children}
      className={classNames(selectProps.classes.chip, {
        [selectProps.classes.chipFocused]: isFocused,
      })}
      onDelete={removeProps.onClick}
      deleteIcon={<CancelIcon {...removeProps} />}
      color="primary"
    />
  );
}

function Menu(props) {
  const { innerProps, selectProps, children } = props;
  return (
    <Paper square className={selectProps.classes.paper} {...innerProps}>
      {children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class TagsEditor extends React.Component {
  handleChange = (value) => {
    const { onChange, name } = this.props;

    onChange(value.map(x => x.value), name);
  };

  render() {
    const {
      classes, theme, suggestions, label, value,
    } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          variant: 'outlined',
          font: 'inherit',
        },
      }),
    };

    return (
      <CreatableSelect
        classes={classes}
        styles={selectStyles}
        textFieldProps={{
          label,
          InputLabelProps: {
            shrink: true,
          },
        }}
        options={suggestions}
        components={components}
        value={value.map(x => ({ label: x, value: x }))}
        onChange={this.handleChange}
        placeholder="Select Tags..."
        isMulti
      />
    );
  }
}

TagsEditor.defaultProps = {
  onChange: () => {},
  value: [],
  name: '',
};

TagsEditor.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(TagsEditor);
