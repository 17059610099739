import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  background: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    zIndex: 999999999999999,
    top: 0,
    background: 'rgba(0, 0, 0, 0.2)',
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

const LoadingSpinner = (props) => {
  const { classes } = props;
  return (
    <div className={classes.background}>
      <div className={classes.loading}>
        <CircularProgress size={50} />
      </div>
    </div>
  );
};

LoadingSpinner.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(LoadingSpinner);
