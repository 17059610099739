import React from 'react';
import { List, Link } from '@material-ui/core';
import StarListItem from './StarListItem';
import Tour from './Tour';

const Tour7 = () => (
  <Tour
    title="Congratulations for completing the Product Tour!"
    image="/assets/images/tour/product-tour7.jpeg"
    previousUrl="/tour/6"
  >
    <List dense>
      <StarListItem>
        If you have any questions, feedback, feature requests,
        feel free to give us feedback anytime.
      </StarListItem>
      <StarListItem>
        You can also write to us at
        {' '}
        <Link href="mailto:support@iseloapp.com">support@iseloapp.com</Link>
      </StarListItem>
    </List>
  </Tour>
);

export default Tour7;
