import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Typography, Box, Button,
} from '@material-ui/core';
import UILink from '@material-ui/core/Link';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';


const styles = () => ({
  root: {
    minHeight: '60vh',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '600px',
  },
});

const Tour = (props) => {
  const {
    classes, children, image, title, nextUrl, previousUrl,
  } = props;
  return (
    <Grid container direction="column" className={classes.root} alignItems="center">
      <Typography variant="h4">{title}</Typography>
      <img
        alt="tour 1"
        src={image}
        className={classes.image}
      />
      <Grid item container alignItem="center" justify="space-between" alignContent="center" >
        {previousUrl && (
          <Box textAlign="left">
            <Button size="large" color="primary" component={Link} to={previousUrl}>
              <i className="fas fa-chevron-left" />
              &nbsp;Previous
            </Button>
          </Box>
        )}
        <Grid item xs={6}>
          <Box textAlign="center" fontSize="h6.fontSize">
            {children}
          </Box>
        </Grid>
        {nextUrl
          ? (
            <Box textAlign="right">
              <Button variant="contained" size="large" color="primary" component={Link} to={nextUrl}>
                Next&nbsp;
                <i className="fas fa-chevron-right" />
              </Button>
            </Box>
          )
          : (
            <Box textAlign="right">
              <Button variant="contained" size="large" color="primary" component={UILink} href="/dashboard/private">
                Get Started!
              </Button>
            </Box>
          )
        }
      </Grid>
    </Grid>
  );
};

Tour.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.node.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  nextUrl: PropTypes.string.isRequired,
  previousUrl: PropTypes.string.isRequired,
};

export default withStyles(styles)(Tour);
