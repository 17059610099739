import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  List, ListItem, ListSubheader, ListItemIcon, ListItemText, Typography, Grid,
} from '@material-ui/core';
import CardIcon from './CardIcon';
import { fetchPaymentMethods } from '../../actions/PaymentMethods';
import { getPaymentMethods } from '../../reducers/PaymentMethods';

class PaymentMethods extends Component {
  componentDidMount() {
    const { fetchPaymentMethodsAction } = this.props;
    fetchPaymentMethodsAction();
  }

  render() {
    const { paymentMethods } = this.props;
    return (paymentMethods.length > 0
      ? (
        <Grid container>
          <List subheader={(
            <ListSubheader component="div" id="nested-list-subheader">
              Cards
            </ListSubheader>
          )}
          >
            {paymentMethods.map(paymentMethod => (
              <ListItem>
                <ListItemIcon>
                  <CardIcon brand={paymentMethod.brand} />
                </ListItemIcon>
                <ListItemText
                  primary={`••••••••••••${paymentMethod.last4} (${paymentMethod.country})`}
                  secondary={`Card Expiring on ${paymentMethod.exp_month}/${paymentMethod.exp_year}`}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      )
      : (
        <Grid container spacing={0} direction="column" alignItems="center" justify="center">
          <Grid item>
            <Typography variant="h6">No Payment Methods!</Typography>
          </Grid>
        </Grid>
      )
    );
  }
}

PaymentMethods.propTypes = {
  paymentMethods: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchPaymentMethodsAction: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  paymentMethods: getPaymentMethods(state),
});

const mapDispatchToProps = dispatch => ({
  fetchPaymentMethodsAction: () => dispatch(fetchPaymentMethods()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentMethods);
