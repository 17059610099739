import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { setSidebarToggleMobile } from '../../../../reducers/ThemeOptions';

import HeaderUserbox from '../HeaderUserbox';
import HeaderDots from '../HeaderDots';
// import HeaderSearch from '../HeaderSearch';
import HeaderMenu from '../HeaderMenu';
import { Button } from "@material-ui/core";
// import HeaderDrawer from '../HeaderDrawer';

const Header = (props) => {
  const {
    headerShadow,
    headerBgTransparent,
    sidebarToggleMobile,
    setSidebarToggleMobile,
  } = props;

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  return (
    <>
      <div
        className={classNames('app-header', {
          'app-header--shadow': headerShadow,
          'app-header--opacity-bg': headerBgTransparent,
        })}
      >
        <div className="app-header--pane">
          <Button
            className={classNames(
              'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
              { 'is-active': sidebarToggleMobile },
            )}
            onClick={toggleSidebarMobile}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </Button>
          {/* <HeaderSearch /> */}
          <HeaderMenu />
        </div>
        <div className="app-header--pane">
          <HeaderDots />
          <HeaderUserbox />
          {/* <HeaderDrawer /> */}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerBgTransparent: state.ThemeOptions.headerBgTransparent,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
