import { Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { connect } from "react-redux";
import { getOrganization } from '../../reducers/Organization';
import AddOrganizationUserButton from './AddOrganizationUserButton';
import CustomerPortalButton from './CustomerPortalButton';

const OrganizationActions = ({ organization }) => (
  <>
    {((organization
      && organization.stripe_subscription_status === 'trialing')
      || (organization
        && organization.valid_subscription
        && organization.user_count < organization.max_users)) && (
        <Grid>
          <AddOrganizationUserButton>
            Add a new user to the Organization
          </AddOrganizationUserButton>
        </Grid>
    )}
    {organization
      && organization.stripe_subscription_status !== 'trialing'
      && organization.valid_subscription
      && organization.user_count === organization.max_users
      && (
        <Grid>
          <CustomerPortalButton caption="Upgrade Subscription to add more users" />
          <Alert severity="warning">
            Users
            {organization.user_count}
            /
            {organization.max_users}
          </Alert>
        </Grid>
      )
    }
  </>
);

const mapStateToProps = state => ({
  organization: getOrganization(state),
});

export default connect(mapStateToProps)(OrganizationActions);
