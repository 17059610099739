import axios from 'axios';
import { enqueueMessage } from './Alerts';
import {
  FETCH_CONTENTS_REQUEST,
  FETCH_CONTENTS_SUCCESS,
  FETCH_CONTENTS_FAILURE,
  CREATE_CONTENT_REQUEST,
  CREATE_CONTENT_SUCCESS,
  CREATE_CONTENT_FAILURE,
  UPDATE_CONTENT_REQUEST,
  UPDATE_CONTENT_SUCCESS,
  UPDATE_CONTENT_FAILURE,
  UPDATE_CONTENT_USER_PROPERTIES_REQUEST,
  UPDATE_CONTENT_USER_PROPERTIES_SUCCESS,
  UPDATE_CONTENT_USER_PROPERTIES_FAILURE,
  DELETE_CONTENT_REQUEST,
  DELETE_CONTENT_SUCCESS,
  DELETE_CONTENT_FAILURE,
  TOGGLE_STARRED_REQUEST,
  TOGGLE_STARRED_SUCCESS,
  TOGGLE_STARRED_FAILURE,
} from './types';

import { trackAddedContent, trackUpdatedContent, trackRemovedContent } from '../utils/mixpanel/contents';
import parseErrorMessage from '../utils/errorMessage';
import { isGroup } from '../utils/listOwnerTypes';

export const fetchContentsRequest = () => ({
  type: FETCH_CONTENTS_REQUEST,
});

export const fetchContentsSuccess = contents => ({
  type: FETCH_CONTENTS_SUCCESS,
  payload: contents,
});

export const fetchContentsFailure = data => ({
  type: FETCH_CONTENTS_FAILURE,
  payload: {
    error: data.message,
  },
});

export const fetchContents = () => (dispatch, getState) => {
  dispatch(fetchContentsRequest());

  const { CurrentOwner } = getState();
  const params = {};

  if (isGroup(CurrentOwner.currentOwnerType)) {
    params.group_id = CurrentOwner.currentOwnerId;
  }

  return axios.get('/contents', { params })
    .then((response) => {
      dispatch(fetchContentsSuccess(response.data));
      return response.data;
    })
    .catch((error) => {
      dispatch(fetchContentsFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
      throw error;
    });
};

export const createContentRequest = () => ({
  type: CREATE_CONTENT_REQUEST,
});

export const createContentSuccess = content => ({
  type: CREATE_CONTENT_SUCCESS,
  payload: content,
});

export const createContentFailure = data => ({
  type: CREATE_CONTENT_FAILURE,
  payload: {
    error: data.message,
  },
});

export const createContent = payload => (dispatch) => {
  dispatch(createContentRequest());

  return axios.post('/contents', payload)
    .then((response) => {
      trackAddedContent(payload);
      dispatch(createContentSuccess(response.data));
      dispatch(enqueueMessage('Content Created!', 'success'));
      return response.data;
    })
    .catch((error) => {
      trackAddedContent(payload, 'failure', parseErrorMessage(error));
      dispatch(createContentFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
      throw error;
    });
};

export const updateContentUserPropertiesRequest = () => ({
  type: UPDATE_CONTENT_USER_PROPERTIES_REQUEST,
});

export const updateContentUserPropertiesSuccess = content => ({
  type: UPDATE_CONTENT_USER_PROPERTIES_SUCCESS,
  payload: content,
});

export const updateContentUserPropertiesFailure = data => ({
  type: UPDATE_CONTENT_USER_PROPERTIES_FAILURE,
  payload: {
    error: data.message,
  },
});

export const updateContentUserProperties = content => (dispatch) => {
  dispatch(updateContentUserPropertiesRequest());

  return axios.put(`/contents/${content.id}/content_user_properties`, { content })
    .then((response) => {
      trackUpdatedContent(response.data);
      dispatch(updateContentUserPropertiesSuccess(response.data));
      dispatch(enqueueMessage('Content Updated!', 'success'));
      return response.data;
    })
    .catch((error) => {
      trackUpdatedContent(content, 'failure', parseErrorMessage(error));
      dispatch(updateContentUserPropertiesFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
      throw error;
    });
};

export const updateContentRequest = () => ({
  type: UPDATE_CONTENT_REQUEST,
});

export const updateContentSuccess = content => ({
  type: UPDATE_CONTENT_SUCCESS,
  payload: content,
});

export const updateContentFailure = data => ({
  type: UPDATE_CONTENT_FAILURE,
  payload: {
    error: data.message,
  },
});

export const updateContent = payload => (dispatch) => {
  dispatch(updateContentRequest());
  const contentId = payload.get('content[id]'); // FormData Payload (for sending files)
  return axios.put(`/contents/${contentId}`, payload)
    .then((response) => {
      trackUpdatedContent(response.data);
      dispatch(updateContentSuccess(response.data));
      dispatch(enqueueMessage('Content Updated!', 'success'));
      return response.data;
    })
    .catch((error) => {
      trackUpdatedContent(payload, 'failure', parseErrorMessage(error));
      dispatch(updateContentFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
      throw error;
    });
};

export const deleteContentRequest = content => ({
  type: DELETE_CONTENT_REQUEST,
  payload: content,
});

export const deleteContentSuccess = content => ({
  type: DELETE_CONTENT_SUCCESS,
  payload: content,
});

export const deleteContentFailure = data => ({
  type: DELETE_CONTENT_FAILURE,
  payload: {
    error: data.message,
  },
});

export const deleteContent = content => (dispatch) => {
  dispatch(deleteContentRequest(content));

  return axios.delete(`/contents/${content.id}`)
    .then(() => {
      trackRemovedContent(content);
      dispatch(deleteContentSuccess(content.id));
      dispatch(enqueueMessage('Content Deleted!', 'success'));
      return content.id;
    })
    .catch((error) => {
      trackRemovedContent(content, 'failure', parseErrorMessage(error));
      dispatch(deleteContentFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
      throw error;
    });
};

export const toggleStarredRequest = () => ({
  type: TOGGLE_STARRED_REQUEST,
});

export const toggleStarredSuccess = content => ({
  type: TOGGLE_STARRED_SUCCESS,
  payload: content,
});

export const toggleStarredFailure = data => ({
  type: TOGGLE_STARRED_FAILURE,
  payload: {
    error: data.message,
  },
});

export const toggleStarred = content => (dispatch) => {
  dispatch(toggleStarredRequest());

  return axios.post(`/contents/${content.id}/toggle_starred`)
    .then((response) => {
      trackUpdatedContent(response.data);
      dispatch(toggleStarredSuccess(response.data));
      dispatch(enqueueMessage('Content Updated!', 'success'));
      return response.data;
    })
    .catch((error) => {
      trackUpdatedContent(content, 'failure', parseErrorMessage(error));
      dispatch(toggleStarredFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
      throw error;
    });
};
