import React from 'react';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

const styles = theme => ({
  content: {
    minWidth: 450,
  },
  delete: {
    marginRight: 'auto',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  dialog: {
    minHeight: '600px',
  },
});

const AddModal = (props) => {
  const {
    classes,
    children,
    open,
    onClose,
    heading,
    onSave,
    onDelete,
    showDelete,
    fullScreen,
    fullWidth,
  } = props;

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      className={classes.dialog}
      maxWidth="md"
      fullWidth={fullWidth}
    >
      <DialogTitle>
        {heading}
      </DialogTitle>
      <Divider />
      <DialogContent style={!fullScreen ? { minWidth: 450 } : {}}>
        {children}
      </DialogContent>
      <Divider />
      <DialogActions>
        {showDelete && (
          <Button variant="outlined" color="secondary" className={classes.delete} onClick={onDelete}>
            <DeleteIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
            Delete
          </Button>
        )}
        <Button variant="contained" color="primary" onClick={onSave}>
          <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
          Save
        </Button>
        <Button variant="outlined" onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

AddModal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  fullScreen: PropTypes.bool.isRequired,
  children: PropTypes.shape({}).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  showDelete: PropTypes.bool.isRequired,
};

export default withMobileDialog()(withStyles(styles)(AddModal));
