import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Route, Switch, Redirect, withRouter,
} from 'react-router-dom';
import blue from '@material-ui/core/colors/blue';
import axios from 'axios';
import NewDashboard from './NewDashboard';
import Tour1 from './Tour/Tour1';
import Tour2 from './Tour/Tour2';
import Tour3 from './Tour/Tour3';
import Tour4 from './Tour/Tour4';
import Tour5 from './Tour/Tour5';
import Tour6 from './Tour/Tour6';
import Tour7 from './Tour/Tour7';
import LoadingSpinner from './UI/LoadingSpinner';
import GroupList from './GroupList/GroupList';
import Memberships from './Memberships/Memberships';
import ContentForm from './ContentForm/ContentForm';
import OneDriveFileForm from './ContentForm/OneDriveFileForm';
import ContentDetails from './ContentDetails/ContentDetails';
import AlertNotifier from './UI/AlertNotifier';
import { enqueueSnackbar } from '../actions/Alerts';
import OrganizationSubscription from './Organization/OrganizationSubscription';
import Organization from './Organization/Organization';
import { fetchGroups } from '../actions/Groups';
import { fetchCurrentUser } from '../actions/Users';
import { fetchOrganization } from '../actions/Organization';
import { getOrganization } from '../reducers/Organization';
import { getGroups } from '../reducers/Groups';
import GroupForm from './Groups/GroupForm';
import NewGroupForm from './Groups/NewGroupForm';
import ListForm from './Lists/ListForm';
import '../assets/base.scss';
// import { messaging } from '../utils/firebase/firebase';

const theme = createMuiTheme({
  palette: {
    primary: blue,
  },
  typography: {
    useNextVariants: true,
  },
});

class App extends Component {
  componentDidMount() {
    const {
      fetchCurrentUserAction, fetchOrganizationAction, fetchGroupsAction, location,
    } = this.props;
    fetchCurrentUserAction();
    fetchOrganizationAction();
    fetchGroupsAction();
    // messaging.requestPermission().then(async () => {
    //   const token = await messaging.getToken();
    //   this.sendFcmRegistrationToken(token);
    // });

    // on message callback... when the app is in foreground (in focus)
    // navigator.serviceWorker.addEventListener("message", (message) => console.log(message));
    this.showHeader = location.pathname.match(/tour\/\d/) === null;
  }

  componentDidUpdate() {
    const { organization, history } = this.props;
    if (organization && !organization.valid_subscription && window.location.pathname !== '/organization/users') {
      history.push('/organization/users');
    }
  }

  sendFcmRegistrationToken = (token) => {
    axios.post('/fcm_registration_tokens', { token })
      .then((response) => {
        console.log('Registration Token Sent!', response.data);
      })
      .catch((error) => {
        console.log('Error Fetching metadata', error);
      });
  }

  render() {
    const {
      loading, groups,
    } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        {
          // this.showHeader ? <Header /> : ''
        }
        <AlertNotifier />
        <Switch>
          <Route exact path="/tour/1" component={Tour1} />
          <Route exact path="/tour/2" component={Tour2} />
          <Route exact path="/tour/3" component={Tour3} />
          <Route exact path="/tour/4" component={Tour4} />
          <Route exact path="/tour/5" component={Tour5} />
          <Route exact path="/tour/6" component={Tour6} />
          <Route exact path="/tour/7" component={Tour7} />
          <Route
            path="/dashboard"
            render={props => <NewDashboard {...props} />}
          />
          <Route exact path="/contents/new" component={ContentForm} />
          <Route exact path="/contents/new/one-drive-file" component={OneDriveFileForm} />
          <Route exact path="/contents/:contentId/edit" component={ContentForm} />
          <Route exact path="/contents/:contentId" component={ContentDetails} />
          <Route exact path="/groups/:groupId/memberships" component={Memberships} />

          <Route
            path="/lists/new"
            render={props => <ListForm {...props} />}
          />
          <Route
            path="/lists/:listId/edit"
            render={props => <ListForm {...props} />}
          />

          <Route
            path="/groups/new"
            render={props => <NewGroupForm {...props} />}
          />

          <Route
            path="/groups/:groupId/edit"
            render={props => <NewGroupForm {...props} />}
          />
          <Route
            path="/groups"
            render={props => <GroupList {...props} groups={groups} />}
          />

          <Route
            path="/organization/subscription"
            render={props => <OrganizationSubscription {...props} />}
          />
          <Route
            path="/organization/users"
            render={props => <Organization {...props} />}
          />
          <Route exact path="/">
            <Redirect to="/dashboard/private" />
          </Route>
        </Switch>
        {loading && <LoadingSpinner />}
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  const keys = Object.keys(state);
  const loading = keys.find(x => state[x].loading);
  return {
    loading: !!loading,
    organization: getOrganization(state),
    groups: getGroups(state),
  };
};

const mapDispatchToProps = dispatch => ({
  fetchCurrentUserAction: () => dispatch(fetchCurrentUser()),
  fetchOrganizationAction: () => dispatch(fetchOrganization()),
  fetchGroupsAction: () => dispatch(fetchGroups()),
  enqueueSnackbar: () => dispatch(enqueueSnackbar()),
});

App.propTypes = {
  loading: PropTypes.bool.isRequired,
  location: PropTypes.shape({}),
  organization: PropTypes.shape({}),
  groups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchOrganizationAction: PropTypes.func.isRequired,
  fetchCurrentUserAction: PropTypes.func.isRequired,
  fetchGroupsAction: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(App));
