import MixpanelClient from './client';
import { MIXPANEL_ADDED_LIST, MIXPANEL_UPDATED_LIST, MIXPANEL_REMOVED_LIST } from './events';

// responseStatus can be 'success' OR 'failure'
const trackListEvent = (eventNameConstant, list, responseStatus, errorMessage) => {
  MixpanelClient.track(eventNameConstant, {
    'List Id': list?.id,
    'Owner Id': list?.owner_id,
    'Owner Type': list?.owner_type,
    'Response Status': responseStatus,
    'Response Error Message': errorMessage,
  });
};

export const trackAddedList = (list, responseStatus = 'success', errorMessage = null) => {
  trackListEvent(MIXPANEL_ADDED_LIST, list, responseStatus, errorMessage);
};

export const trackUpdatedList = (list = null, responseStatus = 'success', errorMessage = null) => {
  trackListEvent(MIXPANEL_UPDATED_LIST, list, responseStatus, errorMessage);
};

export const trackRemovedList = (list = null, responseStatus = 'success', errorMessage = null) => {
  trackListEvent(MIXPANEL_REMOVED_LIST, list, responseStatus, errorMessage);
};
