import {
  FETCH_ORGANIZATION_FAILURE,
  FETCH_ORGANIZATION_SUCCESS,
  FETCH_ORGANIZATION,
  CREATE_LIST_REQUEST,
  CREATE_LIST_SUCCESS,
  CREATE_LIST_FAILURE,
  UPDATE_LIST_REQUEST,
  UPDATE_LIST_SUCCESS,
  UPDATE_LIST_FAILURE,
  DELETE_LIST_REQUEST,
  DELETE_LIST_SUCCESS,
  DELETE_LIST_FAILURE,
} from '../actions/types';

import ListReducer from './Lists';
import { isOrganization } from '../utils/listOwnerTypes';

const initialState = { data: null };
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORGANIZATION:
      return {
        ...state,
        data: null,
        error: undefined,
        loading: true,
      };
    case FETCH_ORGANIZATION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_ORGANIZATION_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        data: null,
        error,
        loading: false,
      };
    }
    case CREATE_LIST_REQUEST:
    case CREATE_LIST_SUCCESS:
    case CREATE_LIST_FAILURE:
    case UPDATE_LIST_REQUEST:
    case UPDATE_LIST_SUCCESS:
    case UPDATE_LIST_FAILURE:
    case DELETE_LIST_REQUEST:
    case DELETE_LIST_SUCCESS:
    case DELETE_LIST_FAILURE:
      if (isOrganization(action.listOwnerType)) return ListReducer(state, action);
      return state;
    default:
      return state;
  }
};

export const getOrganization = state => state.Organization.data;
export const getOrganizationUsers = state => state.Organization.data.users;
