import {
  CREATE_INVITATION_REQUEST,
  CREATE_INVITATION_SUCCESS,
  CREATE_INVITATION_FAILURE,
  RESEND_INVITATION_REQUEST,
  RESEND_INVITATION_SUCCESS,
  RESEND_INVITATION_FAILURE,
} from '../actions/types';

const initialState = { data: [] };
export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_INVITATION_REQUEST:
      return { ...state, error: undefined, loading: true };
    case CREATE_INVITATION_SUCCESS:
      return { ...state, data: [...state.data, action.payload], loading: false };
    case CREATE_INVITATION_FAILURE: {
      const { error } = action.payload;
      return { ...state, error, loading: false };
    }
    case RESEND_INVITATION_REQUEST:
      return { ...state, error: undefined, loading: true };
    case RESEND_INVITATION_SUCCESS: {
      return { ...state, error: undefined, loading: false };
    }
    case RESEND_INVITATION_FAILURE: {
      const { error } = action.payload;
      return { ...state, error, loading: false };
    }
    default:
      return state;
  }
};
