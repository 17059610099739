import {
  SET_CURRENT_OWNER_TYPE,
  SET_CURRENT_OWNER_ID,
} from './types';


import { isGroup, isOrganization, isUser } from '../utils/listOwnerTypes';
import { setAlgoliaSearchKey } from './Alogolia';

export const setCurrentOwnerType = ownerType => ({
  type: SET_CURRENT_OWNER_TYPE,
  payload: ownerType,
});

export const setCurrentOwnerId = ownerId => ({
  type: SET_CURRENT_OWNER_ID,
  payload: ownerId,
});

export const setCurrentOwner = ({ ownerType, ownerId }) => (dispatch, getState) => {
  dispatch(setCurrentOwnerType(ownerType));
  dispatch(setCurrentOwnerId(ownerId));

  // If selectedGroup is fetched set Algolia search key
  const { CurrentUser, Groups, Organization } = getState();
  let searchKey;

  if (isUser(ownerType) && CurrentUser.data) {
    searchKey = CurrentUser.data.algolia_secured_api_key_private_contents;
  } else if (isGroup(ownerType) && Groups.data.length > 0) {
    searchKey = Groups.data.find(t => Number(t.id) === Number(ownerId)).algolia_secured_api_key_group_contents;
  } else if (isOrganization(ownerType) && Organization.data) {
    searchKey = Organization.data.algolia_secured_api_key_organization_contents;
  } else if (CurrentUser.data) {
    searchKey = CurrentUser.data.algolia_secured_api_key_all_accessible_contents;
  }

  dispatch(setAlgoliaSearchKey(searchKey));
};
