import React from 'react';
import axios from 'axios';
import includes from 'lodash/includes';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import CreatableSelect from 'react-select/creatable';

import {
  TextField,
  FormHelperText,
  Button,
  Typography,
  Grid,
  CardActions,
  Card,
  CardContent,
  Box,
  InputAdornment,
} from '@material-ui/core';

import setValue from 'lodash/set';
import { createGroup, updateGroup, deleteGroup } from '../../actions/Groups';
import UsersList from './UsersList';
import { getOrganization } from '../../reducers/Organization';
import MiniHeader from "../NewDashboard/dashboard-components/MiniHeader";

const styles = theme => ({
  root: {
    minHeight: theme.spacing(20),
  },
  card: {
    minWidth: theme.spacing(60),
    margin: theme.spacing(1),
  },
  icon: {
    padding: theme.spacing(0, 1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  favicon: {
    maxWidth: theme.spacing(4),
    maxHeight: theme.spacing(4),
  },
});

const initialGroupState = {
  name: null,
  users: [],
};


// https://github.com/JedWatson/react-select/issues/2910
// ------- Patch to fix an issue-----
const compareOption = (inputValue, option) => {
  const candidate = typeof inputValue === 'string' ? inputValue.toLowerCase() : inputValue;
  if (typeof option.value === 'string') {
    if (option.value.toLowerCase() === candidate) {
      return true;
    }
  }
  if (typeof option.label === 'string') {
    if (option.label.toLowerCase() === candidate) {
      return true;
    }
  }
  return option.value === candidate || option.label === candidate;
};

function isValidNewOption(
  inputValue,
  selectValue,
  selectOptions,
) {
  return !(
    !inputValue
    || selectValue.some(option => compareOption(inputValue, option))
    || selectOptions.some(option => compareOption(inputValue, option))
  );
}
// ------- End Patch-----

class GroupForm extends React.Component {
  state = {
    group: {
      name: null,
      users: [],
    },
    selectedUserEmail: null,
  };

  componentDidMount() {
    const { match } = this.props;
    if (match.params.groupId) {
      axios.get(`/groups/${match.params.groupId}`).then((response) => {
        const group = response.data;
        this.setState({ group });
      });
    }
  }

  handleChange = (e) => {
    const { target } = e;
    const { group } = this.state;
    setValue(group, target.name, target.value);
    this.setState({ group });
  };

  getOptionDisabled = (option) => {
    const { group } = this.state;
    return includes(group.users.map(u => u.email), option.email);
  }

  handleUserSelectChange = (selectedUser) => {
    this.setState({ selectedUserEmail: selectedUser.value });
  }

  handleAddUser = () => {
    const { group, selectedUserEmail } = this.state;
    let userObj;
    if (selectedUserEmail) {
      userObj = { email: selectedUserEmail };
    } else {
      userObj = null;
    }

    if (userObj && !includes(group.users.map(u => u.email), userObj.email)) {
      this.setState({
        group: {
          ...group,
          users: [...group.users, userObj],
        },

        selectedUserEmail: null,
      });
    }
  }

  handleDeleteUser = (userToBeDeleted) => {
    const { group } = this.state;
    let newUsersArr = group.users.filter(user => userToBeDeleted.email !== user.email);
    if (userToBeDeleted.id) {
      newUsersArr = [...newUsersArr, { ...userToBeDeleted, delete: true }];
    }
    this.setState({
      group: {
        ...group,
        users: newUsersArr,
      },
    });
  }

  redirectBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  handleDelete = (group) => {
    const { deleteGroupAction, history } = this.props;
    deleteGroupAction(group).then(() => history.replace('/'));
  };

  redirectToGroupDashboard = (groupId) => {
    const { history } = this.props;
    history.replace(`/dashboard/groups/${groupId}`);
  };

  handleSave = (e) => {
    e.stopPropagation();
    const { createGroupAction, updateGroupAction } = this.props;

    let { group } = this.state;
    group = {
      ...group,
    };

    const action = group.id ? updateGroupAction : createGroupAction;
    action(group).then(response => response && response.id && this.redirectToGroupDashboard(response.id));
  };

  render() {
    const { organization, classes } = this.props;
    const { group, selectedUserEmail } = this.state;
    const userSelectSuggestions = organization?.users.filter(ou => !group.users.map(tu => tu.email).includes(ou.email)).map(u => ({ label: u.email, value: u.email }));
    return (
      <>
        <MiniHeader>
          <Typography variant="h6">{group.id ? 'Edit Group' : 'Create New Group'}</Typography>
        </MiniHeader>
        <Grid container justify="center" direction="column" alignContent="center" className="m-5">
          <Card className={classes.card}>
            <CardContent>
              <Grid item container direction="column" justify="center">
                <Grid item container>
                  <TextField
                    autoFocus
                    required
                    fullWidth
                    variant="outlined"
                    label="Group Name"
                    id="input-name"
                    name="name"
                    value={group.name || ''}
                    onChange={this.handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={`data:image/png;base64, ${group.favicon} `} alt="" className={classes.favicon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item container alignItems="flex-end" justify="space-between">
                  <Grid item xs={9}>

                    <FormHelperText id="my-helper-text">Enter Emails to add new members... </FormHelperText>
                    <CreatableSelect
                      fullWidth
                      id="email-input"
                      aria-describedby="input "
                      isValidNewOption={isValidNewOption}
                      classes={classes}
                      textFieldProps={{
                        InputLabelProps: {
                          shrink: true,
                        },
                      }}
                      options={userSelectSuggestions}
                      value={{ label: selectedUserEmail, value: selectedUserEmail }}
                      onChange={this.handleUserSelectChange}
                      placeholder="Enter Email..."
                    />
                  </Grid>
                  <Grid item xs={3} marginleft={1}>
                    <Button fullWidth color="primary" size="large" onClick={this.handleAddUser} disabled={!selectedUserEmail}>
                      <i className="fa fa-plus-circle" />
                    &nbsp; Add
                    </Button>
                  </Grid>
                </Grid>
                <Grid item>
                  <UsersList users={group.users.filter(u => u.delete !== true)} handleDeleteUser={this.handleDeleteUser} />
                </Grid>
              </Grid>
            </CardContent>

            <hr />
            <CardActions style={{ marginLeft: 'auto' }}>
              <Grid container>
                {group.id && (
                <Button color="secondary" onClick={() => window.confirm('Are you sure you wish to delete the item?') && this.handleDelete(group)}>
                  <i className="fa fa-trash-alt" />
                  &nbsp; Delete
                </Button>
                )}

                <Box flexGrow={1} />

                <Button variant="contained" color="primary" onClick={this.handleSave}>
                  <i className="fa fa-save" />
                &nbsp; Save
                </Button>
                <Button onClick={this.redirectBack}>Cancel</Button>
              </Grid>
            </CardActions>
            {/* <div><pre>{JSON.stringify(this.state, null, 2) }</pre></div> */}
          </Card>
        </Grid>
      </>
    );
  }
}

GroupForm.defaultProps = {
  group: initialGroupState,
  organization: null,
};

GroupForm.propTypes = {
  organization: PropTypes.shape({}),
  createGroupAction: PropTypes.func.isRequired,
  updateGroupAction: PropTypes.func.isRequired,
  deleteGroupAction: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  organization: getOrganization(state),
});

const mapDispatchToProps = dispatch => ({
  createGroupAction: group => dispatch(createGroup(group)),
  updateGroupAction: group => dispatch(updateGroup(group)),
  deleteGroupAction: group => dispatch(deleteGroup(group)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(GroupForm)));
