import { createSelector } from 'reselect';

import {
  FETCH_CURRENT_USER_REQUEST, FETCH_CURRENT_USER_SUCCESS, FETCH_CURRENT_USER_FAILURE,
  CREATE_LIST_REQUEST,
  CREATE_LIST_SUCCESS,
  CREATE_LIST_FAILURE,
  UPDATE_LIST_REQUEST,
  UPDATE_LIST_SUCCESS,
  UPDATE_LIST_FAILURE,
  DELETE_LIST_REQUEST,
  DELETE_LIST_SUCCESS,
  DELETE_LIST_FAILURE,
} from '../actions/types';
import ListReducer from './Lists';
import { isUser } from '../utils/listOwnerTypes';


const initialState = { data: null };
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CURRENT_USER_REQUEST:
      return {
        ...state, data: null, error: undefined, loading: true,
      };
    case FETCH_CURRENT_USER_SUCCESS:
      return {
        ...state, data: action.payload, loading: false,
      };
    case FETCH_CURRENT_USER_FAILURE: {
      const { error } = action.payload;
      return {
        ...state, data: null, error, loading: false,
      };
    }
    case CREATE_LIST_REQUEST:
    case CREATE_LIST_SUCCESS:
    case CREATE_LIST_FAILURE:
    case UPDATE_LIST_REQUEST:
    case UPDATE_LIST_SUCCESS:
    case UPDATE_LIST_FAILURE:
    case DELETE_LIST_REQUEST:
    case DELETE_LIST_SUCCESS:
    case DELETE_LIST_FAILURE:
      if (isUser(action.listOwnerType)) return ListReducer(state, action);
      return state;
    default:
      return state;
  }
};

export const getCurrentUser = state => state.CurrentUser.data;
export const isCurrentUserLoading = state => state.CurrentUser.loading === true;

export const getCurrentUserTags = createSelector(
  getCurrentUser,
  currentUser => (currentUser ? currentUser.tags : []),
);
