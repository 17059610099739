import React from 'react';
import PropTypes from 'prop-types';
import Bowser from 'bowser';
import { withStyles } from '@material-ui/core/styles';
import {
  IconButton,
  MenuItem,
  Typography,
  ListItemText,
  ListItemIcon,
  Link,
  Popover,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';

const styles = theme => ({
  middle: {
    marginTop: 'auto',
    marginBottom: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    float: 'right',
    cursor: 'pointer',
  },
  avatar: {
    width: 40,
    height: 40,
  },
  iconSmall: {
    fontSize: 20,
    marginRight: 10,
  },
  logo: {
    width: '150px',
    [theme.breakpoints.down('xs')]: {
      width: '100px',
    },
  },
});

const IntegrationsMenuItem = ({ title, linkUrl, imgUrl }) => (
  <MenuItem component={Link} href={linkUrl} target="_blank" rel="noopener noreferrer" key={title.replace(/ /g, '-')}>
    <ListItemIcon color="primary">
      <img src={imgUrl} alt="Browser Icon" />
    </ListItemIcon>

    <ListItemText
      primary={(
        <Typography variant="body2">
          {title}
        </Typography>
      )}
    />
  </MenuItem>
);

class IntegrationsMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const browserName = Bowser.getParser(window.navigator.userAgent).getBrowserName();

    return (
      <div>
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={this.handleClick}
          className={classes.iconButton}
        >
          <i className="fas fa-puzzle-piece" />
        </IconButton>
        <Popover
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className="popover-custom-lg overflow-hidden">
            <div className="bg-composed-wrapper bg-midnight-bloom border-0 text-center rounded-sm m-2">
              <div className="bg-composed-img-3 bg-composed-wrapper--image" />
              <div className="bg-composed-wrapper--content text-white px-2 py-4">
                <h4 className="font-size-xl font-weight-bold mb-2">
                  Integrations
                </h4>
              </div>
            </div>
            {browserName === 'Microsoft Edge' && (
              <IntegrationsMenuItem
                title="Microsoft Edge Add-on"
                imgUrl="https://iselo-public-assets.s3.amazonaws.com/icons/web-browser-logo/png_32/Microsoft_Edge_logo_32.png"
                linkUrl="https://microsoftedge.microsoft.com/addons/detail/dbpdmcccdbfhicnangmhiajkecgknfpe"
              />
            )}
            {browserName === 'Firefox' && (
              <IntegrationsMenuItem
                title="Firefox Add-on"
                imgUrl="https://iselo-public-assets.s3.amazonaws.com/icons/web-browser-logo/png_32/firefox-3-226484.png"
                linkUrl="https://addons.mozilla.org/en-US/firefox/addon/save-to-iselo/"
              />
            )}
            {browserName === 'Chrome' && (
              <IntegrationsMenuItem
                title="Chrome Extension"
                imgUrl="https://iselo-public-assets.s3.amazonaws.com/icons/web-browser-logo/png_32/chrome-3-226473.png"
                linkUrl="https://chrome.google.com/webstore/detail/iselo-chrome-extension/jaefgmphcmcmkggipibkmjemeflhokdm"
              />
            )}

            <IntegrationsMenuItem
              title="Slack App"
              imgUrl="https://iselo-public-assets.s3.amazonaws.com/icons/programming-language-logos/png_32/slack-logo-1481728-1254330.png"
              linkUrl="http://api.iseloapp.com/slack_installation"
            />

            <IntegrationsMenuItem
              title="Microsoft Teams App"
              imgUrl="https://iselo-public-assets.s3.amazonaws.com/icons/office-365-1/png_32/teams-1411850-1194339.png"
              linkUrl="https://iselo-public-assets.s3.amazonaws.com/ms-teams-app-packages/ISELO-microsoft-teams-app-1.1.3.zip"
            />
          </div>
        </Popover>
      </div>
    );
  }
}

IntegrationsMenu.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(withRouter(IntegrationsMenu));
