import React from 'react';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

export default ({ filename, onChangeFileClick }) => (
  <Alert
    icon={<i className="far fa-file" />}
    severity="info"
    action={
      onChangeFileClick && (
      <Button color="inherit" size="small" onClick={onChangeFileClick}>
        Select Another File
      </Button>
      )
    }
  >
    {filename}
  </Alert>
);
