import React from 'react';
import { Grid, Hidden } from '@material-ui/core';
import BrowserExtensionButton from '../../../Header/BrowserExtensionButton';
import AddContentsActionBar from '../AddContentsActionBar';

const HeaderMenu = () => (
  <Grid container wrap="nowrap">
    <div className="app-header-menu">
      <Grid container alignItems="center">
        <Grid item>
          <AddContentsActionBar />
        </Grid>
        <Hidden mdDown>
          <Grid item className="ml-4">
            <BrowserExtensionButton />
          </Grid>
        </Hidden>
      </Grid>
    </div>
  </Grid>
);

export default HeaderMenu;
