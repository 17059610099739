import React from 'react';
import { connect } from 'react-redux';
import { IconButton, Tooltip } from '@material-ui/core';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import MixpanelClient from '../../utils/mixpanel/client';
import { MIXPANEL_CLICKED_ON_MARK_COMPLETED } from '../../utils/mixpanel/events';
import { updateContentUserProperties } from '../../actions/Contents';
import { getCurrentUser } from '../../reducers/CurrentUser';

const ContentArchiveButton = ({
  content,
  currentUser,
  updateContentUserPropertiesAction,
}) => {
  const trackMarkCompleted = () => MixpanelClient.track(MIXPANEL_CLICKED_ON_MARK_COMPLETED, { content_title: content.title });

  const isContentArchivedByCurrentUser = () => {
    if (!Array.isArray(content.archived_by_users)) return false;
    return content.archived_by_users.map(u => Number(u.id)).includes(Number(currentUser.id));
  };

  const handleArchiveClick = () => {
    updateContentUserPropertiesAction({ id: content.id, archive: true });
  };

  const handleUnArchiveClick = () => {
    updateContentUserPropertiesAction({ id: content.id, archive: false });
  };

  return isContentArchivedByCurrentUser()
    ? (
      <Tooltip title="Unarchive / Show for me" enterDelay={1000}>
        <IconButton
          color="primary"
          className="m-2"
          size="small"
          aria-label="archive"
          onClick={handleUnArchiveClick}
        >
          <UnarchiveIcon />
        </IconButton>
      </Tooltip>
    ) : (
      <Tooltip title="Archive / Hide for me" enterDelay={1000}>
        <IconButton
          color="primary"
          className="m-2"
          size="small"
          aria-label="archive"
          onClick={() => window.confirm(
            'Are you sure you wish to archive the item? This will hide this item for you. You can use "Show All" to see this again.',
          ) && handleArchiveClick() && trackMarkCompleted()
          }
        >
          <ArchiveIcon />
        </IconButton>
      </Tooltip>
    );
};

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
});

const mapDispatchToProps = dispatch => ({
  updateContentUserPropertiesAction: content => dispatch(updateContentUserProperties(content)).payload,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContentArchiveButton);
