import React from 'react';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';

import { Button } from '@material-ui/core';

const AddPaymentMethodButton = (props) => {
  const creatCheckoutSession = async () => {
    const stripeConfig = await axios.get('/stripe/config');
    const stripe = await loadStripe(stripeConfig.publishableKey);
    axios.post('/stripe/checkout_session', { mode: 'setup' })
      .then((response) => {
        const { data } = response;
        stripe.redirectToCheckout({
          sessionId: data.sessionId,
        }).then((result) => {
          console.log(result);
        }).catch((err) => {
          console.log(err);
        });
      })
      .catch((error) => {
        console.log('Error Creating Checkout Session', error);
      });
  };
  return <Button variant="outlined" size="small" color="primary" onClick={creatCheckoutSession}>Add Payment Method</Button>;
};

export default AddPaymentMethodButton;
