import produce from 'immer';

import {
  UPDATE_TASK_REQUEST,
  UPDATE_TASK_SUCCESS,
  UPDATE_TASK_FAILURE,
  DELETE_TASK_REQUEST,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_FAILURE,
} from '../actions/types';

// Update Task
const updateTaskRequest = state => produce(state, (draft) => {
  draft.loading = true;
  draft.error = null;
});

const updateTaskSuccess = (state, action) => produce(state, (draft) => {
  const task = action.payload;
  const contentIndex = draft.data.findIndex(x => x.id.toString() === task.content_id.toString());
  const content = draft.data.find(x => x.id.toString() === task.content_id.toString());
  const taskIndex = content.tasks.findIndex(x => x.id.toString() === task.id.toString());

  draft.data[contentIndex].tasks[taskIndex] = task;
  draft.loading = false;
});

const updateTaskFailure = (state, action) => produce(state, (draft) => {
  const { error } = action.payload;
  draft.loading = false;
  draft.error = error;
});

// DELETE Task
const deleteTaskRequest = state => produce(state, (draft) => {
  draft.loading = true;
  draft.error = null;
});

const deleteTaskSuccess = (state, action) => produce(state, (draft) => {
  const task = action.payload;
  const contentIndex = draft.data.findIndex(x => x.id.toString() === task.content_id.toString());
  const content = draft.data.find(x => x.id.toString() === task.content_id.toString());
  if (content && contentIndex && task) {
    const taskIndex = content.tasks.findIndex(x => x.id.toString() === task.id.toString());
    draft.data[contentIndex].tasks.splice(taskIndex, 1);
  }

  draft.loading = false;
});

const deleteTaskFailure = (state, action) => produce(state, (draft) => {
  const { error } = action.payload;
  draft.loading = false;
  draft.error = error;
});

const initialState = { data: [] };
export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TASK_REQUEST:
      return updateTaskRequest(state);
    case UPDATE_TASK_SUCCESS:
      return updateTaskSuccess(state, action);
    case UPDATE_TASK_FAILURE:
      return updateTaskFailure(state, action);
    case DELETE_TASK_REQUEST:
      return deleteTaskRequest(state);
    case DELETE_TASK_SUCCESS:
      return deleteTaskSuccess(state, action);
    case DELETE_TASK_FAILURE:
      return deleteTaskFailure(state, action);
    default:
      return state;
  }
};
