import axios from 'axios';
import { enqueueMessage } from './Alerts';
import {
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
  UPDATE_NOTIFICATION_REQUEST,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAILURE,
  DELETE_NOTIFICATION_REQUEST,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAILURE,
} from './types';

import parseErrorMessage from '../utils/errorMessage';

export const fetchNotificationsRequest = () => ({
  type: FETCH_NOTIFICATIONS_REQUEST,
});

export const fetchNotificationsSuccess = Notification => ({
  type: FETCH_NOTIFICATIONS_SUCCESS,
  payload: Notification,
});

export const fetchNotificationsFailure = data => ({
  type: FETCH_NOTIFICATIONS_FAILURE,
  payload: {
    error: data.message,
  },
});

export const fetchNotifications = () => (dispatch) => {
  dispatch(fetchNotificationsRequest());

  return axios
    .get('/notifications')
    .then(response => dispatch(fetchNotificationsSuccess(response.data)))
    .catch((error) => {
      dispatch(fetchNotificationsFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
    });
};

export const updateNotificationRequest = () => ({
  type: UPDATE_NOTIFICATION_REQUEST,
});

export const updateNotificationSuccess = notification => ({
  type: UPDATE_NOTIFICATION_SUCCESS,
  payload: notification,
});

export const updateNotificationFailure = data => ({
  type: UPDATE_NOTIFICATION_FAILURE,
  payload: {
    error: data.message,
  },
});

export const updateNotification = notification => (dispatch) => {
  dispatch(updateNotificationRequest());

  return axios.put(`/notifications/${notification.id}`, { notification })
    .then(() => {
      dispatch(updateNotificationSuccess(notification));
      return notification.id;
    })
    .catch((error) => {
      dispatch(updateNotificationFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
    });
};

export const deleteNotificationRequest = () => ({
  type: DELETE_NOTIFICATION_REQUEST,
});

export const deleteNotificationSuccess = notification => ({
  type: DELETE_NOTIFICATION_SUCCESS,
  payload: notification,
});

export const deleteNotificationFailure = data => ({
  type: DELETE_NOTIFICATION_FAILURE,
  payload: {
    error: data.message,
  },
});

export const deleteNotification = notificationId => (dispatch) => {
  dispatch(deleteNotificationRequest());

  return axios.delete(`/notifications/${notificationId}`)
    .then(() => {
      dispatch(deleteNotificationSuccess(notificationId));
      return notificationId;
    })
    .catch((error) => {
      dispatch(deleteNotificationFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
    });
};
