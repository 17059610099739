import axios from 'axios';
import { enqueueMessage } from './Alerts';
import {
  CREATE_INVITATION_REQUEST,
  CREATE_INVITATION_SUCCESS,
  CREATE_INVITATION_FAILURE,
  RESEND_INVITATION_REQUEST,
  RESEND_INVITATION_SUCCESS,
  RESEND_INVITATION_FAILURE,
} from './types';

import { fetchOrganization } from './Organization';
import parseErrorMessage from '../utils/errorMessage';

export const createInvitationReqeust = () => ({
  type: CREATE_INVITATION_REQUEST,
});

export const createInvitationSuccess = group => ({
  type: CREATE_INVITATION_SUCCESS,
  payload: group,
});

export const createInvitationFailure = data => ({
  type: CREATE_INVITATION_FAILURE,
  payload: {
    error: data.message,
  },
});

export const createInvitation = (name, email, role) => (dispatch) => {
  dispatch(createInvitationReqeust());

  return axios.post('/organization/invitations', { name, email, role })
    .then((response) => {
      dispatch(createInvitationSuccess(response.data));
      dispatch(fetchOrganization());
      dispatch(enqueueMessage('Inviation Created and Sent Successfully', 'success'));
      return response.data;
    })
    .catch((error) => {
      dispatch(createInvitationFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
    });
};

export const resendInvitationRequest = () => ({
  type: RESEND_INVITATION_REQUEST,
});

export const resendInvitationSuccess = () => ({
  type: RESEND_INVITATION_SUCCESS,
});

export const resendInvitationFailure = data => ({
  type: RESEND_INVITATION_FAILURE,
  payload: {
    error: data.message,
  },
});

export const resendInvitation = userId => (dispatch) => {
  dispatch(resendInvitationRequest());

  return axios.post('/organization/invitations', { user_id: userId })
    .then((response) => {
      dispatch(resendInvitationSuccess(response.data));
      dispatch(enqueueMessage('Inviation Sent Successfully', 'success'));
      return response.data;
    })
    .catch((error) => {
      dispatch(resendInvitationFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
    });
};
