// DELETE File
import produce from 'immer';

import {
  DELETE_FILE_REQUEST,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_FAILURE,
} from '../actions/types';

const deleteFileRequest = state => produce(state, (draft) => {
  draft.loading = true;
  draft.error = null;
});

const deleteFileSuccess = (state, action) => produce(state, (draft) => {
  const file = action.payload;
  const contentIndex = draft.data.findIndex(x => x.id.toString() === file.content_id.toString());
  const content = draft.data.find(x => x.id.toString() === file.content_id.toString());

  if (content && contentIndex && file) {
    const fileIndex = content.files.findIndex(x => x.id.toString() === file.id.toString());
    draft.data[contentIndex].files.splice(fileIndex, 1);
  }

  draft.loading = false;
});

const deleteFileFailure = (state, action) => produce(state, (draft) => {
  const { error } = action.payload;
  draft.loading = false;
  draft.error = error;
});

const initialState = { data: [] };
export default (state = initialState, action) => {
  switch (action.type) {
    case DELETE_FILE_REQUEST:
      return deleteFileRequest(state);
    case DELETE_FILE_SUCCESS:
      return deleteFileSuccess(state, action);
    case DELETE_FILE_FAILURE:
      return deleteFileFailure(state, action);
    default:
      return state;
  }
};
