import {
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
} from '../actions/types';

const initialState = { data: [] };
export default (state = initialState, action) => {
  switch (action.type) {
    case DELETE_USER_REQUEST:
      return { ...state, error: undefined, loading: true };
    case DELETE_USER_SUCCESS: {
      return { ...state, error: undefined, loading: false };
    }
    case DELETE_USER_FAILURE: {
      const { error } = action.payload;
      return { ...state, error, loading: false };
    }
    default:
      return state;
  }
};
