import React from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Paper';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import { Editor, EditorState, convertFromRaw } from 'draft-js';

import { deleteComment } from '../../../actions/Comments';

import Gravatar from '../../UI/Gravatar';

const styles = () => ({
  // card: {
  //   maxWidth: '600px',
  // },
});

const Comment = (props) => {
  const { comment, classes, deleteCommentAction } = props;
  let editorState = EditorState.createEmpty();

  if (comment.body !== null) {
    const contentState = convertFromRaw(JSON.parse(comment.body));
    editorState = EditorState.createWithContent(contentState);
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={(
          <Gravatar email={comment.user.email} />
        )}
        action={(
          <IconButton aria-label="delete" onClick={() => window.confirm("Are you sure you wish to delete the comment?") && deleteCommentAction(comment)}>
            <DeleteIcon color="error" />
          </IconButton>
        )}
        title={comment.user.name}
        subheader={Moment(comment.created_at).fromNow()}
      />
      <CardContent>
        <Typography variant="body2" component="div">
          <Editor editorState={editorState} readOnly />
        </Typography>
      </CardContent>
    </Card>
  );
};

const mapDispatchToProps = dispatch => ({
  deleteCommentAction: comment => dispatch(deleteComment(comment)),
});

export default connect(
  null,
  mapDispatchToProps,
)(withStyles(styles)(Comment));
