const actions = {
  identify: ({ email, name, organization }) => {
    const waitForZopim = setInterval(() => {
      if (window.zE === undefined) {
        return;
      }
      window.zE('webWidget', 'prefill', {
        name: {
          value: name,
          readOnly: true,
        },
        email: {
          value: email,
          readOnly: true,
        }
      });
      // Identify in chat widget
      window.zE('webWidget', 'identify', { name, email, organization });
      clearInterval(waitForZopim);
    }, 500);
  },
};

export default actions;
