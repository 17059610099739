import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import algoliasearch from 'algoliasearch/lite';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  InstantSearch, RefinementList, InfiniteHits,
} from 'react-instantsearch-dom';
import {
  Grid,
  Paper,
  Box,
} from '@material-ui/core';

import classNames from 'classnames';
import { algoliaApplicationId, algoliaContentsIndex } from '../../../utils/algolia';
import MaterialUiSearchBox from '../../UI/Algolia/MaterialUiSearchBox';
import MaterialUiCheckBoxRefinementList from '../../UI/Algolia/MaterialUiCheckBoxRefinementList';
import MaterialUiToggleRefinement from '../../UI/Algolia/MaterialUiToggleRefinement';

import ContentLongCard from '../../ContentLongCard';
import NoContentsScreen from './NoContentsScreen';
// import RightSideDrawer from '../RightSideDrawer/RightSideDrawer';
// import ShowOnlyStarredFilter from '../../ShowOnlyStarredFilter';
// import ShowWithArchivedFilter from '../../ShowWithArchivedFilter';
// import Sortby from '../../Sortby';
import { searchTextChanged, resetFilters } from '../../../actions/Filters';
import {
  getContentsCount,
  getContentsLoading,
} from '../../../reducers/Contents';
import { getCurrentUser } from '../../../reducers/CurrentUser';
import { getAlgoliaSearchKey } from '../../../reducers/Algolia';
import { setCurrentOwner } from '../../../actions/CurrentOwner';
import { setCurrentListId } from '../../../actions/CurrentList';
import StateResults from './StateResults';
import ContentAreaTitleBar from './ContentAreaTitleBar';

const styles = theme => ({
  content: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
    padding: theme.spacing(2),
    boxSizing: 'border-box',
  },
});
class ContentArea extends React.Component {
  constructor(props) {
    super(props);
    this.searchResultsRef = React.createRef();
  }

  componentDidMount() {
    this.loadInitialData();
  }

  scrollToTop = () => this.searchResultsRef.current._container.scrollTo({ top: 0, behavior: 'smooth' });

  componentDidUpdate = (prevProps, prevState) => {
    const {
      ownerId, ownerType, listId, setCurrentOwnerAction, setCurrentListIdAction, resetFiltersAction,
    } = this.props;
    if ((prevProps?.ownerType !== ownerType)
      || (prevProps?.ownerId !== ownerId)) {
      setCurrentOwnerAction({ ownerType, ownerId });
    }

    if (listId && prevProps?.listId !== listId) {
      setCurrentListIdAction(listId);
    }
    resetFiltersAction();
  }

  loadInitialData = () => {
    const {
      ownerId, ownerType, listId, setCurrentOwnerAction, setCurrentListIdAction, resetFiltersAction,
    } = this.props;
    setCurrentOwnerAction({ ownerType, ownerId });
    setCurrentListIdAction(listId);
    resetFiltersAction();
  }

  algoliaClient = searchKey => algoliasearch(algoliaApplicationId(), searchKey);

  searchClient = searchKey => Object.assign(this.algoliaClient(searchKey), {
    search: requests => this.algoliaClient(searchKey).search(requests),
  });

  handleSearchChange = (event) => {
    const { target } = event;
    const { searchTextChangedAction } = this.props;
    searchTextChangedAction(target.value);
  };

  render() {
    const {
      searchText,
      contentsLoading,
      contentsCount,
      rightOpen,
      handleLeftDrawerToggle,
      handleRightDrawerToggle,
      algoliaSearchKey,
      currentUser,
      listId,
    } = this.props;
    if (!currentUser) return null;
    let renderPartial = null;
    if (!contentsLoading) {
      // if (contentsCount === 0) {
      if (!algoliaSearchKey) {
        renderPartial = (<NoContentsScreen />);
      } else {
        renderPartial = (
          <>
            <div className="app-inner-content-layout app-inner-content-layout-fixed">
              <InstantSearch searchClient={this.searchClient(algoliaSearchKey)} indexName={algoliaContentsIndex()}>
                {/* <Configure filters={`NOT archived_by_users.id:${currentUser.id}`} /> */}
                <div className="app-inner-content-layout--main">

                  <Box zIndex="modal">
                    <Paper square elevation={1} className="p-2">
                      <Grid container direction="column">
                        <ContentAreaTitleBar />

                        <Grid item container>
                          <Grid item xs>
                            <MaterialUiSearchBox
                              autoFocus
                              delay={1000}
                              showLoadingIndicator
                              searchAsYouType={false}
                              onChange={this.scrollToTop}
                              submit={<i className="fas fa-search" />}
                            />
                          </Grid>
                          <Grid>
                            <MaterialUiToggleRefinement
                              attribute="archived_by_users.id"
                              label="Show All (Including Archived)"
                              value={`-${currentUser.id}`}
                        // Optional parameters
                              defaultRefinement
                            />
                          </Grid>
                        </Grid>
                        { listId && listId > 0 && <RefinementList attribute="list_id" defaultRefinement={[listId]} /> }
                      </Grid>
                    </Paper>
                  </Box>
                  <PerfectScrollbar id="content-area" ref={this.searchResultsRef}>
                    <Grid item xs>
                      <StateResults>
                        <Grid item container direction="column" alignItems="center">
                          <InfiniteHits hitComponent={props => <ContentLongCard {...props} />} />
                        </Grid>
                      </StateResults>
                    </Grid>
                  </PerfectScrollbar>
                </div>
                {/* <Hidden mdUp implementation="css" className={classes.leftSidebar}>
                <Drawer
                  variant="temporary"
                  anchor="right"
                  open={rightOpen}
                  onClose={handleRightDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                >
                  <MaterialUiCheckBoxRefinementList
                    searchable
                    attribute="tags"
                    limit={100}
                  />
                </Drawer>
              </Hidden> */}
                {/* <Hidden smDown implementation="css"> */}
                {/* <Grid item xs={3}> */}
                <div
                  className={classNames(
                    'app-inner-content-layout--sidebar app-inner-content-layout--sidebar__sm pos-r bg-white border-left',
                    { 'layout-sidebar-open': rightOpen },
                  )}
                >
                  <PerfectScrollbar>
                    <div>
                      <MaterialUiCheckBoxRefinementList
                        searchable
                        attribute="tags"
                        limit={100}
                        onChange={this.scrollToTop}
                      />
                    </div>
                  </PerfectScrollbar>
                </div>

                {/* </Grid> */}
                {/* </Hidden> */}
              </InstantSearch>

              {/* <RightSideDrawer
              open={rightOpen}
              handleDrawerToggle={handleRightDrawerToggle}
            /> */}
              {/* </Grid> */}
              {/* </Grid> */}

            </div>
          </>
        );
      }
    }

    return renderPartial;
  }
}

ContentArea.defaultProps = {
  contentsLoading: false,
  currentUser: NodeFilter,
};

ContentArea.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  currentUser: PropTypes.shape({}),
  searchText: PropTypes.string.isRequired,
  searchTextChangedAction: PropTypes.func.isRequired,
  contentsLoading: PropTypes.bool,
  contentsCount: PropTypes.number.isRequired,
  setCurrentOwnerAction: PropTypes.func.isRequired,
  setCurrentListIdAction: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  searchText: state.Filters.searchText,
  contentsLoading: getContentsLoading(state),
  contentsCount: getContentsCount(state),
  currentUser: getCurrentUser(state),
  algoliaSearchKey: getAlgoliaSearchKey(state),
});

const mapDispatchToProps = dispatch => ({
  searchTextChangedAction: searchText => dispatch(searchTextChanged(searchText)),
  resetFiltersAction: () => dispatch(resetFilters()),
  setCurrentOwnerAction: ({ ownerType, ownerId }) => dispatch(setCurrentOwner({ ownerType, ownerId })),
  setCurrentListIdAction: listId => dispatch(setCurrentListId(listId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(ContentArea));
