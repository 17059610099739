import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  Typography,
  Grid,
  CardActions,
  Card,
  CardContent,
  Box,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@material-ui/core';
import cloneDeep from 'lodash/cloneDeep';
import setValue from 'lodash/set';
import { objectToFormData } from 'object-to-formdata';
import CreatableSelect from 'react-select/creatable';
import {
  EditorState,
  ContentState,
  convertFromRaw,
  convertToRaw,
} from 'draft-js';
// import TagsEditor from '../TagsEditor';
import {
  createContent,
  updateContent,
  deleteContent,
} from '../../actions/Contents';
import { getGroups } from '../../reducers/Groups';
import { getTags } from '../../reducers/Tags';
import { fetchTags } from '../../actions/Tags';
import {
  getContentParentDashboardPath,
  isGroup,
  isOrganization,
  isUser,
} from '../../utils/listOwnerTypes';
import DraftEditor from '../UI/DraftEditor';
import { getOrganization } from '../../reducers/Organization';
import { getCurrentUser } from '../../reducers/CurrentUser';
import { GROUP, ORGANIZATION, USER } from '../../Constants';
import IseloSelect from '../UI/Common/IseloSelect';
import FileNameInfoBox from './FileNameInfoBox';
import FileInput from './FileInput';
import {
  getCurrentOwnerId,
  getCurrentOwnerType,
} from '../../reducers/CurrentOwner';
import { getCurrentListId } from '../../reducers/CurrentList';
import MiniHeader from '../NewDashboard/dashboard-components/MiniHeader';

const styles = theme => ({
  button: {
    marginRight: theme.spacing(1),
  },
  memoCard: {
    width: theme.spacing(100),
    margin: theme.spacing(1),
  },
  card: {
    maxWidth: theme.spacing(60),
    minWidth: theme.spacing(38),
    margin: theme.spacing(1),
  },
  smallCard: {
    maxWidth: theme.spacing(25),
    minWidth: theme.spacing(25),
    margin: theme.spacing(1),
  },
  icon: {
    padding: theme.spacing(0, 1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  favicon: {
    maxWidth: theme.spacing(4),
    maxHeight: theme.spacing(4),
  },
});

const initialContentState = {
  url: null,
  title: null,
  description: null,
  group: null,
  tags: [],
  links: [],
  memos: [],
  tasks: [],
  files: [],
  owner_type: USER,
  list_id: 0,
};

class ContentForm extends React.Component {
  constructor(props) {
    super(props);
    const { content } = props;
    const descriptionEditorState = EditorState.createEmpty();
    this.state = {
      ...this.state,
      content: cloneDeep(content),
      descriptionEditorState,
    };
  }

  componentDidMount() {
    const { match, fetchTagsAction } = this.props;
    fetchTagsAction(); // For refereshing tags
    if (match.params.contentId) {
      axios.get(`/contents/${match.params.contentId}`).then((response) => {
        const content = response.data;
        let descriptionEditorState = EditorState.createEmpty();
        if (content.description) {
          try {
            descriptionEditorState = EditorState.createWithContent(
              convertFromRaw(JSON.parse(content.description)),
            );
          } catch (e) {
            descriptionEditorState = EditorState.createEmpty();
          }
        }
        this.setState({ content, descriptionEditorState });
      });
    } else {
      const { content } = this.state;
      const { currentOwnerType, currentOwnerId, currentListId } = this.props;
      content.owner_type = currentOwnerType || USER;
      content.owner_id = currentOwnerId;
      content.list_id = currentListId;
      this.setState({ content });
    }
  }

  updateDescriptionEditorState = (descriptionTxt) => {
    let descriptionEditorState = EditorState.createEmpty();
    if (descriptionTxt) {
      try {
        descriptionEditorState = EditorState.createWithContent(
          ContentState.createFromText(descriptionTxt),
        );
      } catch (e) {
        descriptionEditorState = EditorState.createEmpty();
      }
    }
    this.setState({ descriptionEditorState });
  };

  fetchMetadata = (e) => {
    const { target } = e;
    const { content } = this.state;
    content.url = target.value;
    this.setState({ content });
    axios
      .get('/link-metadata', { params: { url: target.value } })
      .then((response) => {
        const { descriptionEditorState } = this.state;
        const { data } = response;
        if (!content.title) {
          content.title = data.title;
          // content.favicon = data.favicon;
        }
        this.setState({ content });
        if (!descriptionEditorState.getCurrentContent().hasText()) {
          this.updateDescriptionEditorState(data.description);
        }
      })
      .catch((error) => {
        console.log('Error Fetching metadata', error);
      });
  };

  handleDescriptionChange = (descriptionEditorState) => {
    this.setState({ descriptionEditorState });
  };

  handleChange = (e) => {
    const { target } = e;
    const { content } = this.state;
    setValue(content, target.name, target.value);
    this.setState({ content });
  };

  getOwner = (ownerType, ownerId) => {
    const { currentUser, organization, groups } = this.props;
    if (isUser(ownerType)) return currentUser;
    if (isGroup(ownerType)) {
      return groups.length > 0 && ownerId
        ? groups.find(x => parseInt(x.id, 10) === parseInt(ownerId, 10))
        : groups[0];
    }
    if (isOrganization(ownerType)) return organization;
  };

  getOwnerId = ownerType => (this.getOwner(ownerType) ? this.getOwner(ownerType).id : 0);

  handleOwnerTypeChange = (e) => {
    const { target } = e;
    const { content } = this.state;
    content.list_id = 0;
    content.owner_id = this.getOwnerId(target.value);
    setValue(content, target.name, target.value);
    this.setState({ content });
  };

  handleTagsChange = (valueObject) => {
    const { content } = this.state;
    content.tags = valueObject.map(o => o.value);
    this.setState({ content, tagsInputValue: '' });
  };

  onTagsInputChange = (textInput, { action }) => {
    if (action === 'input-blur') {
      const { content, tagsInputValue } = this.state;
      if (tagsInputValue) {
        content.tags = ([...(content.tags || []), tagsInputValue]);
        this.setState({ content, tagsInputValue: '' });
      }
    }
    if (action === 'input-change') {
      if (textInput && textInput.endsWith(',')) {
        const { content } = this.state;
        const label = textInput.slice(0, -1); // trim off comma

        content.tags = ([...(content.tags || []), label]);
        this.setState({ content, tagsInputValue: '' });
      } else {
        this.setState({ tagsInputValue: textInput });
      }
    }
  };

  handleGroupChange = (e) => {
    const { groups } = this.props;
    const { content } = this.state;
    const group = groups.find(x => x.id === e.target.value);
    content.owner_id = (group && group.id) || null;
    content.list_id = 0;
    this.setState({ content });
  };

  handleDelete = () => {
    const { deleteContentAction } = this.props;
    const { content } = this.state;
    deleteContentAction(content).then(() => this.redirectBack());
  };

  redirectBack = (response) => {
    const { content } = this.state;
    window.location = getContentParentDashboardPath(response || content);
  };

  handleFileInputChange = (files) => {
    const { content } = this.state;
    this.setState({
      content: {
        ...content,
        title: files[0].name,
        files,
      },
    });
  };

  clearFiles = () => {
    const { content } = this.state;
    this.setState({
      content: {
        ...content,
        files: [],
      },
    });
  };

  handleSave = (e) => {
    e.stopPropagation();
    const { createContentAction, updateContentAction } = this.props;
    const { descriptionEditorState } = this.state;
    const descriptionJsonString = JSON.stringify(
      convertToRaw(descriptionEditorState.getCurrentContent()),
    );
    const descriptionRawText = descriptionEditorState.getCurrentContent().getPlainText('\u0001');

    let { content } = this.state;
    content = {
      ...content,
      description: descriptionJsonString,
      description_raw_text: descriptionRawText,
    };

    if (content.list_id && content.list_id === -1 && content.list_name) {
      content = {
        ...content,
        list: {
          name: content.list_name,
        },
      };
    }

    if (content.list_id) {
      content = {
        ...content,
        list_id: content.list_id > 0 ? content.list_id : null,
      };
    }

    if (!content.id && content.url && content.url.length > 0) {
      content = {
        ...content,
        links: [
          {
            title: content.title,
            url: content.url,
          },
        ],
      };
    }

    const formData = objectToFormData({ content });

    const action = content.id ? updateContentAction : createContentAction;
    // const payload = { content };
    action(formData).then((res) => {
      console.log({res});
      this.redirectBack(res);
    });
  };

  autoFill = () => {
    const { content } = this.state;

    axios
      .get('/link-metadata', { params: { url: content.url } })
      .then((response) => {
        const { data } = response;
        if (!content.title) {
          content.title = data.title;
          this.setState({ content });
        }
      })
      .catch((error) => {
        console.log('Error Fetching metadata', error);
      });
  };

  validateUrl = (url) => {
    try {
      /* eslint-disable */
      new URL(url);
      /* eslint-enable */
    } catch (e) {
      return false;
    }

    return true;
  };

  render() {
    const {
      groups, tagSuggestions, classes, location,
    } = this.props;
    const params = new URLSearchParams(location.search);
    const { content, descriptionEditorState, tagsInputValue } = this.state;
    const listOwner = this.getOwner(content.owner_type, content.owner_id);
    return (
      <>
        <MiniHeader>
          <Typography variant="h6">{content.id ? 'Edit Content' : 'Add New Content'}</Typography>
        </MiniHeader>

        <Grid
          container
          direction="row-reverse"
          justify="center"
          alignContent="center"
          className="m-5"
        >
          <Grid>
            <Card className={classes.smallCard}>
              <CardContent>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Visibility</FormLabel>
                  <RadioGroup
                    aria-label="owner_type"
                    name="owner_type"
                    defaultValue={USER}
                    value={content.owner_type}
                    onChange={this.handleOwnerTypeChange}
                  >
                    {/* <Grid item container justify="space-around"> */}
                    <FormControlLabel
                      value={USER}
                      control={<Radio color="primary" />}
                      label="Private"
                    />
                    {groups.length > 0 && (
                      <FormControlLabel
                        value={GROUP}
                        control={<Radio color="primary" />}
                        label="Group"
                      />
                    )}
                    <FormControlLabel
                      value={ORGANIZATION}
                      control={<Radio color="primary" />}
                      label="Organization"
                    />
                    {/* </Grid> */}
                  </RadioGroup>
                </FormControl>
                <hr />
                {isGroup(content.owner_type) && (
                  <>
                    <IseloSelect
                      label="Select Group"
                      handleChange={this.handleGroupChange}
                      value={content.owner_id}
                    >
                      {groups.map(group => (
                        <MenuItem value={group.id} key={group.id}>
                          <i className="fa fa-users" fontSize="small" />
                          &nbsp;
                          <Typography variant="inherit">
                            {group.name}
                          </Typography>
                        </MenuItem>
                      ))}
                    </IseloSelect>
                    <hr />
                  </>
                )}

                <IseloSelect
                  label="Select List"
                  handleChange={this.handleChange}
                  value={content.list_id || 0}
                >
                  <MenuItem value={0}>
                    <i className="fas fa-angle-double-left pr-2" />
                    UNLISTED
                    <i className="fas fa-angle-double-right pl-2" />
                  </MenuItem>
                  {listOwner
                    && listOwner.lists
                    && listOwner.lists.map(list => (
                      <MenuItem value={list.id} key={list.id}>
                        <Typography variant="inherit">{list.name}</Typography>
                      </MenuItem>
                    ))}
                  <MenuItem value={-1}>
                    <i className="fas fa-angle-double-left pr-2" />
                    CREATE NEW LIST
                    <i className="fas fa-angle-double-right pl-2" />
                  </MenuItem>
                </IseloSelect>
                {content.list_id && content.list_id === -1 && (
                  <TextField
                    required
                    margin="dense"
                    fullWidth
                    variant="outlined"
                    label="List Name"
                    name="list_name"
                    value={content.list_name || ''}
                    onChange={this.handleChange}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid>
            <Card className={classNames(
              params.get('type') === 'memo' && classes.memoCard,
              params.get('type') !== 'memo' && classes.card,
            )}
            >
              <CardContent>
                <Grid
                  item
                  container
                  direction="column"
                  spacing={0}
                  justify="center"
                >
                  {(params.get('type') === null
                    || params.get('type') === 'link')
                    && !content.id && (
                      <TextField
                        autoFocus
                        required
                        margin="dense"
                        fullWidth
                        variant="outlined"
                        label="URL"
                        name="url"
                        value={content.url || ''}
                        onChange={this.fetchMetadata}
                      />
                  )}

                  {params.get('type') === 'file'
                    && content.files.length > 0 && (
                      <FileNameInfoBox
                        filename={content.files[0].name}
                        onChangeFileClick={this.clearFiles}
                      />
                  )}

                  {params.get('type') === 'file'
                    && content.files.length === 0 && (
                      <FileInput onChange={this.handleFileInputChange} />
                  )}

                  {(content.id
                    || (params.get('type') === 'link' && content.url)
                    || (params.get('type') === 'file'
                      && content.files.length > 0)
                    || params.get('type') === 'memo') && (
                    <>
                      <TextField
                        required
                        margin="dense"
                        fullWidth
                        variant="outlined"
                        label="Title"
                        name="title"
                        value={content.title || ''}
                        onChange={this.handleChange}
                      />

                      <CreatableSelect
                        isMulti
                        onChange={this.handleTagsChange}
                        onInputChange={this.onTagsInputChange}
                        inputValue={tagsInputValue}
                        options={tagSuggestions.map(x => ({
                          label: x,
                          value: x,
                        }))}
                        value={content.tags.map(x => ({
                          label: x,
                          value: x,
                        }))}
                        name="tags"
                        placeholder="Tags/Keywords"
                      />

                      {/* <TagsEditor
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        label="Tags/Keywords (Press Enter to create tags)"
                        suggestions={tagSuggestions.map(x => ({
                          label: x,
                          value: x,
                        }))}
                        name="tags"
                        value={content.tags}
                        onChange={this.handleTagsChange}
                      /> */}

                      <Grid item>
                        <InputLabel shrink>Notes</InputLabel>
                        <DraftEditor
                          margin="dense"
                          editorState={descriptionEditorState}
                          onChange={this.handleDescriptionChange}
                          autoFocus={false}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </CardContent>
              <hr />
              <CardActions>
                <Grid container>
                  {content.id && (
                    <Button
                      color="secondary"
                      onClick={() => window.confirm(
                        'Are you sure you wish to delete the item?',
                      ) && this.handleDelete()
                      }
                    >
                      <i className="fa fa-trash-alt" />
                      &nbsp; Delete
                    </Button>
                  )}

                  <Box flexGrow={1} />

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleSave}
                    className={classes.button}
                  >
                    <i className="fa fa-plus-circle" />
                    &nbsp; Save
                  </Button>

                  <Button
                    color="primary"
                    onClick={this.redirectBack}
                    className={classes.button}
                  >
                    Cancel
                  </Button>
                </Grid>
              </CardActions>

              {/* <div><pre>{JSON.stringify(this.state.content, null, 2) }</pre></div> */}
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }
}

ContentForm.defaultProps = {
  content: initialContentState,
};

ContentForm.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tagSuggestions: PropTypes.arrayOf(PropTypes.string).isRequired,
  content: PropTypes.shape({}),
  createContentAction: PropTypes.func.isRequired,
  updateContentAction: PropTypes.func.isRequired,
  currentOwnerType: PropTypes.string.isRequired,
  currentOwnerId: PropTypes.string.isRequired,
  currentListId: PropTypes.string.isRequired,
  deleteContentAction: PropTypes.func.isRequired,
};

const matchStateToProps = state => ({
  currentOwnerType: getCurrentOwnerType(state),
  currentOwnerId: getCurrentOwnerId(state),
  currentListId: getCurrentListId(state),
  groups: getGroups(state),
  currentUser: getCurrentUser(state),
  organization: getOrganization(state),
  tagSuggestions: getTags(state),
});

const mapDispatchToProps = dispatch => ({
  fetchTagsAction: () => dispatch(fetchTags()),
  createContentAction: payload => dispatch(createContent(payload)),
  updateContentAction: payload => dispatch(updateContent(payload)),
  deleteContentAction: payload => dispatch(deleteContent(payload)),
});

export default connect(
  matchStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(ContentForm)));
