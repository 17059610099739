import {
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_FAILURE,
  FETCH_NOTIFICATIONS_SUCCESS,
  UPDATE_NOTIFICATION_REQUEST,
  UPDATE_NOTIFICATION_FAILURE,
  UPDATE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_REQUEST,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAILURE,
} from '../actions/types';

const initialState = { data: [] };
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        data: [],
        error: undefined,
        loading: true,
      };
    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_NOTIFICATIONS_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        data: [],
        error,
        loading: false,
      };
    }
    case UPDATE_NOTIFICATION_REQUEST:
      return { ...state, error: undefined, loading: true };
    case UPDATE_NOTIFICATION_SUCCESS: {
      const notification = action.payload;
      const notificationIndex = state.data.findIndex(x => x.id === notification.id);
      const data = [...state.data];
      data.splice(notificationIndex, 1, notification);
      return { ...state, data, loading: false };
    }
    case UPDATE_NOTIFICATION_FAILURE: {
      const { error } = action.payload;
      return { ...state, error, loading: false };
    }
    case DELETE_NOTIFICATION_REQUEST:
      return { ...state, error: undefined, loading: true };
    case DELETE_NOTIFICATION_SUCCESS: {
      const notificationId = action.payload;
      const notificationIndex = state.data.findIndex(x => x.id === notificationId);
      const data = [...state.data];
      data.splice(notificationIndex, 1);
      return { ...state, data, loading: false };
    }
    case DELETE_NOTIFICATION_FAILURE: {
      const { error } = action.payload;
      return { ...state, error, loading: false };
    }
    default:
      return state;
  }
};

export const getNotifications = state => state.Notifications.data;
