import React from 'react';
import { List } from '@material-ui/core';
import StarListItem from './StarListItem';
import Tour from './Tour';

const Tour6 = () => (
  <Tour
    title="How to create group and add group members?"
    image="/assets/images/tour/product-tour6.jpeg"
    previousUrl="/tour/5"
    nextUrl="/tour/7"
  >
    <List dense>
      <StarListItem>
        Step1: Visit the Groups List page
      </StarListItem>
      <StarListItem>
        Step2: Click on &quot;All Members&quot; to list members and add members
      </StarListItem>
      <StarListItem>
        Remember, users should be first registered to an orgnaization
      </StarListItem>
    </List>
  </Tour>
);

export default Tour6;
