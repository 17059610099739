import {
  Grid, IconButton, Tooltip, Typography,
} from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCurrentListId, getCurrentListName } from '../../../reducers/CurrentList';
import {
  getCurrentOwner, getCurrentOwnerId, getCurrentOwnerTypeTitle, isCurrentOwnerGroup, isCurrentOwnerOrganization,
} from '../../../reducers/CurrentOwner';
import { getCurrentUser } from '../../../reducers/CurrentUser';

const ContentAreaTitleBar = ({
  currentOwnerTypeTitle, currentListName, isGroup, currentOwnerId, currentListId, isOrganization, user
}) => (
  <Grid item container className="" alignItems="center">
    <Typography variant="h6">{currentOwnerTypeTitle}</Typography>
    {currentListName && (
      <>
        <Typography variant="subtitle2">{currentListName && ` → ${currentListName}`}</Typography>
        <Tooltip title="Edit List">
          <IconButton size="small" color="primary" component={Link} to={`/lists/${currentListId}/edit`}>
            <i className="fa fa-pencil-alt" />
          </IconButton>
        </Tooltip>
      </>
    )}

    <Grid item xs />
    {isGroup && (
      <Tooltip title="Manage Members of this Group">
        <IconButton size="small" color="primary" component={Link} to={`/groups/${currentOwnerId}/edit`}>
          <i className="fas fa-user-plus" />
        </IconButton>
      </Tooltip>
    )}

    {isOrganization && user && user.organization_role === 'admin' && (
    <Tooltip title="Manage Users in the Organization">
      <IconButton color="primary" component={Link} to="/organization/users">
        <i className="fas fa-users-cog" />
      </IconButton>
    </Tooltip>
    )}
  </Grid>
);

const mapStateToProps = state => ({
  currentOwnerTypeTitle: getCurrentOwnerTypeTitle(state),
  currentOwnerId: getCurrentOwnerId(state),
  currentOwner: getCurrentOwner(state),
  currentListId: getCurrentListId(state),
  currentListName: getCurrentListName(state),
  isGroup: isCurrentOwnerGroup(state),
  isOrganization: isCurrentOwnerOrganization(state),
  user: getCurrentUser(state),
});

export default connect(mapStateToProps)(ContentAreaTitleBar);
