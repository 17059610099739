import React from 'react';
import Grid from '@material-ui/core/Grid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Comment from './Comment';

const CommentList = (props) => {
  const { comments } = props;
  return (
    <div className="app-inner-content-layout--main order-3 order-lg-2 card-box bg-secondary">
      <PerfectScrollbar>
        <div className="card-header rounded-0 bg-white p-1 border-bottom">
          <div className="card-header--title">
            <small>Comments</small>
          </div>
        </div>
        <div className="chat-wrapper">
          {comments.map(comment => (
            <Grid key={comment.id} item className="p-2">
              <Comment comment={comment} />
            </Grid>
          ))}
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default CommentList;
