import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, TextField } from '@material-ui/core';
import { createMembership, deleteMembership } from '../../actions/Memberships';
import AddModal from '../AddModal';

class AddGroupMemberButton extends React.Component {
  state = {
    open: false,
    email: '',
  };

  handleOpen = (e) => {
    e.stopPropagation();
    this.setState({ open: true });
  };

  handleClose = (e) => {
    e.stopPropagation();
    this.setState({ open: false });
  };

  handleDelete = (e) => {
    e.stopPropagation();
    const { membership, deleteMembershipAction } = this.props;
    deleteMembershipAction(membership).then(() => {
      this.setState({ open: false });
    });
  };

  handleSave = (e) => {
    e.stopPropagation();
    const { groupId, createMembershipAction } = this.props;
    const { email } = this.state;
    createMembershipAction(groupId, email).then(() => {
      this.setState({ open: false });
    });
  };

  onChange = (e) => {
    this.setState({ email: e.target.value });
  };

  render() {
    const { className, children } = this.props;
    const { open, email } = this.state;

    return (
      <Button
        variant="contained"
        color="primary"
        className={className}
        role="button"
        tabIndex="-1"
        onClick={this.handleOpen}
        onKeyDown={() => {}}
      >
        {children}
        <AddModal
          open={open}
          onClose={this.handleClose}
          onSave={this.handleSave}
          onDelete={this.handleDelete}
          heading="Add a group member"
          showDelete={false}
        >
          <TextField
            type="email"
            required
            autoFocus
            margin="dense"
            id="email"
            label="Email addresss of the user"
            fullWidth
            value={email}
            onChange={this.onChange}
          />
        </AddModal>
      </Button>
    );
  }
}

AddGroupMemberButton.defaultProps = {
  membership: {},
  className: '',
};

AddGroupMemberButton.propTypes = {
  groupId: PropTypes.string.isRequired,
  className: PropTypes.string,
  membership: PropTypes.shape({}),
  createMembershipAction: PropTypes.func.isRequired,
  deleteMembershipAction: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    createMembershipAction: (groupId, email) => dispatch(createMembership(groupId, email)),
    deleteMembershipAction: groupMembership => dispatch(deleteMembership(groupMembership)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddGroupMemberButton);
