import axios from 'axios';
import { enqueueMessage } from './Alerts';
import {
  FETCH_TASKS_REQUEST, FETCH_TASKS_SUCCESS, FETCH_TASKS_FAILURE,
  CREATE_TASK_REQUEST, CREATE_TASK_SUCCESS, CREATE_TASK_FAILURE,
  UPDATE_TASK_REQUEST, UPDATE_TASK_SUCCESS, UPDATE_TASK_FAILURE,
  DELETE_TASK_REQUEST, DELETE_TASK_SUCCESS, DELETE_TASK_FAILURE,
} from './types';

import parseErrorMessage from '../utils/errorMessage';

export const fetchTasksRequest = () => ({
  type: FETCH_TASKS_REQUEST,
});

export const fetchTasksSuccess = tasks => ({
  type: FETCH_TASKS_SUCCESS,
  payload: tasks,
});

export const fetchTasksFailure = data => ({
  type: FETCH_TASKS_FAILURE,
  payload: {
    error: data.message,
  },
});

export const fetchTasks = contentId => (dispatch) => {
  dispatch(fetchTasksRequest());

  return axios.get(`contents/${contentId}/tasks`)
    .then((response) => {
      dispatch(fetchTasksSuccess(response.data));
      return response.data;
    })
    .catch((error) => {
      dispatch(fetchTasksFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
    });
};

export const createTaskRequest = () => ({
  type: CREATE_TASK_REQUEST,
});

export const createTaskSuccess = task => ({
  type: CREATE_TASK_SUCCESS,
  payload: task,
});

export const createTaskFailure = data => ({
  type: CREATE_TASK_FAILURE,
  payload: {
    error: data.message,
  },
});

export const createTask = (contentId, body) => (dispatch) => {
  dispatch(createTaskRequest());

  return axios.post(`contents/${contentId}/tasks`, { body })
    .then((response) => {
      dispatch(createTaskSuccess(response.data));
      dispatch(enqueueMessage('Task Added Successfully', 'success'));
      return response.data;
    })
    .catch((error) => {
      dispatch(createTaskFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
    });
};

export const updateTaskRequest = () => ({
  type: UPDATE_TASK_REQUEST,
});

export const updateTaskSuccess = task => ({
  type: UPDATE_TASK_SUCCESS,
  payload: task,
});

export const updateTaskFailure = data => ({
  type: UPDATE_TASK_FAILURE,
  payload: {
    error: data.message,
  },
});

export const updateTask = task => (dispatch) => {
  dispatch(updateTaskRequest());

  return axios.put(`/tasks/${task.id}`, task)
    .then((response) => {
      dispatch(updateTaskSuccess(response.data));
      dispatch(enqueueMessage('Task Updated Successfully', 'success'));
      return response.data;
    })
    .catch((error) => {
      dispatch(updateTaskFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
    });
};

export const deleteTaskRequest = () => ({
  type: DELETE_TASK_REQUEST,
});

export const deleteTaskSuccess = task => ({
  type: DELETE_TASK_SUCCESS,
  payload: task,
});

export const deleteTaskFailure = data => ({
  type: DELETE_TASK_FAILURE,
  payload: {
    error: data.message,
  },
});

export const deleteTask = task => (dispatch) => {
  dispatch(deleteTaskRequest());

  return axios.delete(`/tasks/${task.id}`)
    .then(() => {
      dispatch(deleteTaskSuccess(task));
      dispatch(enqueueMessage('Task Deleted Successfully', 'success'));
      return task.id;
    })
    .catch((error) => {
      dispatch(deleteTaskFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
    });
};
