import axios from 'axios';
import { enqueueMessage } from './Alerts';
import {
  FETCH_PAYMENT_METHODS_REQUEST,
  FETCH_PAYMENT_METHODS_SUCCESS,
  FETCH_PAYMENT_METHODS_FAILURE,
} from './types';

import parseErrorMessage from '../utils/errorMessage';

export const fetchPaymentMethodsRequest = () => ({
  type: FETCH_PAYMENT_METHODS_REQUEST,
});

export const fetchPaymentMethodsSuccess = paymentMethod => ({
  type: FETCH_PAYMENT_METHODS_SUCCESS,
  payload: paymentMethod,
});

export const fetchPaymentMethodsFailure = data => ({
  type: FETCH_PAYMENT_METHODS_FAILURE,
  payload: {
    error: data.message,
  },
});

export const fetchPaymentMethods = () => (dispatch) => {
  dispatch(fetchPaymentMethodsRequest());

  return axios
    .get('/payment_methods')
    .then(response => dispatch(fetchPaymentMethodsSuccess(response.data)))
    .catch((error) => {
      dispatch(fetchPaymentMethodsFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
    });
};
