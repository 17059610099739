import React from 'react';

import {
  Hidden,
  Typography,
  Button,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
  MIXPANEL_CLICKED_ON_ADD_LINK, MIXPANEL_CLICKED_ON_ADD_MEMO, MIXPANEL_CLICKED_ON_ADD_FILE, MIXPANEL_CLICKED_ON_ADD_LIST,
} from '../../../../utils/mixpanel/events';
import MixpanelClient from '../../../../utils/mixpanel/client';

const AddContentsActionBar = () => {
  const trackAddLinkClick = () => MixpanelClient.track(MIXPANEL_CLICKED_ON_ADD_LINK);
  const trackAddMemoClick = () => MixpanelClient.track(MIXPANEL_CLICKED_ON_ADD_MEMO);
  const trackAddFileClick = () => MixpanelClient.track(MIXPANEL_CLICKED_ON_ADD_FILE);
  const trackAddListClick = () => MixpanelClient.track(MIXPANEL_CLICKED_ON_ADD_LIST);

  return (
    <>
      <Button
        variant="contained"
        size="small"
        aria-label="add-link"
        component={Link}
        to="/contents/new?type=link"
        onClick={trackAddLinkClick}
        className="btn-primary btn-gradient shadow-none bg-night-sky"
      >
        <Hidden implementation="css" mdUp>
          <i className="fas fa-link" />
        </Hidden>
        <Hidden implementation="css" smDown>
        <i className="fas fa-plus-circle" />
          <Typography variant="button" className="ml-1">Link</Typography>
        </Hidden>
      </Button>

      <Button
        variant="contained"
        aria-label="add-note"
        size="small"
        component={Link}
        to="/contents/new?type=memo"
        onClick={trackAddMemoClick}
        className="btn-primary btn-gradient shadow-none bg-night-sky ml-2"
      >
        <Hidden implementation="css" mdUp>
          <i className="far fa-sticky-note" />
        </Hidden>

        <Hidden implementation="css" smDown>
          <i className="fas fa-plus-circle" />
          <Typography variant="button" className="ml-1">Note</Typography>
        </Hidden>
      </Button>

      <Button
        variant="contained"
        aria-label="add-file"
        size="small"
        component={Link}
        to="/contents/new?type=file"
        onClick={trackAddFileClick}
        className="btn-primary btn-gradient shadow-none bg-night-sky ml-2"
      >
        <Hidden implementation="css" mdUp>
          <i className="fas fa-file-upload" />
        </Hidden>
        <Hidden implementation="css" smDown>
          <i className="fas fa-plus-circle" />
          <Typography variant="button" className="ml-1">File</Typography>
        </Hidden>
      </Button>
      <Button
        aria-label="add"
        size="small"
        variant="contained"
        className="ml-4 btn-primary btn-gradient shadow-none bg-night-sky"
        component={Link}
        to="/lists/new"
        onClick={trackAddListClick}
      >
        <Hidden implementation="css" mdUp>
          <i className="fas fa-list" />
        </Hidden>
        <Hidden implementation="css" smDown>
          <i className="fas fa-plus-circle" />
          <Typography variant="button" className="ml-1">List</Typography>
        </Hidden>
      </Button>
    </>
  );
};

export default AddContentsActionBar;
