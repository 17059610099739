import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button/Button';
import Grid from '@material-ui/core/Grid/Grid';
import SendIcon from '@material-ui/icons/Send';
import { EditorState, convertToRaw } from 'draft-js';
import DraftEditor from '../../UI/DraftEditor';
import { createComment } from '../../../actions/Comments';

class CommentEditor extends React.Component {
  state = {
    editorState: EditorState.createEmpty(),
  };

  clearEditor = () => this.setState({ editorState: EditorState.createEmpty() });

  onChange = editorState => this.setState({ editorState });

  sumbitHander = () => {
    const { contentId, createCommentAction } = this.props;
    const { editorState } = this.state;
    const bodyJsonString = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    createCommentAction(contentId, bodyJsonString).then(() => this.clearEditor());
  };

  render() {
    const { editorState } = this.state;

    return (
      <Grid container direction="column">
        <DraftEditor editorState={editorState} onChange={this.onChange} />
        <hr />
        <Grid item xs>
          <Button variant="contained" color="primary" onClick={this.sumbitHander}>
            Add Comment
            <SendIcon />
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  createCommentAction: (contentId, bodySON) => dispatch(createComment(contentId, bodySON)),
});

export default connect(
  null,
  mapDispatchToProps,
)(CommentEditor);
