import axios from 'axios';
import { ISELO_API_HOST_DEV, ISELO_API_HOST_PROD } from '../Constants';

export default function initialize() {
  axios.defaults.withCredentials = true;
  const apiHost = process.env.NODE_ENV === 'development' ? ISELO_API_HOST_DEV : ISELO_API_HOST_PROD;
  axios.defaults.baseURL = `${apiHost}/api`;

  axios.interceptors.response.use(response => response, (xhr) => {
    if (xhr.response && xhr.response.status === 401) {
      window.location = `${apiHost}/users/sign_in`;
    }

    return Promise.reject(xhr);
  });
}
