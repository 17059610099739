import MixpanelClient from './client';
import { MIXPANEL_ADDED_CONTENT, MIXPANEL_UPDATED_CONTENT, MIXPANEL_REMOVED_CONTENT } from './events';

// responseStatus can be 'success' OR 'failure'
const trackContentEvent = (eventNameConstant, content, responseStatus, errorMessage) => {
  MixpanelClient.track(eventNameConstant, {
    'Content Title': content?.title,
    'Response Status': responseStatus,
    'Response Error Message': errorMessage,
  });
}

export const trackAddedContent = (content, responseStatus = 'success', errorMessage = null) => {
  trackContentEvent(MIXPANEL_ADDED_CONTENT, content, responseStatus, errorMessage);
};

export const trackUpdatedContent = (content, responseStatus = 'success', errorMessage = null) => {
  trackContentEvent(MIXPANEL_UPDATED_CONTENT, content, responseStatus, errorMessage);
};

export const trackRemovedContent = (content, responseStatus = 'success', errorMessage = null) => {
  trackContentEvent(MIXPANEL_REMOVED_CONTENT, content, responseStatus, errorMessage);
};
