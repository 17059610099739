import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  Typography,
  Grid,
  CardActions,
  Card,
  CardContent,
  Box,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
} from '@material-ui/core';
import setValue from 'lodash/set';
// import PageTitle from '../UI/PageTitle';
import { createList, updateList, deleteList } from '../../actions/Lists';
import { getGroups, getSelectedGroup } from '../../reducers/Groups';
import { USER, GROUP, ORGANIZATION } from '../../Constants';
import { isGroup, isOrganization, isUser } from '../../utils/listOwnerTypes';
import { getCurrentOwnerId, getCurrentOwnerType } from '../../reducers/CurrentOwner';
import MiniHeader from '../NewDashboard/dashboard-components/MiniHeader';

const styles = theme => ({
  root: {
    minHeight: theme.spacing(20),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  groupSelect: {
    // width: theme.spacing(50),
    padding: theme.spacing(2, 0),
  },
  card: {
    maxWidth: theme.spacing(80),
    minWidth: theme.spacing(40),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class ListForm extends React.Component {
  state = {
    list: {},
  }

  componentDidMount() {
    const { match, currentOwnerType, currentOwnerId } = this.props;
    if (match.params.listId) {
      axios.get(`/lists/${match.params.listId}`).then((response) => {
        const list = response.data;
        this.setState({ list });
      });
    } else {
      const list = {};
      list.owner_type = currentOwnerType || USER;
      if (isGroup(currentOwnerType)) list.owner_id = currentOwnerId;
      this.setState({ list });
    }
  }

  handleChange = (e) => {
    const { target } = e;
    const { list } = this.state;
    setValue(list, target.name, target.value);
    this.setState({ list });
  };

  handleGroupChange = (e) => {
    const { groups } = this.props;
    const { list } = this.state;
    const group = groups.find(x => x.id === e.target.value);
    list.owner_id = (group && group.id) || null;
    this.setState({ list });
  };

  handleDelete = () => {
    const { deleteListAction } = this.props;
    const { list } = this.state;
    deleteListAction(list).then(() => this.redirectToOwnerPage(list));
  };

  redirectBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  redirectToOwnerPage = (list) => {
    const { history } = this.props;
    let destination = '/dashboard/private';
    if (isUser(list.owner_type)) {
      destination = '/dashboard/private';
    } else if (isGroup(list.owner_type)) {
      destination = `/dashboard/groups/${list.owner_id}`;
    } else if (isOrganization(list.owner_type)) {
      destination = '/dashboard/organization';
    }

    history.replace(destination);
  };

  redirectToListPage = (list) => {
    const { history } = this.props;
    let destination = '/dashboard/private';
    if (isUser(list.owner_type)) {
      destination = `/dashboard/private/lists/${list.id}`;
    } else if (isGroup(list.owner_type)) {
      destination = `/dashboard/groups/${list.owner_id}/lists/${list.id}`;
    } else if (isOrganization(list.owner_type)) {
      destination = `/dashboard/organization/lists/${list.id}`;
    }

    history.replace(destination);
  };

  handleSave = (e) => {
    e.stopPropagation();
    const { createListAction, updateListAction } = this.props;

    const { list } = this.state;

    const action = list.id ? updateListAction : createListAction;
    action(list).then(response => response && response.id && this.redirectToListPage(response));
  };

  render() {
    const {
      groups, classes, currentOwnerType, currentOwnerId,
    } = this.props;
    const { list } = this.state;

    return (
      <>
        <MiniHeader>
          <Typography variant="h6">{list.id ? 'Edit List' : 'Create New List'}</Typography>
        </MiniHeader>
        <Grid container justify="center" direction="column" alignContent="center" className="m-5">
          {/* <Typography variant="h6">{list.id ? 'Edit List' : 'New List'}</Typography> */}
          <Card className={classes.card}>
            <CardContent>
              <Grid item container direction="column" spacing={0} justify="center">
                <TextField
                  required
                  autoFocus
                  margin="dense"
                  fullWidth
                  variant="outlined"
                  label="Name"
                  name="name"
                  value={list.name || ''}
                  onChange={this.handleChange}
                />

                {!list.id && (
                <FormControl component="fieldset">
                  <FormLabel component="legend">Visibility</FormLabel>
                  <RadioGroup row aria-label="owner_type" name="owner_type" value={list.owner_type || USER} onChange={this.handleChange}>
                    <Grid item container justify="space-around">
                      <FormControlLabel value={USER} control={<Radio color="primary" />} label="Private" />
                      {groups.length > 0 && <FormControlLabel value={GROUP} control={<Radio color="primary" />} label="Group" />}
                      <FormControlLabel value={ORGANIZATION} control={<Radio color="primary" />} label="Organization" />
                    </Grid>
                  </RadioGroup>
                </FormControl>
                )}
                {!list.id && isGroup(list.owner_type) && (
                <FormControl variant="outlined" className={classes.groupSelect}>
                  <InputLabel id="group-select-label">Select Group</InputLabel>
                  <Select
                    labelId="group-select-label"
                    id="group-select"
                    value={
                      list.owner_id || (isGroup(currentOwnerType) && currentOwnerId) || '0'
                    }
                    name="Group"
                    onChange={this.handleGroupChange}
                  >
                    {groups.map(group => (
                      <MenuItem value={group.id} key={group.id}>
                        <i className="fa fa-users" fontSize="small" />
                        &nbsp;
                        <Typography variant="inherit">{group.name}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                )}
              </Grid>
            </CardContent>
            <hr />
            <CardActions>
              <Grid container>
                {list.id && (
                <Button color="secondary" onClick={() => window.confirm('Are you sure you wish to delete the List?') && this.handleDelete()}>
                  <i className="fa fa-trash-alt" />
                  &nbsp; Delete
                </Button>
                )}

                <Box flexGrow={1} />

                {/* <Button variant="contained" color="primary" onClick={this.handleSave} className={classes.button} disabled={!list.name}> */}
                <Button variant="contained" color="primary" onClick={this.handleSave} className={classes.button}>
                  <i className="fa fa-plus-circle" />
                &nbsp; Save
                </Button>

                <Button color="primary" onClick={this.redirectBack} className={classes.button}>Cancel</Button>
              </Grid>
            </CardActions>
            {/* <div><pre>{JSON.stringify(this.state.list, null, 2) }</pre></div> */}
          </Card>
        </Grid>
      </>
    );
  }
}

ListForm.defaultProps = {
  list: {},
  selectedGroup: null,
};

ListForm.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  list: PropTypes.shape({}),
  createListAction: PropTypes.func.isRequired,
  updateListAction: PropTypes.func.isRequired,
  currentOwnerType: PropTypes.string.isRequired,
  currentOwnerId: PropTypes.string.isRequired,
  deleteListAction: PropTypes.func.isRequired,
};

const matchStateToProps = state => ({
  selectedGroup: getSelectedGroup(state),
  currentOwnerType: getCurrentOwnerType(state),
  currentOwnerId: getCurrentOwnerId(state),
  groups: getGroups(state),
});

const mapDispatchToProps = dispatch => ({
  createListAction: list => dispatch(createList(list)),
  updateListAction: list => dispatch(updateList(list)),
  deleteListAction: list => dispatch(deleteList(list)),
});

export default connect(
  matchStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(ListForm)));
