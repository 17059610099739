import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ArrowBack from '@material-ui/icons/ArrowBack';
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Typography,
  Grid,
  Button,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { fetchMemberships, deleteMembership } from '../../actions/Memberships';
import AddGroupMemberButton from './AddGroupMemberButton';
import Gravatar from '../UI/Gravatar';

const styles = () => ({
  root: {
    displace: 'flex',
    minWidth: '450px',
  },
});


class Memberships extends Component {
  componentDidMount() {
    const { match } = this.props;
    const { getMemberships } = this.props;
    getMemberships(match.params.groupId);
  }

  redirectToGroups = () => {
    const { history } = this.props;
    history.replace('/groups');
  };

  handleDelete = (membership) => {
    const { deleteMembershipAction } = this.props;
    deleteMembershipAction(membership);
  };

  render() {
    const { match, memberships, classes } = this.props;
    const { groupId } = match.params;
    return (
      <div className={classes.root}>
        <Button size="small" color="primary" onClick={this.redirectToGroups}>
          <ArrowBack />
          All Groups
        </Button>
        <Grid container direction="row" justify="space-between">
          <Grid item>
            <Typography variant="h5">Group Members</Typography>
          </Grid>
          <Grid item>
            <AddGroupMemberButton groupId={groupId}>Add a new group member</AddGroupMemberButton>
          </Grid>
        </Grid>
        <List className={classes.list}>
          {memberships.map(membership => (
            <ListItem key={membership.id}>
              <ListItemAvatar>
                <Gravatar email={membership.user.email} />
              </ListItemAvatar>
              <ListItemText
                primary={membership.user.name}
                secondary={membership.user.email ? membership.user.email : null}
              />
              <ListItemSecondaryAction>
                <Button color="secondary" onClick={() => window.confirm('Are you sure you wish to remove the member from the group?') && this.handleDelete(membership)}>
                  Remove
                  <DeleteIcon />
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    );
  }
}
Memberships.propTypes = {
  match: PropTypes.shape({}).isRequired,
  memberships: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getMemberships: PropTypes.func.isRequired,
  deleteMembershipAction: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  memberships: state.Memberships.data,
});

const mapDispatchToProps = dispatch => ({
  getMemberships: groupId => dispatch(fetchMemberships(groupId)),
  deleteMembershipAction: membership => dispatch(deleteMembership(membership)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Memberships));
