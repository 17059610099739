const parseErrorMessage = (error) => {
  let message = null;
  try {
    message = error.response.data.message;
  } catch (err) {
    message = error.message;
  }
  return message;
}

export default parseErrorMessage;
