import React from 'react';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { Link } from '@material-ui/core';
import { connect } from 'react-redux';
import CustomerPortalButton from '../CustomerPortalButton';
import { getOrganization } from '../../../reducers/Organization';
import { getCurrentUser } from '../../../reducers/CurrentUser';

const InsufficientUserSubscription = ({ currentUser, organization }) => (
  <Alert severity="error">
    <AlertTitle>Number of users exceed than the subscription permits!</AlertTitle>
    <span>
      <p>{`You have a subscription for ${organization.max_users} user(s). But you have ${organization.user_count} users in your organization.`}</p>
      {currentUser && currentUser.organization_role === 'admin' && (
        <>
          <p>
          Either remove some users from the organization or update the subscription to the required number of users! Please
          feel free to contact us at
            {' '}
            <Link href="mailto:support@iseloapp.com">support@iseloapp.com</Link>
          </p>
          <CustomerPortalButton />
        </>
      )}
    </span>
  </Alert>
);

const mapStateToProps = state => ({
  organization: getOrganization(state),
  currentUser: getCurrentUser(state),
});

export default connect(mapStateToProps)(InsufficientUserSubscription);
