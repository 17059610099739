import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, TextField } from '@material-ui/core';
import { createGroup, updateGroup, deleteGroup } from '../../actions/Groups';

import AddModal from '../AddModal';

class AddGroupButton extends React.Component {
  state = {
    open: false,
    name: '',
  };

  constructor(props) {
    super(props);
    const { group } = props;
    this.state = { ...this.state, name: group.name };
  }

  handleOpen = (e) => {
    e.stopPropagation();
    this.setState({ open: true });
  };

  handleClose = (e) => {
    e.stopPropagation();
    this.setState({ open: false });
  };

  handleDelete = (e) => {
    e.stopPropagation();
    const { group, deleteGroupAction } = this.props;
    deleteGroupAction(group).then(() => { this.setState({ open: false }); });
  };

  handleSave = (e) => {
    e.stopPropagation();
    const { group, createGroupAction, updateGroupAction } = this.props;
    const { name } = this.state;

    if (!group.id) {
      createGroupAction(name).then(() => { this.setState({ open: false }); });
    } else {
      updateGroupAction({ ...group, name }).then(() => { this.setState({ open: false }); });
    }
  };

  onChange= (e) => {
    this.setState({ name: e.target.value });
  };

  render() {
    const { className, children, group } = this.props;
    const { open, name } = this.state;

    return (
      <Button
        variant="contained"
        color="primary"
        className={className}
        role="button"
        tabIndex="-1"
        onClick={this.handleOpen}
        onKeyDown={() => {}}
      >
        {children}
        <AddModal
          open={open}
          onClose={this.handleClose}
          onSave={this.handleSave}
          onDelete={this.handleDelete}
          heading={`${group.id ? 'Create' : 'Edit'} Group`}
          showDelete={!!group.id}
        >
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Group name*"
            fullWidth
            value={name}
            onChange={this.onChange}
          />
        </AddModal>
      </Button>
    );
  }
}

AddGroupButton.defaultProps = {
  group: {},
  className: '',
};

AddGroupButton.propTypes = {
  className: PropTypes.string,
  group: PropTypes.shape({
    id: PropTypes.number,
  }),
  createGroupAction: PropTypes.func.isRequired,
  updateGroupAction: PropTypes.func.isRequired,
  deleteGroupAction: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
};

const mapDispatchToProps = dispatch => ({
  createGroupAction: name => dispatch(createGroup(name)),
  updateGroupAction: group => dispatch(updateGroup(group)),
  deleteGroupAction: group => dispatch(deleteGroup(group)),
});

export default connect(null, mapDispatchToProps)(AddGroupButton);
