import React from 'react';
import classNames from 'classnames';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import {
  setSidebarToggle,
  setSidebarToggleMobile,
} from '../../../../reducers/ThemeOptions';

import projectLogo from '../../../../assets/images/logo/iselo_logo_white_xs.png';

const useStyles = makeStyles(theme => ({
  logo: {
    width: '100px',
    [theme.breakpoints.down('xs')]: {
      width: '100px',
    },
  },
}));

const SidebarHeader = (props) => {
  const {
    sidebarToggleMobile,
    setSidebarToggleMobile,

    sidebarToggle,
    setSidebarToggle,
  } = props;
  const classes = useStyles();

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  return (
    <>
      <div className="app-sidebar--header">
        <div className="app-sidebar-logo">
          <NavLink
            to="/"
            title="Bamburgh React Admin Dashboard with Material-UI PRO"
            className="app-sidebar-logo"
          >
            {/* <div className="app-sidebar-logo--icon"> */}
            <img
              alt="iselo"
              src={projectLogo}
              width="100px"
              className={classes.logo}
            />
            {/* </div> */}
          </NavLink>
        </div>
        {/* <Tooltip title="Collapse sidebar" placement="right" arrow>
          <Button
            onClick={toggleSidebar}
            className="btn btn-sm collapse-sidebar-btn"
          >
            <i className="far fa-dot-circle" style={{ fontSize: 20 }} />
          </Button>
        </Tooltip> */}
        <Button
          className={classNames(
            'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
            { 'is-active': sidebarToggleMobile },
          )}
          onClick={toggleSidebarMobile}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </Button>
        {/* <Tooltip title="Expand sidebar" placement="right" arrow>
          <Button
            onClick={toggleSidebar}
            className="expand-sidebar-btn btn btn-sm"
          >
            <i className="fas fa-arrows-alt-h" />
          </Button>
        </Tooltip> */}
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggle: enable => dispatch(setSidebarToggle(enable)),
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarHeader);
