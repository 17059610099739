import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  CardContent,
  Link,
  CardActions,
} from '@material-ui/core';
import { Editor, EditorState, convertFromRaw } from 'draft-js';
import CompletedByUsersPopover from './CompletedByUsersPopover';
import { updateTask, deleteTask } from '../../actions/Tasks';
import { updateLink, deleteLink } from '../../actions/Links';
import { toggleStarred } from '../../actions/Contents';
import ContentEditButton from '../ContentCardActions/ContentEditButton';
import ContentShareButton from '../ContentCardActions/ContentShareButton';
import CommentsButton from '../ContentCardActions/CommentsButton';
import ContentArchiveButton from '../ContentCardActions/ContentArchiveButton';
import ContentDeleteButton from '../ContentCardActions/ContentDeleteButton';

const styles = theme => ({
  details: {
    display: 'flex',
    flexFlow: 'column',
  },
  title: {
    margin: 0,
    '& > a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      size: '14px',
    },
  },
  content: {
    padding: '0',
  },
  card: {
    // height: '100%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    maxWidth: theme.spacing(60),
  },
  cardHeader: {
    paddingBottom: 0,
  },
  media: {
    height: '250px',
  },
  favicon: {
    maxWidth: theme.spacing(4),
    maxheight: theme.spacing(4),
  },
});

const ContentCard = (props) => {
  let descriptionEditorState = EditorState.createEmpty();
  const { hit, classes, showActions } = props;
  let { content } = props;
  if (hit) content = hit;

  if (content.memo !== null) {
    try {
      const descriptionState = convertFromRaw(JSON.parse(content.description));
      descriptionEditorState = EditorState.createWithContent(descriptionState);
    } catch (e) {
      descriptionEditorState = EditorState.createEmpty();
    }
  }

  const contentUrl = () => {
    if (content.links && content.links.length === 1) return content.links[0].url;
    if (content.files && content.files.length === 1) return content.files[0].path;
    if (content.one_drive_files && content.one_drive_files.length === 1) return content.one_drive_files[0].web_url;
    return null;
  };

  return (
    <Grid>
      <Grid className={classNames('card card-box-hover-rise bg-white', classes.card)}>
        <Link
          href={contentUrl(content)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {content.created_at > (new Date().getTime() - (7 * 24 * 60 * 60 * 1000)) / 1000 && (
            <span className="ribbon-angle ribbon-angle--top-right ribbon-warning"><small>New</small></span>
          )}

          {Array.isArray(content.archived_by_users) && (content.archived_by_users.length > 0) && (
            <div className="card-badges">
              <CompletedByUsersPopover users={content.archived_by_users} />
            </div>
          )}
          {/* <div className="card-badges"> */}
          {/* <div className="badge badge-pill badge-success">{content.archived_at}</div> */}
          {/* {content.archived_at && (<div className="badge badge-pill badge-warning">Completed</div>)} */}
          {/* </div> */}
          {content.image && (
            <img alt="..." className="card-img-top" src={content.image} />
          )}

          <CardContent className={classNames('px-4 pb-4', content.favicon_image ? 'card-body-avatar' : '')}>
            {content.favicon_image && (
              <div className="avatar-icon-wrapper rounded-pill border-white border-3 avatar-icon-wrapper--sm">
                <div className="avatar-icon rounded-pill">
                  <img
                    src={content.favicon_image}
                    className={classes.favicon}
                    alt=""
                  />
                </div>
              </div>
            )}
            <h5 className="card-title font-size-xl text-black">
              {content.title}
            </h5>
            {descriptionEditorState.getCurrentContent().hasText() && (
              <div className="card-text">
                <Editor editorState={descriptionEditorState} readOnly />
              </div>
            )}
            <div className="card-date text-black-50 mt-2">
              <i className="mr-1 far fa-clock" />
              <small>{`Added ${Moment(content.created_at * 1000).fromNow()} by ${(content.user && content.user.name) || (content.creator && content.creator.name)}`}</small>
            </div>
            {content.tags.length > 0 && (
              <div className="d-flex align-items-flex-end justify-content-flex-end flex-wrap">
                {content.tags.map(tag => <div key={tag} className="m-1 text-second badge badge-neutral-second">{tag}</div>)}
                {' '}
                &nbsp;
                {/* {content.tags.map(tag => <div className="badge badge-neutral-primary badge-pill m-1 text-primary">{tag}</div>)} &nbsp; */}
                {/* {content.tags.map(tag => <div className="m-1 text-second badge badge-neutral-second">{tag}</div>)} &nbsp; */}
              </div>
            )}
            {/* <div className="card-badges"> */}
            {/* <div className="badge badge-pill badge-success">{content.archived_at}</div> */}
            {/* {content.archived_at && (<div className="badge badge-pill badge-warning">Completed</div>)} */}
            {/* {content.tags.map(tag => <div className="badge badge-pill badge-warning">{tag}</div>)} &nbsp; */}
            {/* </div> */}

          </CardContent>
        </Link>

        <CardActions disableSpacing>
          <ContentEditButton content={content} iconOnly />
          {/* <ContentShareButton content={content} iconOnly /> */}
          {/* <CommentsButton content={content} iconOnly /> */}
          <ContentArchiveButton content={content} iconOnly />
          <ContentDeleteButton content={content} iconOnly />
        </CardActions>
      </Grid>
    </Grid>

  // <Card className={classes.card}>

  //   {content.image && (
  //     <CardMedia
  //       className={classes.media}
  //       image={content.image}
  //       title={content.title}
  //     />
  //   )}
  //   <CardHeader
  //     className={classes.cardHeader}
  //     avatar={(
  //       <Tooltip title="Memo" placement="left" enterDelay={1000}>
  //         {content.favicon_image ? (
  //           <img
  //             src={content.favicon_image}
  //             className={classes.favicon}
  //             alt=""
  //           />
  //         ) : (
  //           <i className="far fa-file-alt" style={{ fontSize: 25 }} />
  //         )}
  //       </Tooltip>
  //     )}
  //     action={Array.isArray(content.archived_by_users) && (content.archived_by_users.length > 0) && (
  //       <CompletedByUsersPopover users={content.archived_by_users} />
  //     )}
  //     title={(
  //       <Grid>
  //         <ContentCardTitle content={content} className={classes.title} />
  //         &nbsp;
  //         {content.archived_at && (
  //           <Chip size="small" label="Completed" />
  //         )}
  //       </Grid>
  //     )}
  //     subheader={`Added ${Moment(content.created_at * 1000).fromNow()} by ${
  //       content.user.name
  //     }`}
  //   />
  //   <CardContent className={classes.content}>
  //     {descriptionEditorState.getCurrentContent().hasText() && (
  //       <Box margin={2}>
  //         <Editor editorState={descriptionEditorState} readOnly />
  //       </Box>
  //     )}
  //   </CardContent>
  // </Card>
  );
};

const mapDispatchToProps = dispatch => ({
  updateTaskAction: task => dispatch(updateTask(task)),
  deleteTaskAction: task => dispatch(deleteTask(task)),
  updateLinkAction: link => dispatch(updateLink(link)),
  deleteLinkAction: link => dispatch(deleteLink(link)),
  toggleStarredAction: content => dispatch(toggleStarred(content)),
});

ContentCard.defaultProps = {
  showActions: true,
  content: {},
};

ContentCard.propTypes = {
  content: PropTypes.shape({}),
  classes: PropTypes.shape({}).isRequired,
  showActions: PropTypes.bool,
};

export default connect(
  null,
  mapDispatchToProps,
)(withStyles(styles)(ContentCard));
