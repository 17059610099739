import {
  SEARCH_TEXT_CHANGED,
  TAGS_FILTER_CHANGED,
  RESET_FILTERS,
  SORT_BY_CHANGED,
  SHOW_ONLY_STARRED_CHANGED,
  SHOW_WITH_ARCHIVED_CHANGED,
} from '../actions/types';

const initialState = {
  searchText: '',
  selectedTags: [],
  sortBy: 'created_at:desc',
  showOnlyStarred: false,
  showWithArchived: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_TEXT_CHANGED:
      return {
        ...state,
        searchText: action.payload,
      };
    case TAGS_FILTER_CHANGED:
      return { ...state, selectedTags: action.payload };

    case SORT_BY_CHANGED:
      return { ...state, sortBy: action.payload };
    case SHOW_ONLY_STARRED_CHANGED:
      return { ...state, showOnlyStarred: action.payload };
    case SHOW_WITH_ARCHIVED_CHANGED:
      return { ...state, showWithArchived: action.payload };
    case RESET_FILTERS:
      return { ...state, ...initialState };
    default:
      return state;
  }
};
