import { createSelector } from 'reselect';
import {
  FETCH_GROUPS_REQUEST,
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUPS_FAILURE,
  CREATE_GROUP_REQUEST,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAILURE,
  UPDATE_GROUP_REQUEST,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAILURE,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAILURE,
  CREATE_LIST_REQUEST,
  CREATE_LIST_SUCCESS,
  CREATE_LIST_FAILURE,
  UPDATE_LIST_REQUEST,
  UPDATE_LIST_SUCCESS,
  UPDATE_LIST_FAILURE,
  DELETE_LIST_REQUEST,
  DELETE_LIST_SUCCESS,
  DELETE_LIST_FAILURE,
  SELECT_GROUP,
} from '../actions/types';

import { isGroup } from '../utils/listOwnerTypes';

import ListReducer from './Lists';

const initialState = { data: [] };
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GROUPS_REQUEST:
      return {
        ...state,
        data: [],
        error: undefined,
        loading: true,
      };
    case FETCH_GROUPS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_GROUPS_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        data: [],
        error,
        loading: false,
      };
    }
    case CREATE_GROUP_REQUEST:
      return { ...state, error: undefined, loading: true };
    case CREATE_GROUP_SUCCESS:
      return { ...state, data: [...state.data, action.payload], loading: false };
    case CREATE_GROUP_FAILURE: {
      const { error } = action.payload;
      return { ...state, error, loading: false };
    }
    case UPDATE_GROUP_REQUEST:
      return { ...state, error: undefined, loading: true };
    case UPDATE_GROUP_SUCCESS: {
      const group = action.payload;
      const groupIndex = state.data.findIndex(x => x.id === group.id);
      const data = [...state.data];
      data.splice(groupIndex, 1, group);
      return { ...state, data, loading: false };
    }
    case UPDATE_GROUP_FAILURE: {
      // return error and make loading = false
      const { error } = action.payload;
      return { ...state, error, loading: false };
    }
    case DELETE_GROUP_REQUEST:
      return { ...state, error: undefined, loading: true };
    case DELETE_GROUP_SUCCESS: {
      const groupId = action.payload;
      const groupIndex = state.data.findIndex(x => x.id === groupId);
      const data = [...state.data];
      data.splice(groupIndex, 1);
      return { ...state, data, loading: false };
    }
    case DELETE_GROUP_FAILURE: {
      const { error } = action.payload;
      return { ...state, error, loading: false };
    }
    case SELECT_GROUP: {
      return { ...state, selectedGroupId: action.payload };
    }
    case CREATE_LIST_REQUEST:
    case CREATE_LIST_SUCCESS:
    case CREATE_LIST_FAILURE:
    case UPDATE_LIST_REQUEST:
    case UPDATE_LIST_SUCCESS:
    case UPDATE_LIST_FAILURE:
    case DELETE_LIST_REQUEST:
    case DELETE_LIST_SUCCESS:
    case DELETE_LIST_FAILURE:
      if (isGroup(action.listOwnerType)) return ListReducer(state, action);
      return state;
    default:
      return state;
  }
};

// Selectors

export const getGroups = state => state.Groups.data;
export const getGroupsLoading = state => state.Groups.loading;
export const getSelectedGroupId = state => state.Groups.selectedGroupId;

export const getSelectedGroup = createSelector(
  getGroups,
  getSelectedGroupId,
  (groups, selectedGroupId) => groups.find(group => group.id === selectedGroupId),
);

export const getSelectedGroupTags = createSelector(
  getSelectedGroup,
  group => group.tags,
);

export const getSelectedGroupUsers = createSelector(
  getSelectedGroup,
  group => (group ? group.users : []),
);
