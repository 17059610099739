import React from 'react';
import PropTypes from 'prop-types';
import ShareIcon from '@material-ui/icons/Share';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton, Popover, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  FacebookShareButton, FacebookIcon,
  TwitterShareButton, TwitterIcon,
  WhatsappShareButton, WhatsappIcon,
  LinkedinShareButton, LinkedinIcon,
} from 'react-share';

const styles = () => ({
  shareItem: {
    display: 'flex',
    alignItems: 'center',
    '&>div': {
      marginRight: 10,
    },
  },
});

class ContentShareButton extends React.Component {
  state = {
    open: false,
  };

  handleOpen = (e) => {
    e.stopPropagation();
    this.setState({ open: true });
  };

  handleClose = (e) => {
    e.stopPropagation();
    this.setState({ open: false });
  };

  render() {
    const { content, classes } = this.props;
    const { open } = this.state;

    const { url, title, tags } = content;

    return (
      <div>
        <Tooltip title="Share" placement="bottom" enterDelay={1000}>
          <IconButton
            color="primary"
            size="small"
            className="m-2"
            aria-label="share"
            onClick={this.handleOpen}
            buttonRef={(node) => {
              this.anchorEl = node;
            }}
          >
            <ShareIcon />
          </IconButton>
        </Tooltip>
        <Popover
          open={open}
          anchorEl={this.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={this.handleClose}
          onClick={this.handleClose}
        >
          <MenuItem>
            <FacebookShareButton
              url={url}
              quote={title}
              className={classes.shareItem}
            >
              <FacebookIcon size={32} round />
              Facebook
            </FacebookShareButton>
          </MenuItem>
          <MenuItem>
            <TwitterShareButton
              url={url}
              title={title}
              hashtags={tags}
              className={classes.shareItem}
            >
              <TwitterIcon
                size={32}
                round
              />
              Twitter
            </TwitterShareButton>
          </MenuItem>
          <MenuItem>
            <LinkedinShareButton
              url={url}
              title={title}
              className={classes.shareItem}
            >
              <LinkedinIcon
                size={32}
                round
              />
              Linkedin
            </LinkedinShareButton>
          </MenuItem>
          <MenuItem>
            <WhatsappShareButton
              url={url}
              title={title}
              separator=":: "
              className={classes.shareItem}
            >
              <WhatsappIcon size={32} round />
              Whatsapp
            </WhatsappShareButton>
          </MenuItem>
        </Popover>
      </div>
    );
  }
}

ContentShareButton.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  content: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(ContentShareButton);
