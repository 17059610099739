// Authentication
export const LOG_OUT_REQUEST = 'LOG_OUT_REQUEST'; // DELETE /sessions/me
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const LOG_OUT_FAILURE = 'LOG_OUT_FAILURE';

// Users
export const FETCH_CURRENT_USER_REQUEST = 'FETCH_CURRENT_USER_REQUEST'; // GET /users/me
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS';
export const FETCH_CURRENT_USER_FAILURE = 'FETCH_CURRENT_USER_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST'; // DELETE /users/userId
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

// Organization
export const FETCH_ORGANIZATION = 'FETCH_ORGANIZATION'; // GET /organization
export const FETCH_ORGANIZATION_SUCCESS = 'FETCH_ORGANIZATION_SUCCESS';
export const FETCH_ORGANIZATION_FAILURE = 'FETCH_ORGANIZATION_FAILURE';

// Organization User Invitations
export const CREATE_INVITATION_REQUEST = 'CREATE_INVITATION_REQUEST'; // POST /organization/invitation (params includes email)
export const CREATE_INVITATION_SUCCESS = 'CREATE_INVITATION_SUCCESS';
export const CREATE_INVITATION_FAILURE = 'CREATE_INVITATION_FAILURE';

export const RESEND_INVITATION_REQUEST = 'RESEND_INVITATION_REQUEST'; // POST /organization/invitation (params includes user_id)
export const RESEND_INVITATION_SUCCESS = 'RESEND_INVITATION_SUCCESS';
export const RESEND_INVITATION_FAILURE = 'RESEND_INVITATION_FAILURE';

// Contents
export const FETCH_CONTENT_REQUEST = 'FETCH_CONTENT_REQUEST'; // GET /contents/:content_id
export const FETCH_CONTENT_SUCCESS = 'FETCH_CONTENT_SUCCESS';
export const FETCH_CONTENT_FAILURE = 'FETCH_CONTENT_FAILURE';

export const FETCH_CONTENTS_REQUEST = 'FETCH_CONTENTS_REQUEST'; // GET /contents
export const FETCH_CONTENTS_SUCCESS = 'FETCH_CONTENTS_SUCCESS';
export const FETCH_CONTENTS_FAILURE = 'FETCH_CONTENTS_FAILURE';

export const CREATE_CONTENT_REQUEST = 'CREATE_CONTENT_REQUEST'; // POST /contents
export const CREATE_CONTENT_SUCCESS = 'CREATE_CONTENT_SUCCESS';
export const CREATE_CONTENT_FAILURE = 'CREATE_CONTENT_FAILURE';

export const UPDATE_CONTENT_REQUEST = 'UPDATE_CONTENT_REQUEST'; // PUT /contents/:content_id
export const UPDATE_CONTENT_SUCCESS = 'UPDATE_CONTENT_SUCCESS';
export const UPDATE_CONTENT_FAILURE = 'UPDATE_CONTENT_FAILURE';

export const DELETE_CONTENT_REQUEST = 'DELETE_CONTENT_REQUEST'; // DELETE /contents/:content_id
export const DELETE_CONTENT_SUCCESS = 'DELETE_CONTENT_SUCCESS';
export const DELETE_CONTENT_FAILURE = 'DELETE_CONTENT_FAILURE';

export const TOGGLE_STARRED_REQUEST = 'TOGGLE_STARRED_REQUEST'; // POST /contents/:content_id/toggle_starred
export const TOGGLE_STARRED_SUCCESS = 'TOGGLE_STARRED_SUCCESS';
export const TOGGLE_STARRED_FAILURE = 'TOGGLE_STARRED_FAILURE';

export const UPDATE_CONTENT_USER_PROPERTIES_REQUEST = 'UPDATE_CONTENT_USER_PROPERTIES_REQUEST'; // PUT /contents/:content_id/content_user_properties
export const UPDATE_CONTENT_USER_PROPERTIES_SUCCESS = 'UPDATE_CONTENT_USER_PROPERTIES_SUCCESS';
export const UPDATE_CONTENT_USER_PROPERTIES_FAILURE = 'UPDATE_CONTENT_USER_PROPERTIES_FAILURE';

// lists
export const FETCH_LISTS_REQUEST = 'FETCH_LISTS_REQUEST'; // GET /lists
export const FETCH_LISTS_SUCCESS = 'FETCH_LISTS_SUCCESS';
export const FETCH_LISTS_FAILURE = 'FETCH_LISTS_FAILURE';

export const CREATE_LIST_REQUEST = 'CREATE_LIST_REQUEST'; // POST /lists
export const CREATE_LIST_SUCCESS = 'CREATE_LIST_SUCCESS';
export const CREATE_LIST_FAILURE = 'CREATE_LIST_FAILURE';

export const UPDATE_LIST_REQUEST = 'UPDATE_LIST_REQUEST'; // PUT /lists/:list_id
export const UPDATE_LIST_SUCCESS = 'UPDATE_LIST_SUCCESS';
export const UPDATE_LIST_FAILURE = 'UPDATE_LIST_FAILURE';

export const DELETE_LIST_REQUEST = 'DELETE_LIST_REQUEST'; // DELETE /lists/:list_id
export const DELETE_LIST_SUCCESS = 'DELETE_LIST_SUCCESS';
export const DELETE_LIST_FAILURE = 'DELETE_LIST_FAILURE';

export const SELECT_LIST = 'SELECT_LIST';

// Tags
export const FETCH_TAGS_REQUEST = 'FETCH_TAGS_REQUEST'; // GET /me/tags
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_FAILURE = 'FETCH_TAGS_FAILURE';

export const CREATE_TAG = 'CREATE_TAG'; // POST /tags
export const CREATE_TAG_SUCCESS = 'CREATE_TAG_SUCCESS';
export const CREATE_TAG_FAILURE = 'CREATE_TAG_FAILURE';

export const UPDATE_TAG = 'UPDATE_TAG'; // PUT /tags/:tag_id
export const UPDATE_TAG_SUCCESS = 'UPDATE_TAG_SUCCESS';
export const UPDATE_TAG_FAILURE = 'UPDATE_TAG_FAILURE';

export const DELETE_TAG = 'DELETE_TAG'; // DELETE /tags/:tag_id
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';
export const DELETE_TAG_FAILURE = 'DELETE_TAG_FAILURE';

export const SEARCH_TEXT_CHANGED = 'SEARCH_TEXT_CHANGED';
export const TAGS_FILTER_CHANGED = 'TAGS_FILTER_CHANGED';
export const RESET_FILTERS = 'RESET_FILTERS';
export const SORT_BY_CHANGED = 'SORT_BY_CHANGED';
export const SHOW_ONLY_STARRED_CHANGED = 'SHOW_ONLY_STARRED_CHANGED';
export const SHOW_WITH_ARCHIVED_CHANGED = 'SHOW_WITH_ARCHIVED_CHANGED';

// Groups
export const FETCH_GROUPS_REQUEST = 'FETCH_GROUPS_REQUEST'; // GET /groups
export const FETCH_GROUPS_SUCCESS = 'FETCH_GROUPS_SUCCESS';
export const FETCH_GROUPS_FAILURE = 'FETCH_GROUPS_FAILURE';

export const CREATE_GROUP_REQUEST = 'CREATE_GROUP_REQUEST'; // POST /groups
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAILURE = 'CREATE_GROUP_FAILURE';

export const UPDATE_GROUP_REQUEST = 'UPDATE_GROUP_REQUEST'; // PUT /groups/:group_id
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILURE = 'UPDATE_GROUP_FAILURE';

export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST'; // DELETE /groups/:group_id
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';

export const SELECT_GROUP = 'SELECT_GROUP';

// Group Memberships
export const FETCH_MEMBERSHIPS_REQUEST = 'FETCH_MEMBERSHIPS_REQUEST'; // GET /group_memberships
export const FETCH_MEMBERSHIPS_SUCCESS = 'FETCH_MEMBERSHIPS_SUCCESS';
export const FETCH_MEMBERSHIPS_FAILURE = 'FETCH_MEMBERSHIPS_FAILURE';

export const CREATE_MEMBERSHIP_REQUEST = 'CREATE_MEMBERSHIP_REQUEST'; // POST /group_memberships
export const CREATE_MEMBERSHIP_SUCCESS = 'CREATE_MEMBERSHIP_SUCCESS';
export const CREATE_MEMBERSHIP_FAILURE = 'CREATE_MEMBERSHIP_FAILURE';

export const DELETE_MEMBERSHIP_REQUEST = 'DELETE_MEMBERSHIP_REQUEST'; // DELETE /group_memberships/:group_membership_id
export const DELETE_MEMBERSHIP_SUCCESS = 'DELETE_MEMBERSHIP_SUCCESS';
export const DELETE_MEMBERSHIP_FAILURE = 'DELETE_MEMBERSHIP_FAILURE';

// Comments
export const FETCH_COMMENTS_REQUEST = 'FETCH_COMMENTS_REQUEST'; // GET /contents/:content_id/comments
export const FETCH_COMMENTS_SUCCESS = 'FETCH_COMMENTS_SUCCESS';
export const FETCH_COMMENTS_FAILURE = 'FETCH_COMMENTS_FAILURE';

export const CREATE_COMMENT_REQUEST = 'CREATE_COMMENT_REQUEST'; // POST /contents/:content_id/comments
export const CREATE_COMMENT_SUCCESS = 'CREATE_COMMENT_SUCCESS';
export const CREATE_COMMENT_FAILURE = 'CREATE_COMMENT_FAILURE';

export const UPDATE_COMMENT_REQUEST = 'UPDATE_COMMENT_REQUEST'; // PUT /contents/:content_id/comments/:comment_id
export const UPDATE_COMMENT_SUCCESS = 'UPDATE_COMMENT_SUCCESS';
export const UPDATE_COMMENT_FAILURE = 'UPDATE_COMMENT_FAILURE';

export const DELETE_COMMENT_REQUEST = 'DELETE_COMMENT_REQUEST'; // DELETE /contents/:content_id/comments/:comment_id
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_FAILURE = 'DELETE_COMMENT_FAILURE';

// Tasks
export const FETCH_TASKS_REQUEST = 'FETCH_TASKS_REQUEST'; // GET /contents/:content_id/tasks
export const FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS';
export const FETCH_TASKS_FAILURE = 'FETCH_TASKS_FAILURE';

export const CREATE_TASK_REQUEST = 'CREATE_TASK_REQUEST'; // POST /contents/:content_id/tasks
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';
export const CREATE_TASK_FAILURE = 'CREATE_TASK_FAILURE';

export const UPDATE_TASK_REQUEST = 'UPDATE_TASK_REQUEST'; // PUT /tasks/:task_id
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export const UPDATE_TASK_FAILURE = 'UPDATE_TASK_FAILURE';

export const DELETE_TASK_REQUEST = 'DELETE_TASK_REQUEST'; // DELETE /tasks/:task_id
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export const DELETE_TASK_FAILURE = 'DELETE_TASK_FAILURE';

// Links
export const FETCH_LINKS_REQUEST = 'FETCH_LINKS_REQUEST'; // GET /contents/:content_id/links
export const FETCH_LINKS_SUCCESS = 'FETCH_LINKS_SUCCESS';
export const FETCH_LINKS_FAILURE = 'FETCH_LINKS_FAILURE';

export const CREATE_LINK_REQUEST = 'CREATE_LINK_REQUEST'; // POST /contents/:content_id/links
export const CREATE_LINK_SUCCESS = 'CREATE_LINK_SUCCESS';
export const CREATE_LINK_FAILURE = 'CREATE_LINK_FAILURE';

export const UPDATE_LINK_REQUEST = 'UPDATE_LINK_REQUEST'; // PUT /links/:link_id
export const UPDATE_LINK_SUCCESS = 'UPDATE_LINK_SUCCESS';
export const UPDATE_LINK_FAILURE = 'UPDATE_LINK_FAILURE';

export const DELETE_LINK_REQUEST = 'DELETE_LINK_REQUEST'; // DELETE /links/:link_id
export const DELETE_LINK_SUCCESS = 'DELETE_LINK_SUCCESS';
export const DELETE_LINK_FAILURE = 'DELETE_LINK_FAILURE';

// Files
export const DELETE_FILE_REQUEST = 'DELETE_FILE_REQUEST'; // DELETE /files/:file_id
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_FAILURE = 'DELETE_FILE_FAILURE';

// Payment Methods
export const FETCH_PAYMENT_METHODS_REQUEST = 'FETCH_PAYMENT_METHODS_REQUEST'; // GET /payment_methods
export const FETCH_PAYMENT_METHODS_SUCCESS = 'FETCH_PAYMENT_METHODS_SUCCESS';
export const FETCH_PAYMENT_METHODS_FAILURE = 'FETCH_PAYMENT_METHODS_FAILURE';

// Notifications
export const FETCH_NOTIFICATIONS_REQUEST = 'FETCH_NOTIFICATIONS_REQUEST'; // GET /notifications
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE';

export const UPDATE_NOTIFICATION_REQUEST = 'UPDATE_NOTIFICATION_REQUEST'; // PUT /notifications/:notification_id
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
export const UPDATE_NOTIFICATION_FAILURE = 'UPDATE_NOTIFICATION_FAILURE';

export const DELETE_NOTIFICATION_REQUEST = 'DELETE_NOTIFICATION_REQUEST'; // DELETE /notifications/:notification_id
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_FAILURE = 'DELETE_NOTIFICATION_FAILURE';

export const SET_ALGOLIA_SEARCH_KEY = 'SET_ALGOLIA_SEARCH_KEY';
// Others
export const SELECT_SPACE = 'SELECT_SPACE';

export const SET_CURRENT_OWNER_TYPE = 'SET_CURRENT_OWNER_TYPE';
export const SET_CURRENT_OWNER_ID = 'SET_CURRENT_OWNER_ID';
export const SET_CURRENT_LIST_ID = 'SET_CURRENT_LIST_ID';

// AlertNotifier
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
