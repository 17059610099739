import axios from 'axios';
import { enqueueMessage } from './Alerts';
import parseErrorMessage from '../utils/errorMessage';
import {
  FETCH_LINKS_REQUEST, FETCH_LINKS_SUCCESS, FETCH_LINKS_FAILURE,
  CREATE_LINK_REQUEST, CREATE_LINK_SUCCESS, CREATE_LINK_FAILURE,
  UPDATE_LINK_REQUEST, UPDATE_LINK_SUCCESS, UPDATE_LINK_FAILURE,
  DELETE_LINK_REQUEST, DELETE_LINK_SUCCESS, DELETE_LINK_FAILURE,
} from './types';

export const fetchLinksRequest = () => ({
  type: FETCH_LINKS_REQUEST,
});

export const fetchLinksSuccess = links => ({
  type: FETCH_LINKS_SUCCESS,
  payload: links,
});

export const fetchLinksFailure = data => ({
  type: FETCH_LINKS_FAILURE,
  payload: {
    error: data.message,
  },
});

export const fetchLinks = contentId => (dispatch) => {
  dispatch(fetchLinksRequest());

  return axios.get(`contents/${contentId}/links`)
    .then((response) => {
      dispatch(fetchLinksSuccess(response.data));
      return response.data;
    })
    .catch((error) => {
      dispatch(fetchLinksFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
    });
};

export const createLinkRequest = () => ({
  type: CREATE_LINK_REQUEST,
});

export const createLinkSuccess = link => ({
  type: CREATE_LINK_SUCCESS,
  payload: link,
});

export const createLinkFailure = data => ({
  type: CREATE_LINK_FAILURE,
  payload: {
    error: data.message,
  },
});

export const createLink = (contentId, body) => (dispatch) => {
  dispatch(createLinkRequest());

  return axios.post(`contents/${contentId}/links`, { body })
    .then((response) => {
      dispatch(createLinkSuccess(response.data));
      dispatch(enqueueMessage('Link Added Successfully', 'success'));
      return response.data;
    })
    .catch((error) => {
      dispatch(createLinkFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
    });
};

export const updateLinkRequest = () => ({
  type: UPDATE_LINK_REQUEST,
});

export const updateLinkSuccess = link => ({
  type: UPDATE_LINK_SUCCESS,
  payload: link,
});

export const updateLinkFailure = data => ({
  type: UPDATE_LINK_FAILURE,
  payload: {
    error: data.message,
  },
});

export const updateLink = link => (dispatch) => {
  dispatch(updateLinkRequest());

  return axios.put(`/links/${link.id}`, link)
    .then((response) => {
      dispatch(updateLinkSuccess(response.data));
      dispatch(enqueueMessage('Link Updated Successfully', 'success'));
      return response.data;
    })
    .catch((error) => {
      dispatch(updateLinkFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
    });
};

export const deleteLinkRequest = () => ({
  type: DELETE_LINK_REQUEST,
});

export const deleteLinkSuccess = link => ({
  type: DELETE_LINK_SUCCESS,
  payload: link,
});

export const deleteLinkFailure = data => ({
  type: DELETE_LINK_FAILURE,
  payload: {
    error: data.message,
  },
});

export const deleteLink = link => (dispatch) => {
  dispatch(deleteLinkRequest());

  return axios.delete(`/links/${link.id}`)
    .then(() => {
      dispatch(deleteLinkSuccess(link));
      dispatch(enqueueMessage('Link Deleted Successfully', 'success'));
      return link.id;
    })
    .catch((error) => {
      dispatch(deleteLinkFailure(error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
    });
};
