import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import Store from './store';
import App from './components/App';
import registerServiceWorker from './registerServiceWorker';
import axiosInitializer from './api/axios';

axiosInitializer();

ReactDOM.render(
  /* eslint-disable */
  <Provider store={Store}>
    <BrowserRouter>
      <SnackbarProvider autoHideDuration={3000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <App />
      </SnackbarProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
  /* eslint-enable */
);
registerServiceWorker();
