import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ArrowBack from '@material-ui/icons/ArrowBack';

import {
  Typography,
  Grid,
  Button,
  Container,
} from '@material-ui/core';
import OrganizationUsers from './OrganizationUsers';
import { getOrganization } from '../../reducers/Organization';
import { getCurrentUser } from '../../reducers/CurrentUser';
import Header from '../NewDashboard/dashboard-components/Header';
import NoActiveSubscription from './Errors/NoActiveSubscription';
import NonAdminUser from './Errors/NonAdminUser';
import OrganizationActions from './OrganizationActions';
import InsufficientUserSubscription from './Errors/InsufficientUserSubscription';

class Organization extends Component {
  redirectToDashboard = () => {
    const { history } = this.props;
    history.replace('/dashboard/private');
  };

  render() {
    const { organization, currentUser } = this.props;
    if (!organization) {
      return <Grid>Loading...</Grid>;
    }

    return (
      <>
        <Header mini />
        <Container>
          <Button
            edge="start"
            color="first"
            onClick={this.redirectToDashboard}
          >
            <ArrowBack />
            Back to Dashboard
          </Button>
          <br />
          {organization && organization.stripe_subscription_status === 'canceled' && (
            <NoActiveSubscription />
          )}

          {organization && organization.stripe_subscription_status === 'active' && organization.user_count > organization.max_users && (
            <InsufficientUserSubscription organization={organization} />
          )}

          <Grid container direction="column" alignItems="center">
            <Grid item container justify="space-evenly">
              <Typography variant="h5">
                {`Organization: ${organization && organization.name}`}
              </Typography>
              <Grid xs />
              {currentUser && currentUser.organization_role === 'admin' && <OrganizationActions />}
            </Grid>
            <br />
            {/* <Typography variant="subtitle2" > */}
            {/* <Alert severity="info">Users: {organization.user_count}/{organization.stripe_subscription_quantity}</Alert> */}
            {/* <Grid xs /> */}
            {/* </Typography> */}
            {currentUser && currentUser.organization_role !== 'admin' && <NonAdminUser />}
            {currentUser && currentUser.organization_role === 'admin' && <OrganizationUsers />}

          </Grid>
        </Container>
      </>
    );
  }
}
Organization.propTypes = {
  match: PropTypes.shape({}).isRequired,
  organization: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  organization: getOrganization(state),
  currentUser: getCurrentUser(state),
});

export default connect(mapStateToProps, null)(Organization);
