import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { removeSnackbar } from '../../actions/Alerts';

class AlertNotifier extends React.Component {
  state = {
    displayed: [],
  };

  storeDisplayed = (key) => {
    this.setState(({ displayed }) => ({
      displayed: [...displayed, key],
    }));
  };

  render() {
    const { alerts, enqueueSnackbar, removeSnackbar } = this.props;
    const { displayed } = this.state;

    alerts.forEach((alert) => {
      setTimeout(() => {
        // If alert already displayed, abort
        if (displayed.indexOf(alert.key) > -1) return;
        // Display alert using notistack
        enqueueSnackbar(alert.message, alert.options);
        // Add alert's key to the local state
        this.storeDisplayed(alert.key);
        // Dispatch action to remove the alert from the redux store
        removeSnackbar(alert.key);
      }, 1);
    });

    return null;
  }
}

AlertNotifier.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  removeSnackbar: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  alerts: state.Alerts,
});

const mapDispatchToProps = dispatch => bindActionCreators({ removeSnackbar }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withSnackbar(AlertNotifier));
