import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Button, Card, CardActions, CardContent, CircularProgress, FormHelperText, Grid, makeStyles, TextField, Typography,
} from '@material-ui/core';
import DualListBox from 'react-dual-listbox';
import { connect } from 'react-redux';
import MiniHeader from '../NewDashboard/dashboard-components/MiniHeader';
import { getOrganization } from '../../reducers/Organization';
import { createGroup, deleteGroup, updateGroup } from '../../actions/Groups';
import { getCurrentUser } from '../../reducers/CurrentUser';
import OrganizationActions from '../Organization/OrganizationActions';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: theme.spacing(80),
    minWidth: theme.spacing(60),
    margin: theme.spacing(4),
  },
}));

const NewGroupForm = (props) => {
  const [group, setGroup] = useState({
    name: '',
    users: [],
  });
  const classes = useStyles();
  const { organization, currentUser, match } = props;

  const toOptionObject = user => ({
    value: parseInt(user.id, 10),
    label: `👤 ${user.name || user.email}`,
    disabled: parseInt(user.id, 10) === parseInt(currentUser.id, 10),
  });

  useEffect(() => {
    if (organization && currentUser && !match.params.groupId) {
      setGroup({
        ...group,
        users: [parseInt(currentUser.id, 10)],
      });
    }
  }, [currentUser, organization]);

  useEffect(() => {
    if (match.params.groupId) {
      axios.get(`/groups/${match.params.groupId}`).then((response) => {
        const group = response.data;
        setGroup({
          ...group,
          users: group.users.map(u => u.id),
        });
      });
    }
  }, []);

  const handleNameChange = e => setGroup({
    ...group,
    name: e.target.value,
  });

  const handleSelectedUsersChange = selected => setGroup({
    ...group,
    users: selected,
  });

  const redirectToGroupDashboard = (groupId) => {
    const { history } = props;
    history.replace(`/dashboard/groups/${groupId}`);
  };

  const handleSave = (e) => {
    e.stopPropagation();
    const { createGroupAction, updateGroupAction } = props;

    const action = group.id ? updateGroupAction : createGroupAction;
    action(group).then(response => response && response.id && redirectToGroupDashboard(response.id));
  };

  const redirectBack = () => {
    const { history } = props;
    history.goBack();
  };

  const handleDelete = (group) => {
    const { deleteGroupAction, history } = props;
    deleteGroupAction(group).then(() => history.replace('/'));
  };


  if (!organization || !currentUser) return <CircularProgress />;

  const userOptions = organization ? organization.users.map(u => toOptionObject(u)) : [];
  return (
    <>
      <MiniHeader>
        <Typography variant="h6">{group.id ? 'Edit Group' : 'Create New Group'}</Typography>
      </MiniHeader>
      <Grid container justify="center" direction="column" alignContent="center">
        <Card className={classes.card}>
          <CardContent>
            <Grid item container direction="column" justify="center">
              <Grid item container>
                <TextField
                  autoFocus
                  required
                  fullWidth
                  variant="outlined"
                  label="Group Name"
                  id="input-name"
                  name="name"
                  value={group.name || ''}
                  onChange={handleNameChange}
                />
              </Grid>
              <br />
              <Grid item>
                <Typography variant="h6">Select members of the group</Typography>

                <DualListBox
                  name="users"
                  canFilter={organization && organization.users.length > 5}
                  showHeaderLabels
                  options={userOptions}
                  selected={group.users}
                  onChange={handleSelectedUsersChange}
                />
                <FormHelperText id="my-helper-text">Move users from "Available" to "Selected" to add them as members of the group. </FormHelperText>

              </Grid>
              <Grid item>
                {currentUser && currentUser.organization_role === 'admin' && <OrganizationActions />}
              </Grid>
            </Grid>
            {/* <div><pre>{JSON.stringify(group, null, 2) }</pre></div> */}
          </CardContent>

          <hr />
          <CardActions style={{ marginLeft: 'auto' }}>
            <Grid container>
              {group.id && (
              <Button color="secondary" onClick={() => window.confirm('Are you sure you wish to delete the item?') && handleDelete(group)}>
                <i className="fa fa-trash-alt" />
                  &nbsp; Delete
              </Button>
              )}

              <Box flexGrow={1} />

              <Button variant="contained" color="primary" onClick={handleSave}>
                <i className="fa fa-save" />
                &nbsp; Save
              </Button>
              <Button onClick={redirectBack}>Cancel</Button>
            </Grid>
          </CardActions>
          {/* <div><pre>{JSON.stringify(this.state, null, 2) }</pre></div> */}
        </Card>
      </Grid>
    </>
  );
};

const mapStateToProps = state => ({
  organization: getOrganization(state),
  currentUser: getCurrentUser(state),
});

const mapDispatchToProps = dispatch => ({
  createGroupAction: group => dispatch(createGroup(group)),
  updateGroupAction: group => dispatch(updateGroup(group)),
  deleteGroupAction: group => dispatch(deleteGroup(group)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(NewGroupForm));
