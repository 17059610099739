import React from 'react';
import Popover from '@material-ui/core/Popover';
import { Typography, IconButton, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Gravatar from '../UI/Gravatar';

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
  },
}));

export default ({ users }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <IconButton
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        aria-label="completed"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        // color="default"
        // onClick={() => handleStarClick(content)}
      >
        <i
          className="fa fa-check-circle"
          style={{ fontSize: 15 }}
        />
        &nbsp;
        <Typography variant="subtitle2">{ users.length }</Typography>
      </IconButton>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <List dense>
          {users.map( user => (
            <ListItem key={user.id}>
              {user.avatar && (
                <ListItemIcon>
                  <Gravatar email={user.email} />
                </ListItemIcon>
              )}

              <ListItemText
                primary={user.name}
              />
            </ListItem>
          ))}
        </List>
      </Popover>
    </div>
  );
}