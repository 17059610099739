import MixpanelClient from './client';
import { MIXPANEL_ADDED_GROUP, MIXPANEL_UPDATED_GROUP, MIXPANEL_REMOVED_GROUP } from './events';

// responseStatus can be 'success' OR 'failure'
const trackGroupEvent = (eventNameConstant, group, responseStatus, errorMessage) => {
  MixpanelClient.track(eventNameConstant, {
    'Group Name': group?.name,
    'Response Status': responseStatus,
    'Response Error Message': errorMessage,
  });
}

export const trackAddedGroup = (group, responseStatus = 'success', errorMessage = null) => {
  trackGroupEvent(MIXPANEL_ADDED_GROUP, group, responseStatus, errorMessage);
};

export const trackUpdatedGroup = (group, responseStatus = 'success', errorMessage = null) => {
  trackGroupEvent(MIXPANEL_UPDATED_GROUP, group, responseStatus, errorMessage);
};

export const trackRemovedGroup = (group, responseStatus = 'success', errorMessage = null) => {
  trackGroupEvent(MIXPANEL_REMOVED_GROUP, group, responseStatus, errorMessage);
};
