import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import { setSidebarToggleMobile } from '../../../../reducers/ThemeOptions';
import projectLogo from '../../../../assets/images/logo/iselo_logo_xs.png';
import HeaderUserbox from '../HeaderUserbox';
// import HeaderDrawer from '../HeaderDrawer';

const useStyles = makeStyles(theme => ({
  logo: {
    width: '100px',
    [theme.breakpoints.down('xs')]: {
      width: '100px',
    },
  },
}));

const MiniHeader = (props) => {
  const {
    headerShadow,
    headerBgTransparent,
    children,
  } = props;
  const classes = useStyles();
  return (
    <>
      <div
        className={classNames('app-header', {
          'app-header--shadow': headerShadow,
          'app-header--opacity-bg': headerBgTransparent,
        })}
      >
        <div className="app-header--pane">
          <div className="app-sidebar-logo">
            <NavLink
              to="/"
              title="Bamburgh React Admin Dashboard with Material-UI PRO"
              className="app-sidebar-logo"
            >
              {/* <div className="app-sidebar-logo--icon"> */}
              <img
                alt="iselo"
                src={projectLogo}
                width="100px"
                className={classes.logo}
              />
              {/* </div> */}
            </NavLink>
          </div>
        </div>

        <div className="app-header--pane">
          {children}
        </div>

        <div className="app-header--pane">
          <HeaderUserbox />
          {/* <HeaderDrawer /> */}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerBgTransparent: state.ThemeOptions.headerBgTransparent,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MiniHeader);
