import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Grid,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import GroupIcon from '@material-ui/icons/GroupTwoTone';
import ArrowBack from '@material-ui/icons/ArrowBack';
import DashboardIcon from '@material-ui/icons/DashboardTwoTone';

import { deleteGroup } from '../../actions/Groups';
import AddGroupButton from './AddGroupButton';

class GroupList extends Component {
  redirectToHome = () => {
    const { history } = this.props;
    history.replace('/');
  };

  handleDelete = (group) => {
    const { deleteGroupAction } = this.props;
    deleteGroupAction(group);
  };

  render() {
    const { groups, groupsLoading } = this.props;
    let groupListContent = null;

    if (!groupsLoading && groups.length === 0) {
      groupListContent = 'No Groups';
    } else {
      groupListContent = groups.map(group => (
        <ListItem key={group.id}>
          <ListItemText primary={group.name} />
          <ListItemSecondaryAction>
            <Button color="primary" component={Link} to={`/dashboard/groups/${group.id}`}>
              <DashboardIcon />
              Visit Dashboard
            </Button>
            <Button color="primary" component={Link} to={`/groups/${group.id}/edit`}>
              <GroupIcon />
              All Members
            </Button>

            <Button color="secondary" onClick={() => window.confirm('Are you sure you wish to delete the Group?') && this.handleDelete(group)}>
              <DeleteIcon />
              Delete
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
      ));
    }

    return (
      <Grid>
        <Button size="small" color="primary" onClick={this.redirectToHome}>
          <ArrowBack />
          Back to Home
        </Button>
        <Grid container direction="row" justify="space-between">
          <Grid item>
            <Typography variant="h5">Groups</Typography>
          </Grid>
          <Grid item>
            <AddGroupButton>Create New Group</AddGroupButton>
          </Grid>
        </Grid>

        <Grid container alignItems="center" justify="center" alignContent="center">
          <Grid item xs={6}>
            <List>{groupListContent}</List>
          </Grid>
        </Grid>

      </Grid>
    );
  }
}
GroupList.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  groupsLoading: PropTypes.bool.isRequired,
  deleteGroupAction: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  groups: state.Groups.data,
  groupsLoading: state.Groups.loading,
});

const mapDispatchToProps = dispatch => ({
  deleteGroupAction: group => dispatch(deleteGroup(group)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupList);
