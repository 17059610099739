import React from 'react';
import { List } from '@material-ui/core';
import StarListItem from './StarListItem';
import Tour from './Tour';

const Tour5 = () => (
  <Tour
    title="How to invite users into your organization?"
    image="/assets/images/tour/product-tour5.jpeg"
    previousUrl="/tour/4"
    nextUrl="/tour/6"
  >
    <List dense>
      <StarListItem>
        Step1: Visit the Orgnization Users page
      </StarListItem>
      <StarListItem>
        Step2: Click on &quot;Add a new user&quot; and provide User&apos;s email address to invite
      </StarListItem>
    </List>
  </Tour>
);

export default Tour5;
