import axios from 'axios';
import { enqueueMessage } from './Alerts';
import {
  CREATE_LIST_REQUEST,
  CREATE_LIST_SUCCESS,
  CREATE_LIST_FAILURE,
  UPDATE_LIST_REQUEST,
  UPDATE_LIST_SUCCESS,
  UPDATE_LIST_FAILURE,
  DELETE_LIST_REQUEST,
  DELETE_LIST_SUCCESS,
  DELETE_LIST_FAILURE,
  SELECT_LIST,
} from './types';

import { trackAddedList, trackUpdatedList, trackRemovedList } from '../utils/mixpanel/lists';
import parseErrorMessage from '../utils/errorMessage';

export const createListRequest = listOwnerType => ({
  type: CREATE_LIST_REQUEST,
  listOwnerType,
});

export const createListSuccess = (listOwnerType, list) => ({
  type: CREATE_LIST_SUCCESS,
  listOwnerType,
  payload: list,
});

export const createListFailure = (listOwnerType, data) => ({
  type: CREATE_LIST_FAILURE,
  listOwnerType,
  payload: {
    error: data.message,
  },
});

export const createList = body => (dispatch) => {
  dispatch(createListRequest());

  return axios.post('/lists', body)
    .then((response) => {
      trackAddedList(response);
      dispatch(createListSuccess(body.owner_type, response.data));
      dispatch(enqueueMessage('List Added Successfully', 'success'));
      return response.data;
    })
    .catch((error) => {
      trackAddedList(body, 'failure', parseErrorMessage(error));
      dispatch(createListFailure(body.owner_type, error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
    });
};

export const updateListRequest = listOwnerType => ({
  type: UPDATE_LIST_REQUEST,
  listOwnerType,
});

export const updateListSuccess = (listOwnerType, list) => ({
  type: UPDATE_LIST_SUCCESS,
  listOwnerType,
  payload: list,
});

export const updateListFailure = (listOwnerType, data) => ({
  type: UPDATE_LIST_FAILURE,
  listOwnerType,
  payload: {
    error: data.message,
  },
});

export const updateList = list => (dispatch) => {
  dispatch(updateListRequest(list.owner_type));

  return axios.put(`/lists/${list.id}`, list)
    .then((response) => {
      trackUpdatedList(list);
      dispatch(updateListSuccess(list.owner_type, response.data));
      dispatch(enqueueMessage('List Updated Successfully', 'success'));
      return response.data;
    })
    .catch((error) => {
      trackUpdatedList(list, 'failure', parseErrorMessage(error));
      dispatch(updateListFailure(list.owner_type, error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
    });
};

export const deleteListRequest = listOwnerType => ({
  type: DELETE_LIST_REQUEST,
  listOwnerType,
});

export const deleteListSuccess = (listOwnerType, list) => ({
  type: DELETE_LIST_SUCCESS,
  listOwnerType,
  payload: list,
});

export const deleteListFailure = (listOwnerType, data) => ({
  type: DELETE_LIST_FAILURE,
  listOwnerType,
  payload: {
    error: data.message,
  },
});

export const deleteList = list => (dispatch) => {
  dispatch(deleteListRequest(list.owner_type));

  return axios.delete(`/lists/${list.id}`)
    .then(() => {
      trackRemovedList(list);
      dispatch(deleteListSuccess(list.owner_type, list));
      dispatch(enqueueMessage('List Deleted Successfully', 'success'));
      return list.id;
    })
    .catch((error) => {
      trackRemovedList(list, 'failure', parseErrorMessage(error));
      dispatch(deleteListFailure(list.owner_type, error));
      dispatch(enqueueMessage(parseErrorMessage(error), 'error'));
    });
};

export const selectList = listId => (dispatch, getState) => {
  dispatch({
    type: SELECT_LIST,
    payload: listId,
  });
};
