import React from 'react';
import { Link } from '@material-ui/core';
import Bowser from 'bowser';

/**
 * Promise wrapper for chrome.chrome.runtime.sendMessage
 */
const sendMessagePromise = (extensionId, message) => {
  return new Promise((resolve, reject) => {
    try {
      window.chrome.runtime.sendMessage(extensionId, message, (response) => {
        resolve(response);
      });
    } catch (e) {
      reject(new Error('Something went wrong while sending message to the extension'));
    }
  });
};

const browserName = () => {
  const bowser = Bowser.getParser(window.navigator.userAgent);
  return bowser.getBrowserName();
};

const extensionId = () => {
  const CHROME_EXTENTION_ID = 'jaefgmphcmcmkggipibkmjemeflhokdm';
  const FIREFOX_EXTENSION_ID = '261cca2e-6609-4a85-aee6-2d168e941d18';
  const EDGE_EXTENSION_ID = 'dbpdmcccdbfhicnangmhiajkecgknfpe';

  switch (browserName()) {
    case 'Microsoft Edge':
      return EDGE_EXTENSION_ID;
    case 'Firefox':
      return FIREFOX_EXTENSION_ID;
    case 'Chrome':
    default:
      return CHROME_EXTENTION_ID;
  }
}

const ExtensionButton = ({ browser }) => {
  switch (browser) {
    case 'Microsoft Edge':
      return (
        <Link href="https://microsoftedge.microsoft.com/addons/detail/dbpdmcccdbfhicnangmhiajkecgknfpe" target="_blank" style={{ textDecoration: 'none', display: 'block', color: 'inherit' }}>
          <img alt="iselo" src="/assets/images/microsoft_store_badge.png" />
        </Link>
      );
    case 'Firefox':
      return (
        <Link href="https://addons.mozilla.org/en-US/firefox/addon/save-to-iselo/" target="_blank" style={{ textDecoration: 'none', display: 'block', color: 'inherit' }}>
          <img alt="iselo" src="/assets/images/firefox_store_badge.png" />
        </Link>
      );
    case 'Chrome':
    default:
      return (
        <Link href="https://chrome.google.com/webstore/detail/jaefgmphcmcmkggipibkmjemeflhokdm" target="_blank" style={{ textDecoration: 'none', display: 'block', color: 'inherit' }}>
          <img alt="iselo" src="/assets/images/chrome_store_badge.png" />
        </Link>
      );
  }
}

class BrowserExtensionButton extends React.Component {
  state = {
    showButton: null,
  }

  componentDidMount() {
    sendMessagePromise(extensionId(), 'version').then((response) => {
      if (!response) {
        this.setState({ showButton: 'true' });
      } else {
        console.log('Extension installed!');
      }
    }, (reason) => {
      console.log(reason);
    });
  }

  render() {
    const { showButton } = this.state;
    if (showButton) {
      return <ExtensionButton browser={browserName()} />;
    }
    return null;
  }
}

export default BrowserExtensionButton;
