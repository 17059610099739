import React from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  dropzone: {
    minHeight: theme.spacing(10),
    maxHeight: theme.spacing(30),
    fontFamily: theme.typography.fontFamily,
    fontWeight: 200,
    marginBottom: theme.spacing(1),
  },
  dropzoneParagraph: {
    fontSize: 14,
  },
}));

export default ({ onChange }) => {
  const classes = useStyles();

  // This also has backend validation; If you change this list, you'll have to change the list on the backend too

  const validFileTypes = () => ({
    pdf: 'application/pdf', // .pdf
    rtf: 'application/rtf', // .rtf
    doc: 'application/msword', // .doc
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    ppt: 'application/vnd.ms-powerpoint', // .ppt
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
    xls: 'application/vnd.ms-excel', // .xls
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
    odt: 'application/vnd.oasis.opendocument.text', // .odt
    odp: 'application/vnd.oasis.opendocument.presentation', // .odp
    ods: 'application/vnd.oasis.opendocument.spreadsheet', // .ods
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    png: 'image/png',
  });

  return (
    <DropzoneArea
      acceptedFiles={Object.values(validFileTypes())}
      maxFileSize={10000000}
      dropzoneText={`Drag and drop a file here or click to browse. Valid file types: ${Object.keys(validFileTypes()).join(', ')}`}
      showFileNames
      useChipsForPreview
      dropzoneClass={classes.dropzone}
      dropzoneParagraphClass={classes.dropzoneParagraph}
      onChange={onChange}
    />
  );
};
