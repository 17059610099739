import React from 'react';
import {FormControlLabel, Switch } from '@material-ui/core';
import { connectToggleRefinement } from 'react-instantsearch-dom';
import MixpanelCleint from '../../../utils/mixpanel/client';
import { MIXPANEL_CLICKED_ON_SHOW_ALL } from '../../../utils/mixpanel/events';

const ToggleRefinement = ({
  currentRefinement,
  label,
  refine,
}) => {
  const [state, setState] = React.useState({
    checked: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    MixpanelCleint.track(MIXPANEL_CLICKED_ON_SHOW_ALL);
    refine(!currentRefinement);
  };

  return (
    <FormControlLabel
      control={(
        <Switch
          checked={state.checked}
          onChange={handleChange}
          color="primary"
          className="switch-small"
          name="checked"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      )}
      label={label}
    />

  );
}

export default connectToggleRefinement(ToggleRefinement);
