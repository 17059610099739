import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Collapse, makeStyles, Tooltip,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  NavLink, Link, useLocation, useHistory,
} from 'react-router-dom';

import classNames from 'classnames';
import { setSidebarToggleMobile } from '../../../../reducers/ThemeOptions';

import { getGroups } from '../../../../reducers/Groups';
import { getCurrentUser } from '../../../../reducers/CurrentUser';
import { getOrganization } from '../../../../reducers/Organization';
import {
  isUser, isGroup, isOrganization, isUserPath, isGroupPath, isOrganizationPath,
} from '../../../../utils/listOwnerTypes';

import { USER, GROUP, ORGANIZATION } from '../../../../Constants';

const useStyles = makeStyles(theme => ({
  activeListItem: {
    background: 'rgba(7, 9, 25, 0.45) !important',
    color: 'white !important',
  },
  listItem: {
    whiteSpace: 'normal  !important',
    // minHeight: '40px',
    height: 'auto !important',
    paddingRight: '8px !important',
    paddingTop: '4px !important',
    paddingBottom: '4px !important',
    fontWeight: 'normal',
  },
  chevron: {
    position: 'absolute',
    left: '0px',
    marginLeft: '8px !important',
  },
  editIcon: {
  },
}));

const SidebarMenuItem = ({ title, path, listId }) => {
  const classes = useStyles();
  const history = useHistory();
  const redirectToEditList = (e) => {
    e.preventDefault();
    history.push(`/lists/${listId}/edit`);
  };

  return (
    <li>
      <NavLink
        to={path}
        activeClassName={classes.activeListItem}
        className={classNames(classes.listItem, 'hover-show-hide-container')}
      >
        {title}
        <Tooltip title="Edit this list">
          <span className="ml-auto mr-2 hover-show-wrapper">
            <i className="fa fa-pencil-alt mx-auto" onClick={redirectToEditList} />
          </span>
        </Tooltip>
      </NavLink>
    </li>
  );
};

const SidebarMenuToggleItem = (props) => {
  const {
    title, model, type, path, groupId,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const defaultOpen = (type === USER && isUserPath(location.pathname))
    || (type === ORGANIZATION && isOrganizationPath(location.pathname))
    || (type === GROUP && isGroupPath(location.pathname, groupId));

  const [open, setOpen] = useState(defaultOpen);

  const toggleMenu = () => {
    setOpen(!open);
    return true;
  };
  const redirectToEditGroup = (e) => { e.preventDefault(); history.push(`/groups/${groupId}/edit`); };

  return (
    <li>
      <Link
        to={path}
        onClick={toggleMenu}
        activeClassName={classes.activeListItem}
        className={classNames(
          'hover-show-hide-container',
          classes.listItem,
          (path === location.pathname ? classes.activeListItem : ''),
          // {
          //   active: open,
          // },
        )}
      >
        <span className={classNames('sidebar-icon-indicator', classes.chevron)}>
          { model && model.lists && model.lists.length > 0 && !open && (
            <i className="fas fa-caret-right" />
          )}
          { model && model.lists && model.lists.length > 0 && open && (
            <i className="fas fa-caret-down" />
          )}
        </span>
        {(isUser(type) || isGroup(type) || isOrganization(type)) && (
          <span className="sidebar-icon">
            { isUser(type) && <i className="fas fa-user" style={{ fontSize: '18px' }}/>}
            { isGroup(type) && <i className="fas fa-users" style={{ fontSize: '18px' }} />}
            { isOrganization(type) && <i className="fas fa-building" style={{ fontSize: '18px' }} />}
          </span>
        )}

        <span className={classNames('sidebar-item-label', classes.listItem)}>{title}</span>
        {isGroup(type) && groupId && (
          <span className="ml-auto mr-2 hover-show-wrapper">
            <i className="fa fa-cog font-size-md ml-auto" onClick={redirectToEditGroup} />
          </span>
        )}
      </Link>
      {model && model.lists && model.lists.length > 0 && (
        <Collapse in={open}>
          <ul>
            {model.lists.map(list => (
              <SidebarMenuItem
                key={list.id}
                listId={list.id}
                title={list.name}
                path={isUser(type) ? `/dashboard/private/lists/${list.id}` : (isGroup(type) ? `/dashboard/groups/${groupId}/lists/${list.id}` : `/dashboard/organization/lists/${list.id}`)}
              />
            ))}
          </ul>
        </Collapse>
      )}
    </li>
  );
};
const SidebarMenu = (props) => {
  const {
    setSidebarToggleMobile, currentUser, groups, organization,
  } = props;
  return (
    <>
      <PerfectScrollbar>
        <div className="sidebar-navigation">
          <ul>
            <SidebarMenuToggleItem title="All Content" path="/dashboard/all" />
            <div className="sidebar-header">
              <span>Private</span>
            </div>
            <SidebarMenuToggleItem title="Private Space" path="/dashboard/private" type={USER} model={currentUser} />
            <div className="sidebar-header">
              <span>Groups</span>
            </div>

            {groups && groups.map(group => (
              <SidebarMenuToggleItem key={group.id} title={group.name} path={`/dashboard/groups/${group.id}`} type={GROUP} model={group} groupId={group.id} />
            ))}
            <div className="sidebar-header">
              <span>Organization</span>
            </div>
            <SidebarMenuToggleItem title="Organization Space" path="/dashboard/organization" type={ORGANIZATION} model={organization} />
          </ul>
        </div>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = state => ({
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  groups: getGroups(state),
  currentUser: getCurrentUser(state),
  organization: getOrganization(state),
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
