import React from 'react';

// https://stripe.com/docs/api/cards/object#card_object-brand
// American Express, Diners Club, Discover, JCB, MasterCard, UnionPay, Visa, or Unknown.
export default ({ brand }) => {
  switch (brand.toLowerCase()) {
    case 'visa':
      return <i className="fab fa-cc-visa" style={{ fontSize: 40 }} />;
    case 'mastercard':
      return <i className="fab fa-cc-mastercard" style={{ fontSize: 40 }} />;
    case 'amex':
      return <i className="fab fa-cc-amex" style={{ fontSize: 40 }} />;
    case 'discover':
      return <i className="fab fa-cc-discover" style={{ fontSize: 40 }} />;
    case 'jcb':
      return <i className="fab fa-cc-jcb" style={{ fontSize: 40 }} />;
    case 'diners':
      return <i className="fab fa-cc-diners-club" style={{ fontSize: 40 }} />;
    default:
      return <i className="far fa-credit-card" style={{ fontSize: 40 }} />;
  }
};
